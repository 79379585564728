import React, {useEffect, useState} from "react";
import {ExclamationTriangleIcon} from "@heroicons/react/24/outline";
import Tooltip from "../../../../common/components/tooltip";
import moment from "moment";
import {DEFAULT_DATABASE_DATETIME_FORMAT} from "../../../../common/util/util-consts";
import InfoBar from "../../../../common/components/info-paragraph/info-bar";
import {toFrontDate} from "../../../../common/util/util-dates";

export default function CarrierExpiredInsuranceWarning({data = {}, type = 'icon', translate}) {
    const today = moment();

    const [expiredInsurances, setExpiredInsurances] = useState([]);

    useEffect(() => {
        const InsuranceTypes = ['Liability', 'Cargo', 'TrailerInterop', 'WorkComp'];

        const expiredInsurancesData = InsuranceTypes.reduce((memo, insuranceType) => {
            if (data?.[insuranceType + "InsuranceExpiryDate"]) {
                if (today.isAfter(moment(data[insuranceType + "InsuranceExpiryDate"], DEFAULT_DATABASE_DATETIME_FORMAT))) {
                    memo.push({
                        Type: insuranceType,
                        No: data[insuranceType + "InsuranceNo"],
                        ExpiryDate: data[insuranceType + "InsuranceExpiryDate"],
                        Amount: data[insuranceType + "InsuranceAmount"]
                    });
                }
            }

            return memo;
        }, []);

        setExpiredInsurances(expiredInsurancesData);
    }, []);

    if (!expiredInsurances.length) {
        return null;
    }

    if (type === 'infoBar') {
        return expiredInsurances.map(insurance =>
            <InfoBar key={insurance.No} type="warning">
                <div>{translate("text.carrier_insurance_warning", [insurance.Type, insurance.No, toFrontDate(insurance.ExpiryDate)])}</div>
            </InfoBar>
        )
    }

    return expiredInsurances.map(insurance =>
        <Tooltip
            key={insurance.No}
            content={translate("text.carrier_insurance_warning", [insurance.Type, insurance.No, toFrontDate(insurance.ExpiryDate)])}
        >
            <ExclamationTriangleIcon className={"w-5 h-5 text-yellow-600 animate-button-fade-in"}/>
        </Tooltip>
    )
}