import React, {Component} from "react";
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../data/services/resources";
import {showModal} from "../../../data/actions/ui";
import {
    createSecondResource,
    deleteSecondResource,
    getSecondResource,
    updateSecondResource
} from "../../../data/actions/secondResource";

import {Field, FieldsManager} from "../../../data/services/fields";
import {CREATE_PERM, DEFAULT_CRUD_STATE, DELETE_PERM} from "../../../util/util-constants";
import {checkPerm, classNames, getProp, resourceIsUpdated} from "../../../common/util/util-helpers";
import {ArrowPathIcon, CheckBadgeIcon, TrashIcon} from "@heroicons/react/24/outline";
import {fieldsToHtml} from "../../../common/util/util-fields";
import Card from "../../../common/components/card";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import ResourceTable from "../../../common/components/resource-table";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import ContactSelectCreateModal from "../../../common/components/modal/contact-select-create-modal";
import {DEFAULT_QUERY_LIMIT, UPDATE_PERM} from "../../../common/util/util-consts";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";

export default class DriverDispatchTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: this.props.sortBy ?? "",
            sort: this.props.sort ?? "ASC",
            fields: this.getFields(),
            queryFilterFields: this.getQueryFilterFields(),

            // Create/Edit modal
            createModalShow: false,
            createModalShowAnimation: false,

            // Delete modal
            confirmModal: false,
            confirmModalAnimation: false,

            // Set Primary modal:
            setPrimaryModalShow: false,
            setPrimaryModalShowAnimation: false,
        }
    }

    componentDidMount = () => {
        this.getOrganizationId() && this.fetchContacts();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (resourceIsUpdated(this.props.contactResource, prevProps.contactResource)) {
            this.fetchContacts();
        }
    }

    fetchContacts = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get("user"),
            resource: this.getResourceName(),
            query: this.getQuery(),
        }));
    };

    setPrimaryResource = (item) => {
        const text = 'text.confirm_set_primary_contact'
        this.setState({
            confirmText: this.props.translate(text),
            selectedItem: item,
            setPrimaryModalShow: true
        })
    }

    handleSetPrimary = (item, query) => {
        this.props.dispatch(updateSecondResource({
            user: LocalStorage.get('user'),
            params: {ContactInDriverID: item.ContactInDriverID},
            resource: Resources.DriverDispatchPrimary,
            piggyResource: Resources.DriverDispatch,
            query: query,
            errorMessage: true,
            successMessage: `Driver ${item.FirstName} ${item.LastName} successfully set as primary contact!`
        }))
    }

    archiveResource = (item) => {
        if (item.IsPrimary) {
            return false
        }

        const text = `text.confirm_delete_contact_from_${this.getResourceName()}`
        this.setState({
            confirmText: this.props.translate(text),
            selectedItem: item,
            confirmModal: true
        })
    }

    handleDelete = (item, query) => {
        this.props.dispatch(deleteSecondResource({
            user: LocalStorage.get('user'),
            query: {
                ContactID: item.ContactID,
                ...query
            },
            secondQuery: {
                ContactInDriverID: item.ContactInDriverID,
                ...query
            },
            errorMessage: true,
            successMessage: this.props.translate(`text.contact_deleted_from_${Resources.DriverDispatch}`, [`${item.FirstName} ${item.LastName}`]),
            resource: Resources.DriverDispatch,
            secondPiggyResource: Resources.DriverDispatch
        }))

    }

    handleShowDetails = (item) => {
        this.props.dispatch(showModal('ViewContactCard', {ContactID: item.ContactID}));
    };

    handleShowCreateModal = () => {
        this.setState({
            createModalShow: true
        })
    }

    handleShowConfirmDialog = (submit = null) => {
        this.setState({
            confirmModal: submit
        })
    };

    hideConfirmDialog = () => {
        this.setState({confirmModal: false})
    };

    hideSetPrimaryDialog = () => {
        this.setState({setPrimaryModalShow: false})
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.fetchContacts()
        })
    }

    handleFilterClear = () => {
        this.setState({
            queryFilterFields: this.props.queryFilterFields,
        }, () => {
            this.fetchContacts()
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.fetchContacts();
        })
    };

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => {
            this.fetchContacts();
        })
    };

    getFields = () => {
        return this.props.fields
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,

            id: this.getOrganizationId(),
            ...this.props.additionalQuery ?? {},
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    getPrimaryKey = () => {
        return this.props.primaryKey
    }

    getOrganizationId = () => {
        return this.props.organizationID;
    }

    getResourceName = () => {
        return this.props.resourceName
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            ContactType: new Field('ContactType', '', [''], false, 'select'),
            HighImportance: new Field('HighImportance', '', [''], false, 'checkbox'),
            archived: new Field('archived', '', [''], false, 'checkbox'),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {
                hideLabel: true,
                labelType: "float"
            }, {menuPlacement: "top"})
        }
    }

    render() {
        const {translate, secondResource} = this.props;

        const dataItems = this.props.isCreate ? this.props.Contacts : getProp(secondResource, "data.list", [])
        const isLoading = getProp(secondResource, "isLoading", false)
        const queryFilterFieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.queryFilterFields)), translate, this.handleFilterInputChange, {})
        const isSortHidden = this.props.isCreate

        return (
            <div>
                <div className={"mb-4 ml-4"}>
                    <PageHeader
                        title={translate("page.heading.dispatch")}
                        titleClass="text-tm-gray-900 mr-5 text-2xl"
                        buttonLabel={translate("btn.add/create")}
                        onButtonClick={() => this.handleShowCreateModal()}
                        hasPerm={checkPerm(this.getResourceName(), CREATE_PERM)}
                    >
                        <button className="btn btn-header ml-auto mr-2" onClick={this.fetchContacts}>
                            <ArrowPathIcon className="w-5 h-5"/>
                        </button>

                    </PageHeader>
                </div>

                <Card>
                    <TableCard addClass={'relative'}>
                        {this.props.queryFilterFields && (
                            <div className="grid grid-cols-5 p-3">
                                {queryFilterFieldsHtml}
                            </div>
                        )}

                        <ResourceTable
                            addClass="rounded-card"
                            data={dataItems}
                            count={this.props.isCreate ? 0 : getProp(secondResource, "data.count", 0)}

                            commonTable={true}

                            fields={this.state.fields}
                            translate={this.props.translate}
                            isLoading={isLoading}

                            limit={this.state.limit}
                            offset={this.state.offset}
                            page={this.state.paginationPage}
                            paginationButtonLimit={5}
                            onOffsetChange={this.handleUpdateOffset}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={isSortHidden ? null : this.handleUpdateSort}

                            onView={checkPerm(Resources.Contacts, UPDATE_PERM) ? this.handleShowDetails : null}

                            actions={[
                                {
                                    action: this.setPrimaryResource,
                                    icon: CheckBadgeIcon,
                                    visible: (item) => !item.IsPrimary,
                                    label: false,
                                    title: translate('btn.set_contact_as_primary'),
                                    disabled: false,
                                    class: false,
                                    iconClass: false
                                },
                                {
                                    action: this.archiveResource,
                                    customContent: (item) => <TrashIcon
                                        className={classNames("h-4 w-4", item.IsPrimary ? "text-tm-gray-400" : "text-tm-gray-600")}/>,
                                    visible: () => checkPerm(Resources.Contacts, DELETE_PERM),
                                    title: (item) => item.IsPrimary ? translate("text.primary_contact_delete") : translate("btn.delete")
                                },
                            ]
                            }
                        />

                        <NoRecordsTable
                            show={(dataItems.length === 0) && !isLoading}
                            canCreate={false}
                            title={translate("text.no_matching_records")}
                            className={"pt-16 px-6"}
                        />

                        <TableCardFooter
                            show={!(!dataItems.length && !isLoading)}
                        >
                            <Pagination
                                count={this.props.isCreate ? 0 : getProp(secondResource, "data.count", 0)}
                                isLoading={isLoading}
                                handleQueryChange={
                                    (name, value, currentPage) => name === "offset"
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                            />
                        </TableCardFooter>

                    </TableCard>

                    {this.state.createModalShow && (
                        <ContactSelectCreateModal
                            {...this.props}
                            show={this.state.createModalShow}
                            title={'Add Contact'}
                            widthClass={'max-w-7xl'}
                            disableContactCreate={this.props.disableContactCreate}
                            queryFilterFields={this.state.queryFilterFields}
                            disableMemberOfOrganization={this.props.disableMemberOfOrganization}
                            dialogTableSelectFields={this.props.dialogTableSelectFields}
                            selectableCollumn={this.props.selectableCollumn}
                            systemUserQuery={{IsDispatcher: 1}}

                            // ADDITIONAL FIELDS
                            additionalFields={this.props.additionalFields ?? {
                                DepartmentID: new Field("DepartmentID", '', ['empty'], false, 'select', {
                                    addContainerClass: 'relative md:col-span-2 mb-3'
                                }),
                                Notes: new Field("Notes", '', []),
                            }}

                            // HELPERS
                            query={this.getQuery()}
                            ExcludeIDs={dataItems.map(it => it.ContactID)}

                            // HANDLERS
                            onClose={() => this.setState({createModalShow: false})}

                            handleAddExistingContact={(item, additionalFields) => {
                                if (this.props.onAddExistingContact) {
                                    this.props.onAddExistingContact(item, additionalFields)
                                    this.setState({createModalShow: false})
                                } else {
                                    this.props.dispatch(createSecondResource({
                                        user: LocalStorage.get('user'),
                                        params: {
                                            ContactID: item.ContactID,
                                            id: this.getOrganizationId(),
                                            ...additionalFields,
                                            ContactLocationNotes: additionalFields.Notes
                                        },
                                        query: this.getQuery(),
                                        resource: this.getResourceName(),
                                        piggyResource: this.getResourceName(),
                                        errorMessage: true,
                                        successMessage: `You added ${item.FirstName} ${item.LastName}`,
                                    }));
                                    this.setState({createModalShow: false})
                                }
                            }}

                            handleCreateNewContact={(fields, phones, files, additionalFields) => {
                                if (this.props.onCreateNewContact) {
                                    this.props.onCreateNewContact(fields, phones, files, additionalFields)
                                } else {
                                    this.props.dispatch(createSecondResource({
                                        user: LocalStorage.get('user'),
                                        params: Object.assign({},
                                            {
                                                ...additionalFields,
                                                ...fields,
                                                Phones: phones,
                                                HighImportance: fields?.HighImportance?.value ? 1 : 0,
                                                Roles: fields.Roles ?? [],
                                            }, {
                                                ContactID: -1,
                                                id: this.getOrganizationId()
                                            },
                                        ),
                                        errorMessage: true,
                                        successMessage: `You created and added ${fields.FirstName} ${fields.LastName}`,
                                        query: this.getQuery(),
                                        resource: this.getResourceName(),
                                        piggyResource: this.getResourceName(),
                                        file: [files],
                                        fileResource: Resources.ContactImage
                                    }));
                                }
                            }}
                        />
                    )}

                    <ModalConfirm
                        title={translate("text.Confirm")}
                        show={!!this.state.confirmModal}
                        text={this.state.confirmText}
                        onClose={this.hideConfirmDialog}
                        buttonLabel={translate("btn.confirm")}
                        closeButtonLabel={'Cancel'}
                        translate={translate}
                        onConfirm={() => {
                            this.handleDelete(this.state.selectedItem, this.getQuery())
                            this.hideConfirmDialog(false)
                        }}
                    />

                    <ModalConfirm
                        type={"info"}
                        title={translate("text.Confirm")}
                        show={!!this.state.setPrimaryModalShow}
                        text={this.state.confirmText}
                        onClose={this.hideSetPrimaryDialog}
                        buttonLabel={translate("btn.confirm")}
                        closeButtonLabel={'Cancel'}
                        translate={translate}
                        onConfirm={() => {
                            this.handleSetPrimary(this.state.selectedItem, this.getQuery())
                            this.hideSetPrimaryDialog(false)
                        }}
                    />
                </Card>
            </div>
        )
    }
}
