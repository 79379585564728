import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, getResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {Field, FieldsManager} from '../../../data/services/fields'
import {Square2StackIcon} from '@heroicons/react/24/outline'
import {CREATE_PERM, DEFAULT_CRUD_STATE, RESOURCE_TABLE_SIMPLE_DEFAULT_OPTIONS} from '../../../util/util-constants'
import {checkPerm, getDefaultFieldOrder, getProp, mergeDeep, orderFields} from '../../../common/util/util-helpers'
import Layout from "../../../common/components/layout";
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import ResourceTable from "../../../common/components/resource-table";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import GoBackButton from "../../../common/components/layout/layout-components/go-back-button";
import {DEFAULT_QUERY_LIMIT} from "../../../common/util/util-consts";

// TODO: ADD NO DUPLICATES MESSAGE
class DuplicatesView extends Component {
    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1)

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: 'Name1',

            fieldsOrder: getDefaultFieldOrder(this.getFields(), this.pagePath),

            // Filter, and header fields
            queryFilterFields: this.getQueryFilterFields(),

            headerFilterFields: [],
            selectedItem: null,
            confirmDialog: false,
            confirmDialogHideAnimation: false
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    mergeDuplicates = (item) => {
        this.setState({text: this.props.translate('message.are_you_sure_merge_duplicate')}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({
                    confirmDialog: false,
                    offset: 0,
                    paginationPage: 1
                }, () => {
                    this.props.dispatch(createResource({
                        user: LocalStorage.get('user'),
                        params: {
                            ID1: item.ID1,
                            ID2: item.ID2
                        },
                        query: this.getQuery(),
                        resource: this.getResource(),
                        piggyResource: this.getResource(),
                        errorMessage: true,
                        successMessage: `Successfully merge ${item.CityName2} with ${item.CityName1}`,
                        showRedirectDialog: false
                    }))
                })
            }, item)
        })
    }

    /** UI Events
     ================================================================= */
    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    handleFilterClear = () => {
        this.setState({
            queryFilterFields: this.getQueryFilterFields(),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }
    /** Helpers
     ================================================================= */
    updateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    getFields = () => {
        return {
            Name1: new Field('Name1', '', ['empty']),
            AddressName1: new Field('AddressName1', '', ['empty']),
            CityName1: new Field('CityName1', '', ['empty']),
            PostalCode1: new Field('PostalCode1', '', ['empty']),

            Name2: new Field('Name2', '', ['empty']),
            AddressName2: new Field('AddressName2', '', ['empty']),
            CityName2: new Field('CityName2', '', ['empty']),
            PostalCode2: new Field('PostalCode2', '', ['empty']),
        }
    }

    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'limit', 'query', 'offset', 'paginationPage', 'showArchived'])
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            archived: this.state.archived ? 1 : 0,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {})
        }
    }

    getResource = () => {
        return Resources.LocationsDuplicates
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props
        const data = getProp(resource.data, 'list', [])
        const isLoading = resource.isLoading;

        return (
            <Layout {...this.props}>
                <Page className="default-transition">
                    <PageHeader
                        title={
                            <div className={'flex justify-start items-center'}>
                                <GoBackButton
                                    translate={this.props.translate}
                                    history={this.props.history}
                                    path={'/locations'}
                                />
                                <div className="ml-2">{translate('page.heading.duplicates')}</div>
                            </div>
                        }
                        titleClass="mr-5 text-2xl"
                    />

                    <TableCard>
                        <TableFilters
                            filterFields={this.state.queryFilterFields}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                        />

                        <ResourceTable
                            data={data}
                            tableID={"duplicates"}
                            count={resource?.data?.count}

                            fields={orderFields(this.getFields(), this.state.fieldsOrder)}
                            translate={this.props.translate}
                            isLoading={resource.isLoading}
                            options={RESOURCE_TABLE_SIMPLE_DEFAULT_OPTIONS}
                            limit={this.state.queryFilterFields.limit.value}
                            offset={this.state.offset}
                            page={this.state.paginationPage}

                            onOffsetChange={this.handleUpdateOffset}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.updateSort}

                            actions={[
                                {
                                    tooltipText: () => translate('text.merge'),
                                    hasPerm: checkPerm(Resources.LocationsDuplicates, CREATE_PERM),
                                    action: this.mergeDuplicates,
                                    icon: () => Square2StackIcon
                                }
                            ]}
                        />

                        <TableCardFooter
                            show={!(!data.length && !isLoading)}
                        >
                            <Pagination
                                count={resource?.data?.count}
                                isLoading={isLoading}
                                handleQueryChange={
                                    (name, value, currentPage) => name === "offset"
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                            />
                        </TableCardFooter>
                    </TableCard>

                    <ModalConfirm
                        title={'Confirm'}
                        show={!!this.state.confirmDialog}
                        text={this.state.text}
                        onClose={() => this.setState({confirmDialog: false})}
                        buttonLabel={translate('btn.confirm')}
                        closeButtonLabel={'Cancel'}
                        translate={translate}
                        onConfirm={this.state.confirmModal}
                    />
                </Page>
            </Layout>
        )
    }
}

export default connect(state => state)(DuplicatesView)
