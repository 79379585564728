import {ChevronRightIcon} from '@heroicons/react/20/solid'
import React, {useEffect, useRef, useState} from "react";
import {classNames} from "../../../util/util-helpers";
export default function StackedList({data, fields, listKey, onTapHold, onTapRow, isLoading = false, colRightClass}) {
    const timeoutRef = useRef(setTimeout(() => null));
    const listItems = Object.values(fields);

    const [pressedRow, setPressedRow] = useState(undefined);

    const handleMouseDown = (it) => {
        // Set the state to indicate that the element is pressed
        setPressedRow(it);
        // Set a timeout to simulate the tap and hold duration (adjust the duration as needed)
    };

    const handleMouseUp = () => {
        // Reset the state when the mouse is released
        if (onTapRow) {
            onTapRow(pressedRow);
        }

        setPressedRow(undefined);

    };

    useEffect(() => {
        clearTimeout(timeoutRef.current)
        timeoutRef.current = setTimeout(() => {
            if (pressedRow && onTapHold) {
                // Perform the action you want to simulate on tap and hold
                onTapHold(pressedRow);
                setPressedRow(undefined);
            }
        }, 1000); // 1000 milliseconds (1 second) in this example
    }, [pressedRow]);


    return (<ul role="list" className="divide-y divide-tm-gray-100 bg-inverse px-4 sm:px-6 lg:px-8">
        {data.map((it) => {
            const leftPrimaryData = listItems[0]?.metadata?.render ? listItems[0].metadata.render(it) : it[listItems?.[0]?.name];
            const leftSecondaryData = listItems[1]?.metadata?.render ? listItems[1].metadata.render(it) : it[listItems?.[1]?.name];
            const isRightPrimaryDataHidden = listItems[2]?.type === 'hidden';
            const rightPrimaryData = listItems[2]?.metadata?.render && !isLoading ? listItems[2].metadata.render(it) : it[listItems?.[2]?.name];
            const isRightSecondaryDataHidden = listItems[3]?.type === 'hidden';
            const rightSecondaryData = listItems[3]?.metadata?.render ? listItems[3].metadata.render(it) : it[listItems?.[3]?.name];
      
            return (
                <li
                    key={it[listKey]}
                    className="relative flex justify-between gap-x-6 py-5 group"
                    onMouseDown={() => handleMouseDown(it)}
                    onMouseUp={handleMouseUp}
                    onTouchStart={() => handleMouseDown(it)}
                    onTouchEnd={handleMouseUp}
                >
                    <div className="flex min-w-0 gap-x-4">
                        <div className="min-w-0 flex-auto">
                            <div
                                className={classNames("text-sm font-semibold leading-6", isLoading ? "text-transparent animate-pulse bg-tm-gray-100 rounded-btn" : "text-tm-gray-900")}>
                            <span className={isLoading ? "invisible" : undefined}>
                                {leftPrimaryData}
                            </span>
                            </div>

                            {!!leftSecondaryData && (
                                <div
                                    className={classNames("mt-1 flex text-xs leading-5", isLoading ? "text-transparent animate-pulse bg-tm-gray-100 rounded-btn" : "text-tm-gray-500")}
                                >
                                <span className={isLoading ? "invisible" : undefined}>
                                    {leftSecondaryData}
                                </span>
                                </div>)}
                        </div>
                    </div>

                    <div className="flex shrink-0 items-center gap-x-4">
                        {((!isRightPrimaryDataHidden && !!rightPrimaryData) || (!isRightSecondaryDataHidden && !!rightSecondaryData)) && (
                            <div className={classNames(colRightClass ?? "hidden sm:flex sm:flex-col sm:items-end")}>
                                {!!rightPrimaryData && !isRightPrimaryDataHidden && (
                                    <p className="text-sm leading-6 text-tm-gray-900">
                                        {rightPrimaryData}
                                    </p>)}

                                {!!rightSecondaryData && !isRightSecondaryDataHidden && (
                                    <p className="mt-1 text-xs leading-5 text-tm-gray-500">
                                        {rightSecondaryData}
                                    </p>)}
                            </div>)}
                        <ChevronRightIcon
                            className="h-5 w-5 flex-none text-tm-gray-400 group-hover:text-primary"
                            aria-hidden="true"/>
                    </div>
                </li>)
        })}
    </ul>)
}