import React, {Component} from 'react'
import {connect} from 'react-redux'
import Resources from '../../../data/services/resources'
import InfoTab from './driverInfoTab'
import {
    checkPerm, classNames,
    focusOnAddedPhone,
    getDefaultUserOfficeValues,
    getDocumentTypesFor,
    getProp,
    resourceIsCreated, resourceIsUpdated
} from '../../../common/util/util-helpers'
import DriverEndorsementsTab from './driverEndorsemetsTab'
import {showGlobalModal} from '../../../data/actions/ui'
import DriverVacationsTab from './driverVacations'
import {Field, FieldsManager} from '../../../data/services/fields'
import {createResource, getResource, resetResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import DriverPreferencesTab from './driverPreferencesTab'
import DriverRestrictionsTab from './driverRestrictionsTab'
import {deleteDocument, download, uploadDocument} from '../../../data/actions/download'
import {getCountTaskResource} from '../../../data/actions/taskResource'
import Tippy from '@tippyjs/react'
import {getInfoResource, resetInfoResource} from '../../../data/actions/infoResource'
import DriverSettlementTab from './driver-settlement-tab'
import moment from 'moment'
import {
    CREATE_PERM,
    DEFAULT_CONTACT_INFO_FIELDS,
    DEFAULT_DATABASE_DATETIME_FORMAT,
    DEFAULT_PHONES_FIELDS,
    DELETE_PERM,
    READ_PERM,
    REFERENCE_TYPE_DRIVER,
    UPDATE_PERM
} from '../../../util/util-constants'
import {currentDate, toFrontDateNoTime} from '../../../common/util/util-dates'
import {scrollErrorIntoView} from '../../../common/util/util-vanilla'
import DriverPayrollTab from './driver-payroll-tab'
import DriverDeductionTab from './driver-deduction-tab'
import DriverDispatchTab from './driverDispatchTab'
import ReviewsTab from "../driver-single/reviewsTab";
import {getContactResource} from "../../../data/actions/contactResource";
import DriverNotesTab from "./driverNotesTab";
import {getPhoneFieldsKeyValues} from "../../../common/components/misc/phone-fields";
import FieldSwitchLabel from "../../../common/components/fields/field-switch/field-switch-label";
import {
    DEFAULT_EMERGENCY_CONTACT_FIELDS
} from "../../../common/components/contact/contact-fields/emergency-contact-fields";
import CopyToClipboardButton from "../../../common/components/buttons/copy-to-clipboard-button";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import Page from "../../../common/components/layout/layout-components/page";
import GoBackButton from "../../../common/components/layout/layout-components/go-back-button";
import NavResponsive from "../../../common/components/nav-responsive";
import MobileTabs from "../../../common/components/tabs-navigation/mobile-tabs";
import DocumentsUpdateTab from "../../../common/components/tabs/documents-tab/update-documents";
import DocumentsCreateTab from "../../../common/components/tabs/documents-tab/create-documents";
import EmploymentTab from "../../../common/components/tabs/employment-tab";
import LoadsTab from "../../../common/components/modal/load-tab";
import ResourceListTab from "../../../common/components/tabs/resource-list-tab";
import ExpiryItemsTab from "../../../common/components/tabs/expiry-items-tab";
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";
import RedirectDialog from "../../../common/components/modal/redirect-dialog";
import ResourceTasksDialog from "../../../common/components/modal/tasksDialogs/resourceTasksDialog";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import Layout from "../../../common/components/layout";
import EFSIntegrationDialog from "../../../common/components/modal/efs-integration-dialog";
import ModalDefault from "../../../common/components/modal/modal-default";
import Card from "../../../common/components/card";
import {DEFAULT_STATE} from "../../../common/util/countryCodes";
import DriverStatusBadge from "../../../common/components/badge/driver-status-badge";
import {LoaderSmall} from "../../../common/components/loader";
import {DEFAULT_QUERY_LIMIT} from "../../../common/util/util-consts";
import DriverMobileTab from "./driver-mobile-tab";

class DriverCreateView extends Component {
    constructor(props) {
        super(props)

        this.tabs = [
            {
                name: 'DriverInfo',
                resource: Resources.DriverInfo,
                current: false,
                visible: checkPerm(Resources.DriverInfo, READ_PERM)
            },
            {
                name: 'DriverPayroll',
                resource: Resources.DriversPayroll,
                current: false,
                visible: this.isTabVisible(Resources.DriversPayroll)
            },
            {
                name: 'DriverEmployments',
                resource: Resources.DriverEmployments,
                current: false,
                visible: this.isTabVisible(Resources.DriverEmployments)
            },
            {
                name: 'DriverSettlements',
                resource: Resources.DriverSettlements,
                current: false,
                visible: this.isTabVisible(Resources.DriverSettlements)
            },
            {
                name: 'DriverVacations',
                resource: Resources.DriverVacations,
                current: false,
                visible: this.isTabVisible(Resources.DriverVacations)
            },
            {
                name: 'DriverDispatch',
                resource: Resources.DriverDispatch,
                current: false,
                visible: this.isTabVisible(Resources.DriverDispatch)
            },
            {
                name: 'DriverPreferences',
                resource: Resources.DriverPreferences,
                current: false,
                visible: this.isTabVisible(Resources.DriverPreferences)
            },
            {
                name: 'DriverRestrictions',
                resource: Resources.DriverRestrictions,
                current: false,
                visible: this.isTabVisible(Resources.DriverRestrictions)
            },
            {
                name: 'DriverMobileAppAccess',
                resource: Resources.DriversMobile,
                current: false,
                visible: true//this.isTabVisible(Resources.DriversMobile)
            },
            {
                name: 'DriverEndorsements',
                resource: Resources.DriverEndorsements,
                current: false,
                visible: this.isTabVisible(Resources.DriverEndorsements)
            },
            {
                name: 'DriverClaims',
                resource: Resources.DriversClaims,
                current: false,
                visible: this.isTabVisible(Resources.DriversClaims)
            },
            {
                name: 'DriverAccidents',
                resource: Resources.DriversAccidents,
                current: false,
                visible: this.isTabVisible(Resources.DriversAccidents)
            },
            {
                name: 'DriverReviews',
                resource: Resources.DriverReviews,
                current: false,
                visible: this.isTabVisible(Resources.DriverReviews)
            },
            {
                name: 'DriverNotes',
                resource: Resources.DriverNotes,
                current: false,
                visible: this.isTabVisible(Resources.DriverNotes)
            },
            {
                name: 'DriverLoads',
                resource: Resources.DriversLoads,
                current: false,
                visible: this.isTabVisible(Resources.DriversLoads)
            },
            {
                name: 'ExpiryItems',
                resource: Resources.DriversExpiry,
                current: false,
                visible: this.isTabVisible(Resources.DriversExpiry)
            },
            {
                name: 'DriverDocuments',
                resource: Resources.DriverDocuments,
                current: false,
                visible: this.isTabVisible(Resources.DriverDocuments)
            }
        ]

        if (this?.tabs?.[0]) {
            this.tabs[0].current = true
        }

        this.state = {
            fields_info: this.getFieldsInfo(),
            files_info: [],
            phones_info: this.getPhonesInfo(),
            fields_endorsement: [],
            fields_willing: [],
            fields_reviews: [],
            fields_vacations: [],
            fields_documents: [],
            fields_settlements: this.getSettlementFields(),
            isRedirectDialogVisible: false,
            fields_restrictions: [],
            DriverName: '',
            emailExist: false,
            sameAsFirstAddress: false,
            createNewContactBtn: false,
            tabs: this.tabs,
            selectedTab: this.tabs?.[0]?.resource,
            payAdjustments: [],
            //IMAGE CROP
            src: null,
            crop: {
                unit: '%',
                width: 30,
                aspect: 1 / 1,
            },
            isCropVisible: false,
            isResourceTaskModalVisible: false,
            linkedContactData: {},
            contactInfoData: getProp(this.props.contactResource, 'data.list', []),
        }
        this.fileImageObject = null
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        if (this.getID()) {
            this.fetchData()
            this.fetchInfoData()
            this.fetchTaskResourceCount()
            this.handleCheckIfRedirected()
        } else {
            this.props.dispatch(resetInfoResource())
            this.props.dispatch(resetResource())
        }
        this.fetchContacts()
        this.getPropName()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.resource !== prevProps.resource && !prevProps.resource.showRedirectDialog && !!this.props.resource.showRedirectDialog) {
            this.handleToggleRedirectDialog()
        }

        if (this.props.resource !== prevProps.resource && !!this.getID()) {
            this.setState({
                fields_info: this.getFieldsInfo(getProp(this.props.resource, 'data', {})),
                phones_info: this.getPhonesInfo(getProp(this.props.resource, 'data.ContactPhone', [])),
            })
            if (this.state.selectedTab === Resources.DriverInfo) {
                this.setState({
                    DriverName: getProp(this.props.resource, 'data.FirstName', '') + ' ' + getProp(this.props.resource, 'data.LastName', ''),
                    ArchivedDate: getProp(this.props.resource, 'data.ArchivedDate', null),
                })
            }
        }

        if (this.props.info !== prevProps.info && !!this.getID()) {
                this.setState({
                    DriverName: getProp(this.props.info, 'data.FirstName', '') + ' ' + getProp(this.props.info, 'data.LastName', ''),
                })
        }

        if (this.props.contactResource !== prevProps.contactResource) {
            this.setState({contactInfoData: getProp(this.props.contactResource, 'data.list', [])})
        }

        if (prevState.selectedTab !== this.state.selectedTab && this.state.selectedTab === Resources.DriverInfo && this.getID()) {
            this.fetchData()
        }
        if (!this.getID() && !!this.props.resource.errorMessage && !prevProps.resource.errorMessage && (this.props.resource.errorMessage === 'EMAIL_PARAMETER_ALREADY_EXISTS')) {
            this.setState({emailExist: true, selectedTab: Resources.DriverInfo})
        }

        if (resourceIsCreated(this.props.resource, prevProps.resource)) {
            this.setState({
                payAdjustments: []
            })
        }

        if (resourceIsUpdated(this.props.resource, prevProps.resource) && (this.props.resource.resource === Resources.DriversPending)) {
            this.fetchInfoData();
        }
    }

    /** Data Events
     ================================================================= */

    fetchData = () => { // data for info tab
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.DriverInfo,
            query: {id: this.getID()}
        }))
    }

    fetchInfoData = () => { // global info data
        this.props.dispatch(getInfoResource({
            user: LocalStorage.get('user'),
            resource: Resources.DriverInfo,
            query: {id: this.getID()}
        }))
    }

    fetchTaskResourceCount = () => {
        this.props.dispatch(getCountTaskResource({
            user: LocalStorage.get('user'),
            resource: Resources.TaskResourceCount,
            query: {param: window.location.pathname}
        }))
    }

    fetchContacts = () => {
        this.props.dispatch(getContactResource({
            user: LocalStorage.get("user"),
            resource: Resources.ContactsQuick,
            query: {}
        }))
    }

    submit = () => {
        const fieldsList = this.getFieldList()

        this.setState({
            fields_info: FieldsManager.validateFields(this.state.fields_info, fieldsList),
            phones_info: this.state.phones_info.map((fields) => FieldsManager.validateFields(fields)),
        }, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields_info, fieldsList) && this.state.phones_info.reduce((memo, fields) => (memo && FieldsManager.checkFieldsForErrors(fields)))) {
                const phones = getPhoneFieldsKeyValues(this.state.phones_info)
                const fields = this.formatMailingAddressValues(this.state.fields_info)
                let params = Object.assign({},
                    fields,
                    {
                        Phones: phones,
                        Endorsements: this.state.fields_endorsement.map((fields) => FieldsManager.getFieldKeyValues(fields)),
                        Preference: this.state.fields_willing.map((fields) => {
                            const params = FieldsManager.getFieldKeyValues(fields)
                            return {
                                ...params,
                                Preferences: Array.isArray(params.PreferenceTypeSubItem) ? params.PreferenceTypeSubItem.map(it => {
                                    return it.__isNew__ ? {
                                        value: -1,
                                        label: it.label,
                                    } : it
                                }) : []
                            }
                        }),
                        Reviews: this.state.fields_reviews.map((fields) => FieldsManager.getFieldKeyValues(fields)),
                        Vacations: this.state.fields_vacations.map((fields) => FieldsManager.getFieldKeyValues(fields)),
                        Restrictions: this.state.fields_restrictions.map((fields) => FieldsManager.getFieldKeyValues(fields)),
                        Settlements: FieldsManager.getFieldKeyValues(this.state.fields_settlements),
                        Active: true
                    }, {
                        PayAdjustments: this.state.payAdjustments
                    }
                )
                this.props.dispatch(createResource({
                    user: LocalStorage.get('user'),
                    params: params,
                    resource: Resources.Drivers,
                    file: [this.fileImageObject],
                    fileResource: Resources.ContactImage,
                    errorMessage: true,
                    showRedirectDialog: true,
                    document: this.state.fields_documents,
                    documentResource: Resources.DriverDocuments
                }))
            } else {
                this.state.selectedTab !== Resources.DriverInfo && this.handleTabChange(Resources.DriverInfo)
                scrollErrorIntoView(this.state.fields_info)
            }
        })
    }

    updateResource = () => {
        const fieldsList = this.getFieldList()
        this.setState({
            fields_info: FieldsManager.validateFields(this.state.fields_info, fieldsList),
            phones_info: this.state.phones_info.map((fields) => FieldsManager.validateFields(fields)),
        }, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields_info, fieldsList) && this.state.phones_info.reduce((memo, fields) => (memo && FieldsManager.checkFieldsForErrors(fields)))) {
                const ContactID = getProp(this.props.resource, 'data.ContactID', null)
                const phones = getPhoneFieldsKeyValues(this.state.phones_info)
                const fields = this.formatMailingAddressValues(this.state.fields_info)
                this.props.dispatch(updateResource({
                    user: LocalStorage.get('user'),
                    params: Object.assign({id: this.getID()}, fields, {
                        ContactID: ContactID,
                        Phones: phones
                    }),
                    query: {
                        id: this.getID()
                    },
                    fileParams: {
                        id: ContactID
                    },
                    errorMessage: true,
                    successMessage: `Driver ${this.state.fields_info.FirstName.value} ${this.state.fields_info.LastName.value} updated`,
                    file: [this.fileImageObject],
                    resource: Resources.DriverInfo,
                    piggyResource: Resources.DriverInfo,
                    fileResource: Resources.ContactImage,
                    infoPiggyResource: Resources.DriverInfo
                }))
                this.setState({isDirty: false})
            } else {
                scrollErrorIntoView(this.state.fields_info)
            }
        })
    }

    /** UI Events
     ================================================================= */

    handleToggleRedirectDialog = () => {
        // and reset state
        this.fileImageObject = null // reset image

        this.setState({
            createdResourceID: this.props.resource.create && this.props.resource.create.DriverID,
            isRedirectDialogVisible: !this.state.isRedirectDialogVisible,
            // Reset state
            fields_info: this.getFieldsInfo(),
            phones_info: this.getPhonesInfo(),
            src: null,
            croppedImageUrl: null,
            files_info: [],
            isDirty: false
        })
    }

    handleSettlementInputCreateChange = (name, value) => {
        let tmp = {
            value: -1,
            label: value
        }
        let newValue = this.state.fields_settlements[name].value ? [...this.state.fields_settlements[name].value, tmp] : [tmp]
        this.setState({
            fields_settlements: FieldsManager.updateField(this.state.fields_settlements, name, newValue),
            isDirty: true
        })
    }

    handleCreateNewResourceTask = () => {
        this.props.dispatch(showGlobalModal('createTaskDialog', {taskResource: this.props.match.url}))
    }

    handleToggleResourceTasks = () => {
        this.setState({
            isResourceTaskModalVisible: !this.state.isResourceTaskModalVisible
        })
    }

    handleAddPayAdjustments = (params) => {
        const payAdjustments = this.state.payAdjustments;
        payAdjustments.push(params);
        this.setState({
            payAdjustments,
            isDirty: true
        });
    }

    /** Fields
     ================================================================= */
    getSettlementFields = () => {
        return {
            LoadPayMethodID: new Field('LoadPayMethodID', '', ['empty'], false, 'select', {addContainerClass: 'col-span-6'}),
            PayMethodValue: new Field('PayMethodValue', '', ['empty'], false, 'text', {addContainerClass: 'col-span-6'}),
            PayMethodValueExtra: new Field('PayMethodValueExtra', '', [], false, 'hidden', {addContainerClass: 'col-span-3'}),
            FuelCards: new Field('FuelCards', '', [], false, 'creatable-select-search', {addContainerClass: 'col-span-full'}, {
                onCreateOption: this.handleSettlementInputCreateChange,
                isMulti: true
            }),
            Transponders: new Field('Transponders', '', [], false, 'creatable-select-search', {addContainerClass: 'col-span-full'}, {
                onCreateOption: this.handleSettlementInputCreateChange,
                isMulti: true
            }),

            // PAY TO
            IsPaysIFTA: new Field('IsPaysIFTA', '', [], false, 'checkbox', {
                addContainerClass: 'col-span-4',
            }),
            IsPaysFuel: new Field('IsPaysFuel', '', [], false, 'checkbox', {
                addContainerClass: 'col-span-4',
            }),

            Send1099: new Field('Send1099', '', [], false, 'checkbox', {
                addContainerClass: 'col-span-4',
            }),

            PayOnlyWhenPODReady: new Field('PayOnlyWhenPODReady', '', [], false, 'checkbox', {
                addContainerClass: 'col-span-full',
                labelType: "float"
            }),

            IsFactoringActive: new Field('IsFactoringActive', '', [], false, 'switch', {
                addContainerClass: "-mx-4 pr-4 col-span-full flex items-center hover:bg-sky-600/10 rounded-xl",
                htmlBefore: (it) =>
                    <FieldSwitchLabel
                        onClick={() => this.handleSettlementsInputChange("IsFactoringActive", !it.value)}
                        label={this.props.translate("field.PayToOverride")}
                        note={this.props.translate("text.PayToOverrideInfo")}
                    />
            }),

            OrganizationID: new Field('OrganizationID', '', [], true, 'select-search', {addContainerClass: 'col-span-full'}),
        }
    }

    getFieldsInfo = (item = {}) => {
        let fields = Object.assign({},
            DEFAULT_CONTACT_INFO_FIELDS(),
            DEFAULT_EMERGENCY_CONTACT_FIELDS(),
            {
                ContactID: new Field('ContactID', -1, ['empty'], false, 'select-search'),

                // Notes
                Notes: new Field('Notes', '', [], false, 'textarea', {
                    heightClass: 'h-52',
                    addContainerClass: 'col-span-3'
                }),
                DriverAssignmentStatusID: new Field('DriverAssignmentStatusID', '', [], false, 'select', {}, {isClearable: true}),
                AssignmentNotes: new Field('AssignmentNotes', '', [], false, 'textarea', {
                    heightClass: 'h-52',
                    addContainerClass: 'col-span-3'
                }),

                // Address Info
                CountryID: new Field('CountryID', 1, [], false, 'select', {addContainerClass: "col-span-4"}),
                GooglePlaces: new Field('GooglePlaces', '', [], false, 'google-locations', {
                    setLocations: this.handleSetLocations,
                    addContainerClass: "col-span-8"
                }),

                AddressName: new Field('AddressName', '', [], false, 'text', {
                    addContainerClass: "col-span-full"
                }),
                AddressName2: new Field('AddressName2', '', [], false, 'text', {
                    addContainerClass: "col-span-full"
                }),
                CityName: new Field('CityName', '', [], false, "text", {addContainerClass: "col-span-4"}),
                StateID: new Field('StateID', '', [], false, item?.CountryID === 1 || !item?.CountryID ? 'select' : 'hidden', {addContainerClass: "col-span-4"}),
                PostalCode: new Field('PostalCode', '', [], false, "text", {addContainerClass: "col-span-4"}),

                // DRIVER FIELDS

                SecondAddress: new Field('SecondAddress', '', ['']),
                MailingList: new Field('MailingList', getProp(this.props.resource, 'data.MailingList', ''), [''], false, 'checkbox'),

                // Mailing Address Info
                MailingSameAsFirstAddress: new Field('MailingSameAsFirstAddress', 1, [], false, 'checkbox', {
                    addContainerClass: "col-span-3"
                }),
                MailingListAddressAutocomplete: new Field('MailingListAddressAutocomplete', '', [], false, 'google-locations', {
                    setLocations: this.handleSetMailingLocations,
                    addContainerClass: "col-span-full"
                }),
                MailingAddressName: new Field('MailingAddressName', '', [''], false, 'text', {
                    addContainerClass: "col-span-3"
                }),
                MailingAddressName2: new Field('MailingAddressName2', '', [''], false, 'text', {
                    addContainerClass: "col-span-3"
                }),
                MailingCityName: new Field('MailingCityName', '', ['']),
                MailingStateID: new Field('MailingStateID', '', [''], false, 'select'),
                MailingPostalCode: new Field('MailingPostalCode', '', ['']),

                // Driver Info
                OfficeID: new Field('OfficeID', !this.getID() ? getDefaultUserOfficeValues() : '', [''], false, 'select-search'),
                SocialSecurityNo: new Field('SocialSecurityNo', '', [''], false, 'text', {}, {}),
                UserDefinedField1: new Field('UserDefinedField1', '', []),
                UserDefinedField2: new Field('UserDefinedField2', '', []),
                DriverIDNumber: new Field('DriverIDNumber', '', ['']),
                DriverPositionTypeID: new Field('DriverPositionTypeID', '', [''], false, 'select'),
                GovernmentAndStrictLoads: new Field('GovernmentAndStrictLoads', '', [], false, 'checkbox'),
                LegalStatusID: new Field('LegalStatusID', '', [''], false, item?.GovernmentAndStrictLoads ? 'select' : 'hidden'),

                // CDL Info
                CDLNumber: new Field('CDLNumber', '', [''], false, 'text', {addContainerClass: "col-span-6"}),
                CDLState: new Field('CDLState', '', [''], false, 'select', {addContainerClass: "col-span-6"}),
                CDLIssued: new Field('CDLIssued', '', [''], false, 'date', {addContainerClass: "col-span-6"}),
                CDLExpDate: new Field('CDLExpDate', '', [''], false, 'date', {addContainerClass: "col-span-6"}),
                HasTWICCard: new Field('HasTWICCard', '', [''], false, 'checkbox', {addContainerClass: "col-span-4"}),
                TWICCardExpDate: new Field('TWICCardExpDate', '', [''], !item?.HasTWICCard, 'date', {addContainerClass: "col-span-4"}),
                TWICCardNumber: new Field('TWICCardNumber', '', [''], false, 'text', {addContainerClass: "col-span-4"}),
                HasTSAApproved: new Field('HasTSAApproved', '', [''], false, 'checkbox', {addContainerClass: "col-span-4"}),
                TSAApprovedExpDate: new Field('TSAApprovedExpDate', '', [''], !item?.HasTSAApproved, 'date', {addContainerClass: "col-span-4"}),
                TSANumber: new Field('TSANumber', '', [''], false, 'text', {addContainerClass: "col-span-4"}),
                PharmaApproved: new Field('PharmaApproved', '', [''], false, 'checkbox', {addContainerClass: "col-span-4"}),
                HazmatApproved: new Field('HazmatApproved', '', [''], false, 'checkbox', {addContainerClass: "col-span-4"}),
                PreviousExperience: new Field('PreviousExperience', '', [''], false, 'text', {addContainerClass: "col-span-6"}),
            })

        fields['IsSystemUser'].type = 'hidden'
        fields['Roles'].validate = ['']
        fields['Roles'].type = 'hidden'
        fields['ValidationType'].validate = ['']
        fields['ValidationType'].type = 'hidden'

        fields.Email = new Field('Email', '', ['empty', 'email'], false, 'text', {
                addContainerClass: "md:col-span-full",
                label: "BusinessEmail",
                htmlAfterField: (it) => <div className="h-5 relative top-2 flex">
                    <CopyToClipboardButton
                        clipboardText={it.value}
                        addClass="relative -top-2"
                        translate={this.props.translate}
                    />
                </div>
            }, {
                onBlur: (e) => {
                    e.persist();
                    if (e.target.value) {
                        if (/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/gi.test(e.target.value)) {
                            this.onBlurFetchContacts().then(() => {
                                this.handleInputChange('Email', e.target?.value);
                            });
                        }
                    }
                }
            }
        )

        fields.PrivateEmail = new Field('PrivateEmail', '', [], false, "email", {
            addContainerClass: "md:col-span-6",
            htmlAfterField: (it) => <div className="h-5 relative top-2 flex">
                <CopyToClipboardButton
                    clipboardText={it.value}
                    addClass="relative -top-2"
                    translate={this.props.translate}
                />
            </div>
        })

        if (Object.keys(item).length > 0) {
            fields = fillFieldsFromData(fields, item)
            fields['Groups'].value = getProp(item, 'Groups', []).map(it => ({
                value: it.ContactGroupID,
                label: it.ContactGroupName
            }))
        }

        return fields
    }

    addNewEndorsement = (fields) => {
        let arr = this.state.fields_endorsement
        arr.push(fields)
        this.setState({fields_endorsement: arr})
    }

    removeNewEndorsement = (i) => {
        let arr = this.state.fields_endorsement
        arr.splice(i, 1)
        this.setState({fields_endorsement: arr})
    }

    addNewRestriction = (fields) => {
        let arr = this.state.fields_restrictions
        arr.push(fields)
        this.setState({fields_restrictions: arr})
    }

    removeNewRestriction = (i) => {
        let arr = this.state.fields_restrictions
        arr.splice(i, 1)
        this.setState({fields_restrictions: arr})
    }

    addNewVacation = (fields) => {
        let arr = this.state.fields_vacations
        arr.push(fields)
        this.setState({fields_vacations: arr})
    }
    removeNewVacation = (i) => {
        let arr = this.state.fields_vacations
        arr.splice(i, 1)
        this.setState({fields_vacations: arr})
    }

    addNewReview = (fields) => {
        let arr = this.state.fields_reviews
        arr.push(fields)
        this.setState({fields_reviews: arr})
    }

    removeNewReview = (i) => {
        let arr = this.state.fields_reviews
        arr.splice(i, 1)
        this.setState({fields_reviews: arr})
    }

    handleAddNewPreference = (fields) => {
        let arr = this.state.fields_willing
        arr.push(fields)
        this.setState({fields_willing: arr})
    }

    handleRemovePreference = (i) => {
        let arr = this.state.fields_willing
        arr.splice(i, 1)
        this.setState({fields_willing: arr})
    }

    addPhoneField = () => {
        const newPhones = this.state.phones_info

        newPhones.push(DEFAULT_PHONES_FIELDS())

        this.setState({
            phones_info: newPhones
        }, () => focusOnAddedPhone())
    }

    removePhoneField = (i) => {
        let newPhones = this.state.phones_info
        newPhones.splice(i, 1)
        this.setState({
            phones_info: Array.from(newPhones),
            isDirty: true
        })
    }

    handleInputChange = (name, value) => {
        let isDirty = true
        let Countries = LocalStorage.get('lookup')?.Country ?? [DEFAULT_STATE];

        let fieldsInfo = FieldsManager.updateField(this.state.fields_info, name, value)

        if (name === 'Email' && this.state.emailExist) {
            this.setState({emailExist: false})
        }

        if (name === 'ContactID') {
            this.setState({linkedContactData: value.metadata})
        }

        if (name === 'Email') {
            let data = this.state.contactInfoData;
            let emailExist = data.filter((it) => it.Email === value)
            if (emailExist.length != 0 && getProp(this.props.resource, 'data.Email', null) != value) {
                fieldsInfo[name].errorMessage = [this.props.translate("text.contact_email_in_system")]
            } else {
                fieldsInfo[name].errorMessage = null
            }
            // fieldsInfo = FieldsManager.updateField(fieldsInfo, 'Email', value.toLowerCase())
        }

        isDirty = !fieldsInfo['Email'].errorMessage;

        if (name === 'SocialSecurityNo') {
            value = value.replace(/[-_]/g, '')
        }

        if (name === 'GovernmentAndStrictLoads') {
            fieldsInfo.LegalStatusID.type = value ? 'select' : 'hidden'
            fieldsInfo.LegalStatusID.value = ''
        }

        if (name === 'HasTWICCard') {
            fieldsInfo.TWICCardExpDate.disabled = !value
            fieldsInfo.TWICCardExpDate.value = ''
        }

        if (name === 'HasTSAApproved') {
            fieldsInfo.TSAApprovedExpDate.disabled = !value
            fieldsInfo.TSAApprovedExpDate.value = ''
        }

        if (name === 'AddressName' && !!this.state.sameAsFirstAddress) {
            this.setState({fields: FieldsManager.updateField(fieldsInfo, 'SecondAddress', value)})
        }

        if (name === 'CountryID') {
            fieldsInfo.StateID.type = (value == 1) ? 'select' : 'hidden'
            fieldsInfo.StateID.value = ''

            let phones = this.state.phones_info
            let CountryCode = Countries.find(it => it.CountryID == value)?.CountryCode ?? "+1"

            phones.map(it => {
                it.AreaCode.value = CountryCode
            })
            this.setState({phones_info: phones,})
        }

        if (name === 'HasTWICCard') {
            fieldsInfo = FieldsManager.updateField(fieldsInfo, 'TWICCardExpDate', '')
        }

        if (name === 'HasTSAApproved') {
            fieldsInfo = FieldsManager.updateField(fieldsInfo, 'TSAApprovedExpDate', '')
        }

        if (name === 'LoadPayMethodID') {
            fieldsInfo.PayMethodValueExtra.type = Number(value) === 7 ? 'text' : 'hidden'
            fieldsInfo.PayMethodValueExtra.value = Number(value) === 7 ? fieldsInfo?.PayMethodValueExtra?.value : ''
            fieldsInfo.PayMethodValueExtra.metadata.addContainerClass = Number(value) === 7 ? "col-span-3" : "col-span-6"
            fieldsInfo.PayMethodValue.metadata.addContainerClass = Number(value) === 7 ? "col-span-3" : "col-span-6"
        }

        this.setState({
            isDirty: isDirty,
            fields_info: fieldsInfo
        })
    }

    handleSettlementsInputChange = (name, value) => {
        let fields = this.state.fields_settlements;

        fields = FieldsManager.updateField(fields, name, value);

        if (name === 'IsFactoringActive') {
            fields.OrganizationID.value = !value ? "" : fields.OrganizationID.value;
            fields.OrganizationID.disabled = !value;

            fields.Send1099.value = value ? "" : fields.Send1099.value;
            fields.Send1099.disabled = !!value;
        }

        this.setState({
            fields_settlements: fields
        })
    }

    handleItemInputChange = (i, name, value) => {
        if (name === 'AreaCode' && value[0] !== '+') {
            value = '+' + value
        }

        let fields = this.state.phones_info[i]
        const newItems = this.state.phones_info
        newItems[i] = FieldsManager.updateField(fields, name, value)
        this.setState({isDirty: true, phones_info: newItems})
    }

    handleSetLocations = (fields) => {
        let updatedFields = Object.assign({}, this.state.fields_info)

        for (const [key, value] of Object.entries(fields)) {
            if (key in this.state.fields_info) {
                updatedFields = FieldsManager.updateField(updatedFields, key, value)
            }
        }

        this.setState({
            fields_info: updatedFields,
            isDirty: true
        })
    }

    handleSetMailingLocations = (fields) => {
        let updatedFields = Object.assign({}, this.state.fields_info);

        updatedFields.MailingAddressName.value = fields.AddressName;
        updatedFields.MailingAddressName2.value = fields.AddressName2;
        updatedFields.MailingCityName.value = fields.CityName;
        updatedFields.MailingStateID.value = fields.StateID;
        updatedFields.MailingPostalCode.value = fields.PostalCode;
        //updatedFields.MailingListAddressAutocomplete.value = fields.query

        this.setState({
            fields_info: updatedFields,
            isDirty: true
        })
    }

    acceptFilesInfo = acceptedFiles => {
        this.setState({
            isDirty: true,
            files_info: acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })),
        })
    }

    handleUploadDocument = (files) => {
        let fields = this.state.fields_documents
        fields = fields.concat(files)
        this.setState({fields_documents: fields})
    }

    handleDeleteDocument = (item) => {
        let fields = this.state.fields_documents.filter(it => it.preview !== item.preview)
        this.setState({fields_documents: fields})
    }

    cancelInfoTabHandler = () => {
        this.setState({
            fields_info: this.getFieldsInfo(getProp(this.props.resource, 'data', {})),
            phones_info: this.getPhonesInfo(getProp(this.props.resource, 'data.ContactPhone', [])),
            isDirty: false
        })
    }

    toggleCreateNewContactBtnHandler = () => {
        this.setState({createNewContactBtn: !this.state.createNewContactBtn}, () => {
            if (this.state.createNewContactBtn) {
                this.removeValidationForGivenFields(['FirstName', 'LastName', 'Email', 'Roles', 'ValidationType'])
            } else {
                this.addValidationForGivenFields(['FirstName', 'LastName', 'Email', 'Roles', 'ValidationType'], ['empty'])
            }
        })
    }

    removeValidationForGivenFields = (fieldArray) => {
        let fields = this.state.fields_info
        Object.keys(fields).map(it => {
            if (fieldArray.includes(it)) {
                fields[it].validate = []
            }
        })
        this.setState({fields_info: fields})
    }

    addValidationForGivenFields = (fieldArray, validationArray) => {
        let fields = this.state.fields_info
        Object.keys(fields).map(it => {
            if (fieldArray.includes(it)) {
                fields[it].validate = validationArray
            }
        })
        fields.ContactID.validate = []
        fields.ContactID.value = '-1'
        this.setState({fields_info: fields})
    }

    uploadDocument = (files, descriptions) => {
        this.props.dispatch(uploadDocument({
            user: LocalStorage.get('user'),
            files: files,
            id: this.getID(),
            query: {
                DriverID: this.getID(),
            },
            Descriptions: descriptions,
            resource: Resources.DriverDocuments,
            errorMessage: true,
            successMessage: `Attachment${files.length > 1 ? 's' : ''} ${files.length > 1 ? 'have' : 'has'} been uploaded successfully.`,
            piggySecondResource: Resources.DriverDocuments
        }))
    }

    deleteDocument = (document) => {
        this.props.dispatch(deleteDocument({
            user: LocalStorage.get('user'),
            query: {
                DriverID: this.getID(),
            },
            params: {
                DriverDocumentID: document.DriverDocumentID,
                DriverID: this.getID(),
            },
            errorMessage: true, successMessage: `Attachment has been deleted successfully.`,
            resource: Resources.DriverDocuments,
            piggySecondResource: Resources.DriverDocuments
        }))
    }

    downloadDocument = (document) => {
        const fileExt = document?.OriginalFilename.split('.').pop()

        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.DriverDocuments,
            query: Object.assign({
                DriverDocumentID: document.DriverDocumentID,
                DriverID: this.getID(),
                format: fileExt,
                name: 'document_' + currentDate() + '.' + fileExt
            })
        }))
    }

    cancelImageCropping = () => {
        this.setState({
            src: null,
            croppedImageUrl: null,
            files_info: [],
        }, () => this.fileImageObject = null)
    }

    onSelectFile = acceptedFiles => {
        if (acceptedFiles) {
            const reader = new FileReader()
            reader.addEventListener('load', () =>
                this.setState({
                    src: reader.result,
                    isDirty: true,
                    isCropVisible: true
                },)
            )
            reader.readAsDataURL(acceptedFiles[0])
        }
    }

    // If you setState the crop in here you should return false.
    onImageLoaded = image => {
        this.imageRef = image
    }

    onCropComplete = crop => {
        this.makeClientCrop(crop).then(r => r)
    }

    onCropChange = (crop) => {
        this.setState({crop})
    }

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            )
            this.setState({croppedImageUrl})
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas')
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height
        canvas.width = crop.width
        canvas.height = crop.height
        const ctx = canvas.getContext('2d')

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        )

        return new Promise((resolve) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    return
                }
                blob.name = fileName
                window.URL.revokeObjectURL(this.fileUrl)
                this.fileUrl = window.URL.createObjectURL(blob)
                this.fileImageObject = new File([blob], fileName)
                resolve(this.fileUrl)
            }, 'image/png')
        })
    }

    setCropOk = () => {
        this.setState({
            isCropVisible: false
        })
    }

    handleCheckIfRedirected = () => {
        const query = new URLSearchParams(this.props.location.search)
        const tab = query.get('tab')
        if (tab) this.handleTabChange(tab)
    }

    handleTabChange = (resource) => {
        if (resource === this.state.selectedTab) {
            return false
        }

        if (!this.getID()) {
            this.setState({
                tabs: this.state.tabs.map((it) => {
                    it.current = it.resource === resource
                    return it
                }),
                selectedTab: resource
            })
            return false
        }

        if (this.state.isDirty) {
            this.setState({
                confirmModal: () => {
                    this.tabChange(resource)
                }
            })
        } else {
            this.tabChange(resource)
        }
    }

    tabChange = (resource) => {
        this.setState({
            confirmModal: false,
            isDirty: false,
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource
                return it
            }),
            selectedTab: resource
        }, () => this.props.history.push('?tab=' + resource))
    }

    handleLinkClick = (e) => {
        this.props.history.push(e)
    }

    /** Helpers
     ================================================================= */
    getPhonesInfo = (phones = []) => {
        if (this.getID()) {
            return phones?.map(item => {
                return this.getDefaultPhoneFields(item)
            })
        } else {
            return [this.getDefaultPhoneFields()]
        }
    }

    getDefaultPhoneFields = (item = {}) => {
        return fillFieldsFromData(DEFAULT_PHONES_FIELDS(), item)
    }

    onBlurFetchContacts = () => {
        return new Promise((resolve) => {
            this.fetchContacts();
            setTimeout(() => {
                resolve();
            }, 200);
        });
    }
    getPropName = () => {
        if (this.props.location?.query?.input) {
            let value = this.props.location.query.input.split(' ')
            this.setState({fields_info: FieldsManager.updateField(this.state.fields_info, 'FirstName', value[0])})
            if (value.length > 1) {
                let tmp = ''
                value.forEach((it, i) => {
                    if (i != 0) {
                        tmp += it + ' '
                    }
                })
                this.setState({fields_info: FieldsManager.updateField(this.state.fields_info, 'LastName', tmp)})
            }
        }
    }

    getFieldList = () => {
        let fieldsList = [
            'FirstName',
            'AddressName',
            'CountryID',
            'LastName',
            'Email',
            'CDLNumber',
            'CDLState',
            'CDLIssued',
            'CityName',
            'CDLExpDate',
            'PostalCode',
            'DriverPosition'
        ]

        if (!this.state.fields_info.MailingSameAsFirstAddress.value) {
            fieldsList = [
                'FirstName',
                'MailingAddressName',
                'MailingCityName',
                'MailingPostalCode',
                'AddressName',
                'CountryID',
                'LastName',
                'Email',
                'CDLNumber',
                'CDLState',
                'CDLIssued',
                'CityName',
                'CDLExpDate',
                'PostalCode',
                'DriverPosition'
            ]

            if (this.state.fields_info.CountryID.value == 1) {
                fieldsList.push('MailingStateID')
            }

        }

        if (this.state.fields_info.CountryID.value == 1) {
            fieldsList.push('StateID')
        }

        if (this.state.fields_info.IsSystemUser.value) {
            fieldsList.push('Roles')
            fieldsList.push('ValidationType')
        }

        return fieldsList
    }

    formatMailingAddressValues = (fields) => {
        if (fields.MailingSameAsFirstAddress.value) {
            fields.MailingAddressName.value = fields.AddressName.value
            fields.MailingAddressName2.value = fields.AddressName2.value
            fields.MailingStateID.value = fields.StateID.value
            fields.MailingPostalCode.value = fields.PostalCode.value
            fields.MailingCityName.value = fields.CityName.value
        }

        return FieldsManager.getFieldKeyValues(fields)
    }

    getDocuments = () => {
        if (this.getID()) {
            return getProp(this.props.secondResource, 'data', [])
        }
        return []
    }

    returnLastDriverEmployment = () => {
        const DriverEmployment = getProp(this.props.resource, 'data.DriverEmployment', [])
        let styles = {
            1: 'btn-success',
            2: 'btn-outline',
            3: 'btn-outline-warning'
        }
        const lastEmployment = DriverEmployment[DriverEmployment.length - 1] ?? {}
        let txt = DriverEmployment.length > 0 ? `Last employment info: ${toFrontDateNoTime(lastEmployment.HireDate)} - ${toFrontDateNoTime(lastEmployment.ReleaseDate)} ${lastEmployment.ContractNum ? `(${lastEmployment.ContractNum})` : ''}` : `Driver has no employment info`
        let style = DriverEmployment.length > 0 ? styles[2] : styles[3]
        return (
            <button
                onClick={() => this.tabChange(Resources.DriverEmployments)}
                className={`btn h-8 ${style}`}>
                {txt}
            </button>
        )
    }

    returnDriverStatus = () => {
        let active = getProp(this.props.resource, 'data.Active', null)
        let styles = {
            Active: 'btn-outline-success',
            Paused: 'btn-light',
            Inactive: 'btn-outline-danger'
        }
        if (active) {
            return (
                <span className={`btn-xs active ${styles[active]} cursor-auto rounded`}>
                    {active}
                </span>
            )
        }
    }

    getID = () => {
        return this.props.match.params.id
    }

    isTabVisible = (tabResource) => {
        const isVisible = checkPerm(tabResource, READ_PERM) && this.getID()
        return !!isVisible
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props
        const TaskCount = getProp(this.props.taskResource, 'count.count', 0)
        const infoData = getProp(this.props.info, 'data', {})
        const isLoading = getProp(this.props.info, 'isLoading', true);
        const openTaskCountTwoDigits = TaskCount > 9;

        const hasFooter = !this.getID() ? true : (this.state.selectedTab === Resources.DriverInfo || this.state.selectedTab === Resources.DriverSettlements)

        return (
            <Layout
                {...this.props}
                hasFooter={hasFooter}
                isDirty={this.state.isDirty}
                customPageTitle={(this.getID() && !isLoading) ? this.state.DriverName : 'Driver'}
            >
                <Page>
                    <div className="flex justify-between items-center">
                        <div className="flex">
                            <GoBackButton
                                translate={this.props.translate}
                                history={this.props.history}
                                path={'/drivers'}
                            />
                            <h1 className={'mr-5 text-3xl ml-2'}>
                                {translate('page.heading.driver')}
                                <span
                                    className="text-tm-gray-500"> - {this.getID() ? `${infoData.FirstName ?? ''} ${infoData.LastName ?? ''}` : 'Create new'}
                                    {infoData.ArchivedDate && (
                                        <Tippy content={<span>Archived</span>}>
                                            <i className="iconsminds-delete-file"/>
                                        </Tippy>
                                    )}
                                </span>
                            </h1>
                                    {this.getID() ? (!isLoading ? (
                                        <DriverStatusBadge
                                            item={{
                                                StatusID: getProp(this.props.info, 'data.StatusID', ''),
                                                OnLoadID: getProp(this.props.info, 'data.OnLoadID', '')
                                            }}
                                            translate={translate}
                                            addClass={"h-8"}
                                        />
                                    ) : (
                                        <LoaderSmall disableContainer={true}/>
                        )) : null}
                        </div>

                        <div className="gap-2 flex flex-wrap items-center justify-end">
                            {checkPerm(Resources.Tasks, CREATE_PERM) && this.getID() && (
                                <div className="btn btn-outline mr-3 ml-2"
                                     onClick={() => this.handleCreateNewResourceTask()}>{translate('btn.new_driver_task')}
                                </div>
                            )}

                            {checkPerm(Resources.Tasks, READ_PERM) && this.getID() && (
                                <div className="relative">
                                    <button
                                        className="btn btn-outline"
                                        disabled={!checkPerm(Resources.TaskResource, READ_PERM)}
                                        onClick={() => this.handleToggleResourceTasks()}>

                                        {TaskCount > 0 && (
                                            <span
                                                className={
                                                    classNames(
                                                        "absolute z-10 flex -top-2.5 -left-2",
                                                        openTaskCountTwoDigits ? "h-6 w-6" : "h-5 w-5"
                                                    )
                                                }
                                            >
                                                          <div
                                                              className="absolute z-10 font-bold inset-0 flex items-center justify-center text-primary-contrast">{[TaskCount]}</div>
                                                          <span
                                                              className="animate-ping absolute z-10 inline-flex h-full w-full rounded-full bg-primary opacity-25"></span>
                                                          <span
                                                              className={
                                                                  classNames(
                                                                      "relative inline-flex rounded-full bg-primary",
                                                                      openTaskCountTwoDigits ? "h-6 w-6" : "h-5 w-5"
                                                                  )
                                                              }
                                                          />
                                                    </span>
                                        )}
                                        {translate('btn.show_tasks')}
                                        {this.props.taskResource.isCountLoading && `(${translate('text.Loading...')})`}
                                        {!this.props.taskResource.isCountLoading && TaskCount === 0 && `(${TaskCount})`}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    {!!this.state.tabs?.length && (
                        <>
                            <div className="mb-5 hidden sm:block">
                                <NavResponsive
                                    tabs={this.state.tabs}
                                    onTabChange={this.handleTabChange}
                                    translate={translate}
                                />
                            </div>

                            <div className="mb-5">
                                <MobileTabs
                                    tabs={this.state.tabs}
                                    onTabChange={this.handleTabChange}
                                    translate={translate}
                                />
                            </div>
                        </>
                    )}

                    <div className="row">
                        <div className="col">
                            {!!this.state.emailExist &&
                                <div className={'col-md-12 '}>
                                    <div className="alert alert-danger rounded display-space-between">
                                        {translate('EMAIL_PARAMETER_ALREADY_EXISTS')}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>


                    {this.state.selectedTab === Resources.DriverInfo && (
                        <InfoTab
                            toggleCreateNewContactBtnHandler={this.toggleCreateNewContactBtnHandler}
                            handleSetLocations={this.handleSetLocations}
                            acceptFilesInfo={this.acceptFilesInfo}
                            addPhoneField={this.addPhoneField}
                            removePhoneField={this.removePhoneField}
                            handleItemInputChange={this.handleItemInputChange}
                            returnDriverStatus={this.returnDriverStatus}
                            linkedContactInfo={this.state.linkedContactData}
                            returnLastDriverEmployment={this.returnLastDriverEmployment}
                            handleInputChange={this.handleInputChange}
                            sameAsFirstAddress={this.state.sameAsFirstAddress}
                            createNewContactBtn={this.state.createNewContactBtn}
                            fields_info={this.state.fields_info}
                            phones_info={this.state.phones_info}
                            files_info={this.state.files_info}
                            canSubmit={this.state.isDirty}
                            cancel={this.cancelInfoTabHandler}
                            submit={this.updateResource}
                            setActiveTab={this.handleTabChange}
                            setImage={(file) => {
                                this.fileImageObject = file
                                this.setState({isDirty: true})
                            }}
                            mainID={this.getID()} // Condition for submit (Create OR Update)
                            crop={this.state.crop}
                            croppedImageUrl={this.state.croppedImageUrl}
                            src={this.state.src}
                            OnLoadID={getProp(this.props.resource, 'data.OnLoadID', '')}
                            StatusID={getProp(this.props.resource, 'data.StatusID', '')}
                            HireNote={getProp(this.props.resource, 'data.HireNote', '')}
                            LastActive={getProp(this.props.resource, 'data.LastActive', '')}
                            IsSuspended={getProp(this.props.resource, 'data.IsSuspended', '')}
                            SuspendedNotes={getProp(this.props.resource, 'data.SuspendedNotes', '')}
                            onSuspendDriver={(params) => {
                                params.DriverID = this.getID()
                                this.props.dispatch(updateResource({
                                    user: LocalStorage.get('user'),
                                    params: params,
                                    resource: Resources.DriversSuspend,
                                    piggyResource: Resources.DriverInfo,
                                    query: {id: this.getID()},
                                    errorMessage: true,
                                    successMessage: "Driver " + (params.IsSuspended ? "suspended" : "actived") + " successfully!"
                                }))
                            }}
                            {...this.props}/>
                    )}

                    {this.state.selectedTab === Resources.DriversPayroll && this.getID() && (
                        <DriverPayrollTab
                            dispatch={this.props.dispatch}
                            DriverID={this.props.match.params.id}
                            resource={this.props.resource}
                            secondResource={this.props.secondResource}
                            thirdResource={this.props.thirdResource}
                            info={this.props.info}
                            translate={this.props.translate}
                            location={this.props.location}
                        />
                    )}

                    {this.state.selectedTab === Resources.DriverEndorsements && (
                        <DriverEndorsementsTab
                            removeNewEndorsement={this.removeNewEndorsement}
                            addNewEndorsement={this.addNewEndorsement}
                            fields_endorsement={this.state.fields_endorsement}
                            {...this.props}/>
                    )}
                    {this.state.selectedTab === Resources.DriverRestrictions && (
                        <DriverRestrictionsTab
                            removeNewRestriction={this.removeNewRestriction}
                            addNewRestriction={this.addNewRestriction}
                            fields_restrictions={this.state.fields_restrictions}
                            {...this.props}/>
                    )}

                    {this.state.selectedTab === Resources.DriversMobile && (
                        <DriverMobileTab
                            resourcePath={this.state.selectedTab}
                            DriverID={this.getID()}
                            translate={translate}
                        />
                    )}

                    {this.state.selectedTab === Resources.DriverPreferences && (
                        <DriverPreferencesTab
                            handleRemovePreference={this.handleRemovePreference}
                            handleAddNewPreference={this.handleAddNewPreference}
                            fields_willing={this.state.fields_willing}
                            {...this.props}/>
                    )}
                    {this.state.selectedTab === Resources.DriverReviews && (
                        <ReviewsTab {...this.props} id={this.getID()}
                                    resourceName={Resources.DriverReviews}/>
                    )}

                    {this.state.selectedTab === Resources.DriverNotes && (
                        <DriverNotesTab {...this.props} id={this.getID()}
                                        resourceName={Resources.DriverNotes} titleVisible={true}/>
                    )}

                    {this.state.selectedTab === Resources.DriverVacations && !!this.getID() && (
                        <DriverVacationsTab
                            addNewVacation={this.addNewVacation}
                            removeNewVacation={this.removeNewVacation}
                            fields_vacations={this.state.fields_vacations}
                            {...this.props}/>
                    )}
                    {this.state.selectedTab === Resources.DriverDocuments && !!this.getID() && (
                        <DocumentsUpdateTab
                            id={this.getID()}
                            dispatch={this.props.dispatch}
                            translate={this.props.translate}
                            resource={this.props.resource}
                            thirdResource={this.props.thirdResource}

                            dialogTitle={'Driver Documents'}

                            sortBy={'Description'}
                            primaryKey={'DocumentID'}

                            download={this.props.download}
                            resourceName={Resources.DriverDocuments}

                            documentUpdateFields={checkPerm(Resources.DriverDocuments, UPDATE_PERM) && {
                                DocumentTypeID: new Field("DocumentTypeID", '', ['empty'], false, 'select', {addContainerClass: 'col-span-full'}, {
                                    values: getDocumentTypesFor("IsPersonnelDocument")
                                }),
                                ExpiryDate: new Field("ExpiryDate", '', [], false, 'date', {addContainerClass: 'col-span-full'}, {isClearable: true}),
                                Description: new Field("Description", '', [], false, 'textarea', {addContainerClass: 'col-span-full'}),
                            }}

                            documentTypes={getDocumentTypesFor("IsPersonnelDocument")}
                            searchFields={{
                                query: new Field('query', '', [], false, 'text', {
                                    containerClass: 'col-md-4'
                                }),
                                DocumentTypeID: new Field('DocumentTypeID', '', [], false, 'select', {
                                    containerClass: 'col-md-4'
                                }, {
                                    all: true,
                                    values: getDocumentTypesFor("IsPersonnelDocument")
                                }),
                                DocumentExtension: new Field('DocumentExtension', '', [], false, 'select', {
                                    containerClass: 'col-md-4'
                                }, {
                                    all: true
                                }),
                            }}
                            readOnly={!checkPerm(Resources.DriverDocuments, CREATE_PERM)}
                            disableDelete={!checkPerm(Resources.DriverDocuments, DELETE_PERM)}
                        />
                    )}

                    {this.state.selectedTab === Resources.DriverDocuments && !this.getID() && (
                        <Card>
                            <DocumentsCreateTab
                                fields={this.state.fields_documents}
                                translate={this.props.translate}

                                dialogTitle={'Employee Documents'}

                                documentTypes={getDocumentTypesFor("IsPersonnelDocument")}
                                handleUploadDocument={this.handleUploadDocument}
                                handleDeleteDocument={this.handleDeleteDocument}
                            />
                        </Card>
                    )}

                    {this.state.selectedTab === Resources.DriverEmployments && this.getID() && (
                        <EmploymentTab
                            resourceName={Resources.DriverEmployments}
                            id={this.getID()}
                            primaryKey={'DriverEmploymentID'}
                            translate={translate}
                            StatusID={infoData.StatusID}
                            dispatch={this.props.dispatch}
                            resource={this.props.resource}
                            secondResource={this.props.secondResource}
                        />
                    )}

                    {this.state.selectedTab === Resources.DriverDispatch && this.getID() && (
                        <DriverDispatchTab
                            {...this.props}
                            id={this.getID()}
                            translate={translate}
                            resourceName={Resources.DriverDispatch}
                            resourceImagePath={Resources.ContactImage}
                            organizationID={this.getID()}
                            primaryKey={'ContactInDriverID'}
                            disableMemberOfOrganization
                            disableContactCreate
                            additionalQuery={{
                                IsEmployee: 1
                            }}
                            sortBy={'CreateUpdateDate'}
                            sort={'DESC'}

                            additionalFields={{
                                SetDate: new Field('SetDate', moment().format(DEFAULT_DATABASE_DATETIME_FORMAT), ['empty'], false, 'date', {addContainerClass: 'relative w-40'}),
                                Notes: new Field('Notes', '', []),
                            }}
                            fields={{
                                FirstName: new Field('FirstName', '', [], false, 'custom', {
                                    columnName: 'DispatcherName',
                                    render: (item) => item.FirstName + ' ' + item.LastName
                                }),
                                Email: new Field('Email', '', []),
                                SetDate: new Field('SetDate', '', [''], false, 'date'),
                                Notes: new Field('Notes', '', []),
                                IsPrimary: new Field('IsPrimary', '', [], false, 'checkbox'),
                            }}
                            selectableCollumn
                            dialogTableSelectFields={{
                                FirstName: new Field('FirstName', '', [], false, 'custom', {
                                    columnName: 'DispatcherName',
                                    render: (item) => item.FirstName + ' ' + item.LastName
                                }),
                                Email: new Field('Email', '', []),
                            }}
                        />
                    )}


                    {this.state.selectedTab === Resources.DriversLoads && this.getID() && (
                        <LoadsTab
                            tabResource={Resources.DriversLoads}
                            id={this.props.match.params.id}
                            tabKey="DriverID"
                            resource={this.props.resource}
                            dispatch={this.props.dispatch}
                            history={this.props.history}
                            translate={this.props.translate}
                        />
                    )}


                    {this.state.selectedTab === Resources.DriversClaims && this.getID() && (
                        <ResourceListTab
                            id={this.props.match.params.id}
                            tabKey={"DriversClaims"}
                            dispatch={this.props.dispatch}
                            translate={this.props.translate}
                            resource={this.props.resource}

                            sortBy={'CreateUpdateDate'}
                            primaryKey={'ClaimID'}
                            resourceName={Resources.DriversClaims}
                            fields={{
                                ClaimNumber: new Field('ClaimNumber', '', ['empty'], false, '', {
                                    onCellClick: (item) => item.ClaimID && this.handleLinkClick(`/claims/info/${item.ClaimID}`)
                                }),
                                ClaimDate: new Field('ClaimDate', '', ['empty'], false, 'date'),
                                ClaimClosed: new Field('ClaimClosed', '', [], false, 'checkbox'),
                                ClaimCode: new Field('ClaimCode', '', ['empty']),
                                ClaimOpened: new Field('ClaimOpened', '', ['empty'], false, 'date'),
                                SettledAmount: new Field('SettledAmount', '', ['empty'], false, 'money'),
                                Amount: new Field('Amount', '', ['empty'], false, 'money'),
                                ChargeDriver: new Field('ChargeDriver', '', ['empty'], false, 'checkbox'),
                                LoadNumber: new Field('LoadNumber', '', [''], false, '', {
                                    onCellClick: (item) => item.LoadID && this.handleLinkClick(`/loads/info/${item.LoadID}`)
                                }),
                                Driver: new Field('Driver', '', [''], false, '', {
                                    onCellClick: (item) => item.DriverID && this.handleLinkClick(`/drivers/info/${item.DriverID}`)
                                }),
                                Truck: new Field('Truck', '', [''], false, '', {
                                    onCellClick: (item) => item.TruckID && this.handleLinkClick(`/trucks/info/${item.TruckID}`)
                                }),
                                Trailer: new Field('Trailer', '', [''], false, '', {
                                    onCellClick: (item) => item.TrailerID && this.handleLinkClick(`/trailers/info/${item.TrailerID}`)
                                }),

                            }}
                            searchFields={{
                                query: new Field('query', '', [''], false, 'text', {
                                    containerClass: 'col-md-4'
                                }),
                                ClaimClosed: new Field('ClaimClosed', '45', [''], false, 'select', {
                                    containerClass: 'col-md-4'
                                }, {
                                    values: {
                                        0: 'Open',
                                        1: 'Closed',
                                        45: 'All',
                                    }
                                }),
                                limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {
                                    hideLabel: true,
                                    containerClass: 'col-md-4 limit-field-container float-right'
                                })
                            }}
                            disableEdit
                            disableDelete
                            disableCreate
                        />
                    )}

                    {this.state.selectedTab === Resources.DriversAccidents && this.getID() && (
                        <ResourceListTab
                            id={this.props.match.params.id}
                            tabKey={"DriversAccidents"}
                            dispatch={this.props.dispatch}
                            translate={this.props.translate}
                            resource={this.props.resource}

                            sortBy={'CreateUpdateDate'}
                            primaryKey={'AccidentID'}
                            resourceName={Resources.DriversAccidents}
                            fields={{
                                FileNumber: new Field('FileNumber', '', ['empty'], false, '', {
                                    onCellClick: (item) => item.AccidentID && this.handleLinkClick(`/accidents/info/${item.AccidentID}`)
                                }),
                                AccidentDate: new Field('AccidentDate', '', ['empty'], false, 'date'),
                                FileOpened: new Field('FileOpened', '', ['empty'], false, 'date'),
                                FileClosed: new Field('FileClosed', '', ['empty'], false, 'date'),
                                Amount: new Field('Amount', '', ['empty'], false, 'money'),
                                ChargeDriver: new Field('ChargeDriver', '', ['empty'], false, 'checkbox'),
                                Driver: new Field('Driver', '', [''], false, '', {
                                    onCellClick: (item) => item.DriverID && this.handleLinkClick(`/drivers/info/${item.DriverID}`)
                                }),
                                Truck: new Field('Truck', '', [''], false, '', {
                                    onCellClick: (item) => item.TruckID && this.handleLinkClick(`/trucks/info/${item.TruckID}`)
                                }),
                                Trailer: new Field('Trailer', '', [''], false, '', {
                                    onCellClick: (item) => item.TrailerID && this.handleLinkClick(`/trailers/info/${item.TrailerID}`)
                                }),
                            }}
                            searchFields={{
                                query: new Field('query', '', [''], false, 'text', {
                                    containerClass: 'col-md-4'
                                }),
                                limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {
                                    hideLabel: true,
                                    containerClass: 'col-md-4 limit-field-container float-right'
                                })
                            }}
                            disableEdit
                            disableDelete
                            disableCreate
                        />
                    )}

                    {this.state.selectedTab === Resources.DriverSettlements && this.getID() && (
                        <div className="grid grid-cols-12 gap-x-5 gap-y-10">
                            <div className="col-span-full 3xl:col-span-4">
                                <DriverSettlementTab
                                    fields={this.state.fields_settlements}
                                    handleInputChange={(fields) => this.setState({
                                        fields_settlements: fields,
                                        isDirty: true
                                    })}
                                    handleInputCreateChange={this.handleSettlementInputCreateChange}
                                    setIsStateDirty={(isDirty) => {
                                        this.setState({isDirty: isDirty})
                                    }}
                                    getData={(item) => this.setState({deductionData: item})}
                                    {...this.props}
                                />
                            </div>

                            <div className="col-span-full 3xl:col-span-8">
                                <DriverDeductionTab
                                    deductionData={this.state.deductionData}
                                    dispatch={this.props.dispatch}
                                    DriverID={this.props.match.params.id}
                                    secondResource={this.props.secondResource}
                                    onAddPayAdjustment={this.handleAddPayAdjustments}
                                    payAdjustments={this.state.payAdjustments}
                                    translate={this.props.translate}
                                />
                            </div>
                        </div>
                    )}

                    {this.state.selectedTab === Resources.DriversExpiry && (
                        <ExpiryItemsTab
                            translate={translate}
                            resourceName={Resources.DriversExpiry}
                            Reference={(infoData.FirstName ?? '') + ' ' + (infoData.LastName ?? '')}
                            ReferenceID={this.getID()}
                            ReferenceType={REFERENCE_TYPE_DRIVER}
                        />
                    )}


                    {!this.getID() && (
                        <PageFooter
                            translate={translate}
                            canSubmit={this.state.isDirty}
                            actionCancel={this.cancelInfoTabHandler}
                            actionSubmit={checkPerm(Resources.Drivers, CREATE_PERM) && this.submit}
                        />
                    )}
                </Page>

                {this.state.EFSIntegrationDialog && (
                    <EFSIntegrationDialog
                        show={!!this.state.EFSIntegrationDialog}
                        dispatch={this.props.dispatch}
                        resource={this.props.resource}
                        translate={translate}
                        integrationResource={this.props.integrationResource}
                        id={this.getID()}
                        resourceName={Resources.EFSIntegration}
                        hideResourceDialog={() => this.setState({EFSIntegrationDialog: false})}
                    />
                )}

                <RedirectDialog
                    show={this.state.isRedirectDialogVisible}
                    title={translate('card_header.driver_created')}
                    onClose={() => {
                        this.props.dispatch(resetResource());
                        window.location.reload();
                    }}

                    createdLabel={translate('btn.driver')}
                    createdLink={`/drivers/info/${this.state.createdResourceID}`}

                    newLabel={translate('btn.create_new')}

                    listLabel={translate('btn.list')}
                    listLink="/drivers"

                    dashboardLabel={translate('btn.dashboard')}

                    {...this.props}
                >
                    <p>{translate('message.driver_created')}</p>
                </RedirectDialog>

                <ModalDefault
                    show={!!this.state.isResourceTaskModalVisible}
                    title={translate('modal_heading.driver_tasks')}
                    widthClass={'max-w-3xl'}
                    closeButtonLabel={translate('btn.close')}
                    onClose={this.handleToggleResourceTasks}
                    translate={translate}
                >
                    <ResourceTasksDialog
                        close={this.hideConfirmCheckLoadTasksHandler}
                        {...this.props}
                        large={true}
                    />
                </ModalDefault>

                <ModalConfirm
                    type="default"
                    onClose={() => this.setState({confirmModal: false})}
                    onConfirm={this.state.confirmModal}
                    buttonLabel={translate("btn.proceed")}
                    closeButtonLabel={translate('btn.stay_on_current_tab')}
                    show={!!this.state.confirmModal}
                    title={this.props.translate("dialog_heading.unsaved_changes_on_tab")}
                    translate={this.props.translate}
                >
                    <p>{translate("text.unsaved_changes_on_tab")}</p>
                    <p>{translate("text.unsaved_changes_on_tab_line_2")}</p>
                </ModalConfirm>
            </Layout>
        )
    }
}

export default connect(state => state)(DriverCreateView)

