import {useEffect} from "react";
import moment from "moment";
import {DEFAULT_DATABASE_DATE_FORMAT, GROUP_BY_DISPATCH_GROUP_PLUS_DISPATCH} from "../../util/util-consts";

export default function CommandCenterTableStyles({
                                                     setTableStyles,
                                                     driverFields,
                                                     schedulerFields,
                                                     queryFields,
                                                     hasGroups,
                                                     tableOptions,
                                                     compressedView,
                                                     selectedDate,
                                                     constants = {}
                                                 }) {

    useEffect(() => {
        const todayDate = moment().format(DEFAULT_DATABASE_DATE_FORMAT);
        let hasSubgroups = false;
        if (queryFields.GroupBy) {
            hasSubgroups = queryFields?.GroupBy?.value === GROUP_BY_DISPATCH_GROUP_PLUS_DISPATCH;
        }
        let columnWidthClasses = '';
        let driverTableWidth = 0;
        let schedulerTableWidth = 0;
        let cumulativeLeft = hasSubgroups
            ? 300
            : hasGroups ? 150 : 0;
        const driverFieldsValues = Object.values(tableOptions.columns);
        let lastVisibleColumnIndex = -1;

        driverFieldsValues.forEach((it, i) => {
            if (!it.hidden && it.isSticky) {
                lastVisibleColumnIndex = i;
            }

            driverFieldsValues[i].isLast = false;
        });

        if (lastVisibleColumnIndex !== -1) {
            driverFieldsValues[lastVisibleColumnIndex].isLast = true;
        }

        const generatedDriverTableStyle = driverFieldsValues.reduce((memo, it, indx) => {
            const isFirst = !indx;
            const isLast = it.isLast;
            driverTableWidth = driverTableWidth + Number(it?.width);

            memo[`--col-${it.name}`] = it.width;
            columnWidthClasses = columnWidthClasses + `
            .col-${it.name} {
            width: calc(var(--col-${it.name}) * 1px);
            ${it.type === 'checkbox' ? "justify-content: center;" : ""}
            ${it.isSticky ? `position: sticky; left: ${cumulativeLeft + (cumulativeLeft ? "px" : '')}; z-index: 1; background: inherit;` : ''}
            ${isFirst ? "padding-left: 1.5rem;" : "padding-left: 0.25rem;"}
            ${isLast ? 'border-right-style: solid; border-right-width: 4px!important;' : ''}
        }
    `
            if (it.isSticky) {
                cumulativeLeft = cumulativeLeft + Number(it.width);
            }
            return memo;
        }, {});

        const generatedScheduleTableStyle = Object.values(schedulerFields).reduce((memo, it) => {
            schedulerTableWidth = schedulerTableWidth + Number(it?.width);
            memo[`--col-${it.name}`] = it?.width;
            columnWidthClasses = columnWidthClasses + `
            .col-${it.name} {
            width: ${compressedView ? 140 : 200}px;
            flex-shrink: 0;
            justify-content: center;
            position: relative;
            ${todayDate === it.name ? "background-color: var(--color-primary-transparent);" : ""}
        }
    `
            return memo;
        }, {});


        setTableStyles(Object.assign({}, generatedDriverTableStyle, generatedScheduleTableStyle));

        const style = document.createElement("style");
        document.head.appendChild(style);
        style.innerHTML = columnWidthClasses + `
           .cc-cell {
           border-color: var(--color-gray-300);
         ${tableOptions.style.showHorizontalLines ? "border-bottom-width: 1px;" : ""}
         ${tableOptions.style.showVerticalLines ? "border-right-width: 1px;" : ""}
        min-height: 30px;
        display: flex;
        align-items: center;
          font-size: 0.8rem;
    }
    
    .cc-header {
        border-color: var(--color-gray-300);
        background-color: var(--color-gray-50);
        ${constants?.ccHeaderHeight ? constants?.ccHeaderHeight + ';' : 'height: 2.5rem;'}
        border-bottom-width: 1px;
         ${tableOptions.style.showVerticalLines ? "border-right-width: 1px;" : ""}
        padding-right: 0.25rem;
        font-size: 0.8rem!important;
        font-weight: normal;
          line-height: 1.25rem!important;
         display: flex;
        align-items: center!important;
    }
        .col-Dispatcher {
        display: flex;
        align-items: center;
        padding-left: 1.5rem;
       font-size: 0.8rem;
        line-height: 1.75rem
    }
    
    .cc-header.col-${todayDate} {
       color: var(--color-primary);
       font-weight: 700;
    }
       
    .col-group,
    .col-DispatchContactGroup,
     .col-PrimaryDispatch,
      .col-ContactGroup {
    width: 150px; 
    max-width: 150px;
    flex-grow: 1; 
    display: flex; 
    align-items: center;
     padding-left: 1rem;
     border-right-style: solid; 
     border-right-width: 1px;
     position: sticky;
     left: 0;
     z-index: 10;
    }
    
    .col-PrimaryDispatch {
        left: ${hasSubgroups ? '150px' : '0px'}
    }
    
    .col-Driver {
        left: ${
            hasSubgroups 
                ? '300px' 
                : hasGroups ? '150px' : '0px'
        }
     }
    
    `;

        return () => {
            style.remove();
        }
    }, [driverFields, tableOptions, hasGroups, queryFields, selectedDate]);
    return null;
}