import React, {Component} from 'react'
import LocalStorage from '../../../util/localStorage'
import {Field, FieldsManager} from '../../../data/services/fields'
import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DEFAULT_QUERY_LIMIT,
    DELETE_PERM,
    UPDATE_PERM
} from '../../../common/util/util-consts'
import {checkPerm, classNames, getProp, longTableColumn} from '../../../common/util/util-helpers'
import {currentDate} from "../../../common/util/util-dates";
import {cloneDeep} from "../../../common/util/util-vanilla";
import {
    createSecondResource,
    deleteSecondResource,
    getSecondResource,
    updateSecondResource
} from "../../../data/actions/secondResource";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import ResourceTable from "../../../common/components/resource-table";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import ModalConfirm from "../../../common/components/modal/modal-confirm";

export default class DriverNotesTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // Query
            ...DEFAULT_CRUD_STATE,
            sort: "DESC",
            sortBy: "Date",
            // Fields
            fields: this.getFields(),
            queryFilterFields: this.getQueryFilterFields(),
            canSubmit: false,

            // Modals
            createModalOpen: false,
            editModalOpen: false,
            confirmModalOpen: false,
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount = () => {
        this.fetchData()
    }

    /** Data events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery(),
        }))
    }


    /** UI events
     ================================================================= */
    handleToggleConfirmDialog = (item) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.fetchData()
        })
    }

    handleToggleCreateModal = () => {
        this.setState({
            rating: 0,
            createModalOpen: !this.state.createModalOpen,
            canSubmit: false
        })
    }

    handleToggleEditModel = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen,
            canSubmit: false
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.fetchData()
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.fetchData(true)
        })
    }
    /** Fields/Data definitions
     ================================================================= */
    getFields = (item = null) => {
        const fieldTemplates = {
            Date: new Field('Date', currentDate(), ['empty'], false, 'date', {addContainerClass: "col-span-full"}),
            DriverNotesTypeID: new Field('DriverNotesTypeID', '', [''], false, 'select', {addContainerClass: "col-span-full"}),
            Notes: new Field('Notes', '', ['empty'], false, 'textarea', {
                addContainerClass: 'col-span-full',
                render: (item) => (
                    <div>{longTableColumn(item.Notes)}</div>
                )
            }),
            UpdatedByContactID: new Field('UpdatedByContactID', '', [], false, 'select-search', {hideDialog: true}),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            DriverNotesTypeID: new Field('DriverNotesTypeID', '', [''], false, 'select'),
            StartDate: new Field('StartDate', '', [''], false, 'date', {}, {}),
            EndDate: new Field('EndDate', '', [''], false, 'date', {}, {}),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {menuPlacement: 'top'})
        }
    }

    /** Query definitions
     ================================================================= */
    getQuery = () => {
        const {
            query,
            StartDate,
            EndDate,
            DriverNotesTypeID
        } = FieldsManager.getFieldKeyValues(this.state.queryFilterFields)
        return {
            id: this.props.id,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            limit: this.state.limit,
            offset: this.state.offset,
            query: query,
            searchFields: JSON.stringify({
                ...(!!StartDate && {StartDate: ['Date', '>=', StartDate]}),
                ...(!!EndDate && {EndDate: ['Date', '<=', EndDate]}),
                DriverNotesTypeID: DriverNotesTypeID
            })
        }
    }

    /** Helpers
     ================================================================= */
    getResource = () => {
        return this.props.resourceName
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, secondResource} = this.props

        const data = getProp(secondResource.data, 'list', [])
        const count = getProp(secondResource.data, 'count', 0)

        return (
            <React.Fragment>
                <PageHeader
                    title={translate('page.heading.Notes')}
                    titleClass={
                        classNames(
                            "mr-5 text-2xl m-4",
                            this.props.titleVisible ? "visible" : "invisible",
                        )
                    }
                    buttonLabel={translate('btn.create_new')}
                    buttonsClassName={"ml-auto space-x-3 flex m-4"}
                    onButtonClick={() => this.handleToggleCreateModal()}
                    hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                />

                <div className="flex mb-4">
                    <ActiveFilters
                        filterFields={this.state.queryFilterFields}
                        onLabelClick={this.handleFilterInputChange}
                        onClearFiltersClick={this.handleClearFiltersClick}
                        translate={translate}
                    />
                </div>

                <TableCard>
                    <TableFilters
                        hideLimit
                        filterFields={this.state.queryFilterFields}
                        handleInputChange={this.handleFilterInputChange}
                        translate={translate}
                        onRefreshTable={this.fetchData}
                    />

                    <ResourceTable
                        addClass="rounded-card"

                        data={data}
                        count={count}

                        fields={this.state.fields}
                        translate={this.props.translate}
                        isLoading={secondResource.isLoading}

                        limit={this.state.searchFields?.limit?.value ?? this.state.limit}
                        offset={this.state.offset}
                        page={this.state.paginationPage}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onOffsetChange={this.handleUpdateOffset}
                        onSortChange={this.handleUpdateSort}

                        onRowClick={checkPerm(this.getResource(), UPDATE_PERM) ? this.handleToggleEditModel : null}

                        onDelete={this.handleToggleConfirmDialog}
                        onEdit={this.handleToggleEditModel}

                        hasDeletePerm={checkPerm(this.getResource(), DELETE_PERM)}
                        hasEditPerm={checkPerm(this.getResource(), UPDATE_PERM)}
                    />

                    <NoRecordsTable
                        show={(data.length === 0) && !secondResource.isLoading}
                        canCreate={false}
                        title={translate('text.no_matching_records')}
                        className={"pb-12 pt-16 px-6"}
                    />
                </TableCard>

                <ModalSaveResource
                    title={'Edit notes'}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    show={this.state.editModalOpen}
                    onClose={this.handleToggleEditModel}
                    fields={this.getFields(this.state.selectedItem)}
                    onSubmit={(params) => {
                        if (params) {
                            delete params.UpdatedByContactID
                            params.DriverID = this.props.id
                            this.props.dispatch(updateSecondResource({
                                user: LocalStorage.get('user'),
                                params: Object.assign({}, params, {
                                    DriverNotesID: this.state.selectedItem.DriverNotesID
                                }),
                                query: this.getQuery(),
                                resource: this.getResource(),
                                piggyResource: this.getResource(),
                                errorMessage: true, successMessage: translate('text.driver_note_updated'),
                            }))
                            this.handleToggleEditModel()
                        }
                    }}
                    translate={this.props.translate}
                    canSubmit={this.state.canSubmit}
                    handleInputChange={(fields, name, value) => {
                        this.setState({canSubmit: true})
                        return FieldsManager.updateField(fields, name, value)
                    }}
                />

                <ModalSaveResource
                    show={this.state.createModalOpen}
                    title={'Create note'}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    onClose={this.handleToggleCreateModal}
                    fields={this.getFields()}
                    onSubmit={(params) => {
                        if (params) {
                            delete params.UpdatedByContactID
                            params.DriverID = this.props.id

                            this.props.dispatch(createSecondResource({
                                user: LocalStorage.get('user'),
                                params: params,
                                query: this.getQuery(),
                                resource: this.getResource(),
                                piggyResource: this.getResource(),
                                errorMessage: true, successMessage: translate('text.driver_note_created'),
                            }))
                            this.handleToggleCreateModal()
                        }
                    }}
                    translate={this.props.translate}
                    canSubmit={this.state.canSubmit}
                    handleInputChange={(fields, name, value) => {
                        this.setState({canSubmit: true})
                        return FieldsManager.updateField(fields, name, value)
                    }}
                />

                <ModalConfirm
                    title={'Confirm'}
                    show={!!this.state.confirmModalOpen}
                    text={translate('text.confirm_delete_note') + '?'}
                    onClose={this.handleToggleConfirmDialog}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={() => {
                        this.props.dispatch(deleteSecondResource({
                            user: LocalStorage.get('user'),
                            query: Object.assign({}, {DriverNotesID: this.state.selectedItem.DriverNotesID}, this.getQuery()),
                            resource: this.getResource(),
                            secondPiggyResource: this.getResource(),
                            secondPiggyQuery: this.getQuery(),
                            errorMessage: true, successMessage: translate("text.note_deleted")
                        }))
                        this.handleToggleConfirmDialog({})
                    }}
                />
            </React.Fragment>
        )
    }
}
