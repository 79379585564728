import React from 'react';
import {
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    JSONParseFix,
    saveTableColumns
} from '../../../common/util/util-helpers';
import Resources from "../../../data/services/resources";
import {Field, FieldsManager} from "../../../data/services/fields";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import {
    DEFAULT_CRUD_STATE,
    DEFAULT_QUERY_LIMIT,
    DELETE_PERM,
    EXPIRY_FLEET_ITEM_TYPES,
    REFERENCE_TYPE_TRUCK,
    REFERENCE_TYPES_SYSTEM_LINKS,
    UPDATE_PERM
} from "../../../util/util-constants";
import {useEffect, useState} from "react";
import LocalStorage from "../../../util/localStorage";
import {checkPerm, getProp, openInNewTab} from "../../../common/util/util-helpers";
import {deleteResource, getResource} from "../../../data/actions/resource";
import moment from "moment";
import Badge from "../../../common/components/badge";
import {toFrontDate} from "../../../common/util/util-dates";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import PopoverDatePicker from "../../../common/components/popover/popover-datepicker";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import ResourceTable from "../../../common/components/resource-table";
import {EyeIcon, TrashIcon} from "@heroicons/react/24/outline";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import {cloneDeep} from "../../../common/util/util-vanilla";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import PayDateFilter from "../../accounting/carrier-pay/carrier-pay-date-filter";


const ExpiryItemsTab = ({translate, dispatch, resourceName, match, resource, breakpoint}) => {

    /** Constants
     ================================================================= */
    const pagePath = match.path.substring(1);

    const tableDefaults = {
        behaviour: {
            rowSelect: true,
            canAdjustWidth: true,
            hasMenu: true
        },
        style: {
            condensed: true,
            isGPUAccelerated: true
        }
    }

    const [isColumnsDialogVisible, setIsColumnsDialogVisible] = useState(false)

    function setTableColumnOptions(columns) {
        let tableOptionsUpdate = cloneDeep(tableOptions)

        tableOptionsUpdate.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        setTableOptions(tableOptionsUpdate)
        setIsColumnsDialogVisible(false)
        saveTableColumns(pagePath, tableOptionsUpdate)
    }

    const [tableOptions, setTableOptions] =  useState(() => getDefaultTableOptions(getFields(), tableDefaults, pagePath, translate));

    const getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(fields, tableDefaults, translate)
    }

    /** Fields/Data definitions
     ================================================================= */
    function getFields(item) {
        let fields = {};
        switch (resourceName) {
            case Resources.DispatchExpiry:
            case Resources.FleetExpiry:
            case Resources.PersonnelExpiry:
            case Resources.CompaniesExpiry:
                fields = {
                    Reference: new Field('Reference', '', [], false, 'text', {
                        label: "REFERENCE_TYPE",
                        render: (item) => (item.ReferenceType ? translate('reference_type.' + item.ReferenceType) : "") + ' ' + item.Reference,
                        omitSort: true
                    }),
                    Description: new Field('Description', '', [], false, 'text', {
                        omitSort: true
                    }),
                    Date: new Field('Date', '', [], false, 'text', {
                        label: "ExpiryDate",
                        render: (item) => renderDueDate(item),
                        omitSort: true
                    }),
                }
                break;
            default:
                fields = {}
                break;
        }

        return fillFieldsFromData(fields, item);
    }

    const resourceIsFleetExpiry = () => {
        return resourceName == Resources.FleetExpiry
    }

    const getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            StartDate: new Field('StartDate', '', [], false, 'date', {labelType: "stack"}),
            EndDate: new Field('EndDate', '', [], false, 'date', {labelType: "stack"}),
            Type: new Field('Type', '', [''], false, resourceIsFleetExpiry() ? 'select' : 'hidden', {
                label: 'ExpiryItemType',
                labelType: 'stack'
            }, {
                values: EXPIRY_FLEET_ITEM_TYPES,
                isClearable: true
            }),
            Overdue: new Field('Overdue', '', [''], false, 'checkbox', {}, {}),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {menuPlacement: "top"})
        }
    }

    /** State
     ================================================================= */
    const [fields, setFields] = useState(getFields())
    const [query, setQuery] = useState(DEFAULT_CRUD_STATE)
    const [queryFilterFields, setQueryFilterFields] = useState(LocalStorage.has(pagePath + '_state') ? JSONParseFix(LocalStorage.get(pagePath + '_state'))?.queryFilterFields : getQueryFilterFields())
    const [selectedItem, setSelectedItem] = useState(null)
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)

    /** Helpers
     ================================================================= */

    const getResourceName = () => {
        return resourceName;
    }

    const getQuery = () => {
        return {
            offset: query.offset,
            sort: query.sort,
            sortBy: query.sortBy,
            ...FieldsManager.getFieldKeyValues(queryFilterFields),
        }
    }

    const saveFilters = () => {
        LocalStorage.persistState(pagePath, {
            query,
            queryFilterFields
        }, Object.keys(getQuery()))
    }

    const getResourceTab = (it) => {
        if (it.ReferenceType === REFERENCE_TYPE_TRUCK) {
            switch (it.Tab) {
                case "documents":
                    return Resources.TruckDocuments
                case "permits":
                    return Resources.TrucksPermits
                case "expiry":
                    return Resources.TrucksExpiry
                case "info":
                    return Resources.TrucksInfo
            }
        } else {
            switch (it.Tab) {
                case "documents":
                    return Resources.TrailerDocuments
                case "expiry":
                    return Resources.TrailersExpiry
                case "info":
                    return Resources.Trailer
            }
        }
    }

    const checkPermForItem = (it) => {
        let resource;

        switch (it.ReferenceType) {
            case "tbl_Truck": {
                resource = getResourceTab(it)
                break;
            }
            case "tbl_Trailer": {
                resource = getResourceTab(it)
                break;
            }
            case "tbl_Load": {
                resource = Resources.LoadInfo
                break;
            }
            case "tbl_Customer": {
                resource = Resources.CustomersInfo
                break;
            }
            case "tbl_Carrier": {
                resource = Resources.Carrier
                break;
            }
            case "tbl_Vendor": {
                resource = Resources.VendorsInfo
                break;
            }
            case "tbl_Driver": {
                resource = Resources.DriverInfo
                break;
            }

            default: {
                return false
            }
        }

        return checkPerm(resource, UPDATE_PERM)
    }
    /** Data events
     ================================================================= */
    const fetchData = () => {
        dispatch(getResource({
            user: LocalStorage.get('user'),
            query: getQuery(),
            resource: getResourceName()
        }))
    }

    /** UI events
     ================================================================= */
    const handleFilterInputChange = (name, value) => {
        setQueryFilterFields(FieldsManager.updateField(queryFilterFields, name, value))
    }

    const handleUpdateQueryFields = (fields) => {
        setQueryFilterFields(fields);
    }

    const handleToggleViewModal = (item = null) => {
        const path = REFERENCE_TYPES_SYSTEM_LINKS[item.ReferenceType]

        switch (item.ReferenceType) {
            case "tbl_Truck":
            case "tbl_Trailer": {
                const targetTabURLParam = item.ReferenceType == REFERENCE_TYPE_TRUCK ? `?tab=trucks/${item.Tab}` : `?tab=trailers/${item.Tab}`
                openInNewTab(path + item.ReferenceID + targetTabURLParam)
                break;
            }
            default: {
                openInNewTab(path + item.ReferenceID)
            }
        }
    }

    const handleClearFiltersClick = () => {
        setQueryFilterFields(getQueryFilterFields())
    }

    const handleUpdateOffset = (offset, num) => {
        setQuery((prevState) => ({
            ...prevState,
            offset: offset,
            paginationPage: num
        }))
    }

    const handleUpdateSort = (sortBy) => {
        setQuery((prevState) => ({
            ...prevState,
            sortBy: sortBy,
            sort: (query.sortBy === sortBy) ? (query.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }))
    }

    const handleToggleConfirmModal = (item = null) => {
        setIsConfirmModalOpen(!isConfirmModalOpen)
        setSelectedItem(item)
    }

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        fetchData()
        saveFilters()
    }, [query, queryFilterFields]);

    useEffect(() => {
        if (breakpoint && breakpoint.index <= 1 && getProp(queryFilterFields, "limit.value", 10) !== 10) {
            handleFilterInputChange('limit', 10);
        }
    }, [breakpoint])

    /** Render
     ================================================================= */
    const renderDueDate = (item) => {
        if (!item.Date) {
            return undefined;
        }

        let ExpiryDate = item.Date
        let today = moment().format("YYYY-MM-DD HH:mm:ss")
        let x = 7
        let xDaysBefore = moment(ExpiryDate).add(-x, 'days').format("YYYY-MM-DD HH:mm:ss")
        let styles = ''
        if (ExpiryDate) {
            if (xDaysBefore < today && today < ExpiryDate) {
                styles = 'warning'
            }
            if (today > ExpiryDate) {
                styles = 'danger'
            }
        }
        return (
            <>
                <Badge type={styles}>
                    {toFrontDate(ExpiryDate)}
                </Badge>
            </>
        )
    }

    const data = getProp(resource, 'data.list', [])
    const count = getProp(resource, 'data.count', 0)
    const isLoading = getProp(resource, 'isLoading', false)

    return (
        <>
            <div className="flex items-center justify-between">
                <ActiveFilters
                    filterFields={queryFilterFields}
                    onLabelClick={handleFilterInputChange}
                    onClearFiltersClick={handleClearFiltersClick}
                    translate={translate}
                />

                <div className={"ml-auto"}>
                    <TableSettingsPopOver
                        options={tableOptions}
                        setOptions={setTableOptions}
                        toggleColumnSettingsDialog={() => setIsColumnsDialogVisible(true)}
                        translate={translate}
                    />
                </div>
            </div>
            <TableCard>
                <TableFilters
                    popoverClass="relative"
                    hideLimit
                    filterFields={queryFilterFields}
                    handleInputChange={handleFilterInputChange}
                    customHeaderHtml={
                        <PopoverDatePicker
                            queryFields={queryFilterFields}
                            onQueryInputChange={handleFilterInputChange}
                            updateFields={handleUpdateQueryFields}
                            translate={translate}
                        />
                    }
                    translate={translate}
                    onRefreshTable={fetchData}
                />

                <ResourceTable
                    data={data}
                    options={tableOptions}
                    tableKey="ReferenceID"
                    //onSelectRow={handleSelectRowClick}
                    //selectedRows={selectedRows}
                    //onSelectAllClick={handleSelectAllClick}
                    //options={tablePageDefaults}

                    verticalTableIsVisible={breakpoint.index <= 1}
                    fields={fields}
                    translate={translate}
                    isLoading={isLoading}

                    limit={queryFilterFields?.limit?.value ?? query.limit}
                    offset={query.offset}
                    page={query.paginationPage}
                    onOffsetChange={handleUpdateOffset}
                    sort={query.sort}
                    sortBy={query.sortBy}
                    onSortChange={handleUpdateSort}

                    onRowClick={(it) => checkPermForItem(it) ? handleToggleViewModal(it) : null}

                    actions={[
                        {
                            action: (it) => handleToggleViewModal(it),
                            icon: () => EyeIcon,
                            hasPerm: (it) => checkPermForItem(it),
                            title: translate('btn.open_in_new_tab')
                        },
                        {
                            action: (it) => handleToggleConfirmModal(it),
                            icon: () => TrashIcon,
                            visible: (it) => ((it.LoadStopByID || it.LoadStopID) && resourceName === Resources.DispatchExpiry),
                            hasPerm: () => checkPerm(Resources.DispatchExpiry, DELETE_PERM),
                            title: translate('btn.mark_item_as_retrieved')
                        }
                    ]}
                />

                <NoRecordsTable
                    show={(data.length === 0) && !isLoading}
                    canCreate={false}
                    title={translate('text.no_matching_records')}
                    className={"pt-16 pb-14 px-6"}
                />

                {!!data.length && !isLoading && (
                    <TableCardFooter>
                        <Pagination
                            count={count}
                            isLoading={isLoading}
                            hideRowsPerPage={breakpoint.index <= 1}
                            handleQueryChange={
                                (name, value, currentPage) => name === "offset"
                                    ? handleUpdateOffset(value, currentPage)
                                    : handleFilterInputChange(name, value)
                            }
                            pageOffset={query.offset}
                            queryFields={queryFilterFields}
                            translate={translate}
                        />
                    </TableCardFooter>
                )}

            </TableCard>

            <ModalConfirm
                title={translate("text.confirm")}
                show={!!isConfirmModalOpen}
                text={translate('message.are_you_sure_mark_as_retrieved')}
                onClose={handleToggleConfirmModal}
                buttonLabel={translate('btn.confirm')}
                closeButtonLabel={translate('text.cancel')}
                translate={translate}
                onConfirm={() => {
                    dispatch(deleteResource({
                        user: LocalStorage.get('user'),
                        query: {
                            LoadStopByID: selectedItem.LoadStopByID ?? null,
                            LoadStopID: selectedItem.LoadStopID ?? null
                        },
                        piggyQuery: getQuery(),
                        errorMessage: true,
                        successMessage: translate('message.item_marked_as_retrieved'),
                        resource: Resources.DispatchExpiry,
                        piggyResource: Resources.DispatchExpiry,
                    }))
                    handleToggleConfirmModal()
                }}
            />

            <TableOptionsDialog
                show={isColumnsDialogVisible}
                pagePath={pagePath}
                columns={tableOptions.columns}
                setTableColumnOptions={setTableColumnOptions}
                getDefaultTableColumnOptions={getDefaultTableColumnOptions}
                onClose={() => setIsColumnsDialogVisible(false)}
                translate={translate}
            />
        </>
    )
}

export default ExpiryItemsTab;
