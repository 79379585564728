import React, {Component} from 'react'
import Resources from '../../../data/services/resources'
import LocalStorage from '../../../util/localStorage'
import {
    checkPerm,
    classNames,
    getDefaultTableOptions,
    getProp,
    renderInvoiceStatusBadge,
    resourceIsLoaded
} from '../../../common/util/util-helpers'
import {getResource, updateResource} from '../../../data/actions/resource'
import {
    DEFAULT_CRUD_STATE,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    DELETE_PERM,
    READ_PERM,
    UPDATE_PERM
} from '../../../util/util-constants'
import {Field, FieldsManager} from '../../../data/services/fields'
import Env from '../../../util/env'
import {ArrowDownTrayIcon, EyeIcon, TrashIcon} from '@heroicons/react/24/outline'
import {getSecondResource} from '../../../data/actions/secondResource'
import {DriverPayrollOpenPayEntries, DriverPayrollSummary} from './driver-payroll-section'
import ChevronRightIcon from '@heroicons/react/24/outline/ChevronRightIcon'
import {cloneDeep, genericMoneyFormatter} from '../../../common/util/util-vanilla'
import Tippy from '@tippyjs/react'
import {download} from '../../../data/actions/download'
import {currentDate} from '../../../common/util/util-dates'
import {getInfoResource} from '../../../data/actions/infoResource'
import {createThirdResource, deleteThirdResource, getThirdResource} from '../../../data/actions/thirdResource'
import {numberWithCommasToBack} from '../../../util/util-formaters'
import ExclamationTriangleIcon from '@heroicons/react/24/outline/ExclamationTriangleIcon'
import {fieldsToHtml, fillFieldsFromData, includeFields} from "../../../common/util/util-fields";
import {LoaderSmall} from "../../../common/components/loader";
import Subtitle from "../../../common/components/layout/layout-components/page/subtitle";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import ResourceTable from "../../../common/components/resource-table";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import TableBulkActions from "../../../common/components/resource-table/table-components/table-bulk-actions";
import PopoverDatePicker from "../../../common/components/popover/popover-datepicker";
import ModalDefault from "../../../common/components/modal/modal-default";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import InfoParagraph from "../../../common/components/info-paragraph";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import InvoiceExpenseDetailsDialog from "../../../common/components/modal/invoice-expense-details-dialog";
import FileViewer from "../../../common/components/file-viewer/components";
import {getJWT} from "../../../common/util/util-auth";
import DriverStatusBadge from "../../../common/components/badge/driver-status-badge";
import {DEFAULT_QUERY_LIMIT} from "../../../common/util/util-consts";
import DriverPayrollStatementsSearch from "./driver-payroll-statements-search";

export default class DriverPayrollTab extends Component {

    constructor(props) {
        super(props)

        this.pagePath = this.props.location.pathname.substring(1) + '_payroll'

        this.tablePageDefaults = {
            behaviour: {
                rowSelect: true,
            }
        }

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sort: 'DESC',
            sortBy: 'Date',
            sortInvoice: 'DESC',
            sortByInvoice: 'Date',
            offsetInvoices: 0,
            fields: this.getFields(),
            queryFilterFields: this.getQueryFilterFields(),
            queryFilterFieldsInvoices: this.getQueryFilterFieldsInvoices(),
            terminationInvoiceFields: this.getCreateTerminationFields(),
            terminationInvoiceItems: [],
            isOpenPayEntriesSectionExpanded: true,
            isSummarySectionExpanded: true,
            isPayrollSectionExpanded: true,
            isFuelCardsSectionExpanded: true,
            isTerminationInvoices: true,
            selectedItem: null,
            selectedRows: {},
            isBulkPreviewModalOpen: false,
            openPayEntryData: [],
            terminateConfirmDialogOpen: false,
            undoTerminateConfirmDialogOpen: false,
            isContactAdvancesDialogOpen: false,
            isPayrollViewDialogOpen: false,
            isPayrollEditDialogOpen: false,
            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate),
            createTerminationModal: false,
            confirmModalOpen: false,
            selectedInvoiceItem: null
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
        this.fetchSummaryData()
        this.fetchInvoiceData()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (resourceIsLoaded(this.props.secondResource, prevProps.secondResource)) {
            const openPayEntryData = [
                {
                    Item: 'UnpaidItemsTotal',
                    Name: this.props.translate('field.UnpaidItemsTotal'),
                    TotalAmount: getProp(this.props.secondResource, 'data.UnpaidItemsTotal', 0)
                },
                {
                    Item: 'EscrowBalancesTotal',
                    Name: this.props.translate('field.EscrowBalancesTotal'),
                    TotalAmount: getProp(this.props.secondResource, 'data.EscrowBalancesTotal', 0)
                },
                {
                    Item: 'LoanBalancesTotal',
                    Name: this.props.translate('field.LoanBalancesTotal'),
                    TotalAmount: getProp(this.props.secondResource, 'data.LoanBalancesTotal', 0)
                },
            ]

            this.setState({
                openPayEntryData: openPayEntryData
            })
        }

        if (!this.props.resource.isLoading && this.state.terminateConfirmDialogOpen && this.props.resource.isLoading !== prevProps.resource.isLoading) {
            this.toggleTerminateConfirmDialog()
            this.fetchInfoData()
        }
        if (!this.props.resource.isLoading && this.state.undoTerminateConfirmDialogOpen && this.props.resource.isLoading !== prevProps.resource.isLoading) {
            this.toggleUndoTerminateConfirmDialog()
            this.fetchInfoData()
        }
        if (!this.props.thirdResource.isLoading && this.props.thirdResource.isLoading !== prevProps.thirdResource.isLoading) {
            this.fetchSummaryData()
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    }

    fetchSummaryData = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            query: this.getQuery(),
            resource: Resources.PayrollSummary
        }))
    }

    fetchInfoData = () => { // global info data
        this.props.dispatch(getInfoResource({
            user: LocalStorage.get('user'),
            resource: Resources.DriverInfo,
            query: {id: this.getID()}
        }))
    }

    fetchInvoiceData = () => { // Termination invoice data
        this.props.dispatch(getThirdResource({
            user: LocalStorage.get('user'),
            resource: Resources.DriversTerminateInvoice,
            query: this.getQueryInvoices()
        }))
    }

    /** UI Events
     ================================================================= */
    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, this.fetchData)
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => this.fetchData())
    }

    handleUpdateSortInvoice = (sortBy) => {
        this.setState({
            sortByInvoice: sortBy,
            sortInvoice: (this.state.sortByInvoice === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => this.fetchInvoiceData())
    }

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData())
    }

    handleFilterInputChangeInvoices = (name, value) => {
        this.setState({
            queryFilterFieldsInvoices: FieldsManager.updateField(this.state.queryFilterFieldsInvoices, name, value),
            offsetInvoices: 0,
            paginationPage: 1
        }, () => {
            this.fetchInvoiceData()
        })
    }

    handleUpdateOffsetInvoices = (offset, num) => {
        this.setState({
            offsetInvoices: offset,
            paginationPage: num
        }, () => {
            this.fetchInvoiceData()
        })
    }

    handleUpdateQueryFields = (fields) => {
        this.setState({
            queryFilterFields: fields
        }, () => this.fetchData())
    }

    handleSelectRowClick = (item) => {
        const itemID = item.PayrollBatchEntryID

        let selectedRows = cloneDeep(this.state.selectedRows)

        if (!!selectedRows[itemID]) {
            delete selectedRows[itemID]
        } else {
            Object.assign(selectedRows, {[itemID]: item})
        }

        this.setState({selectedRows})
    }

    handleSelectAllClick = (selectAll) => {
        const data = cloneDeep(getProp(this.props.resource.data, 'list', []))

        let selectedRows = cloneDeep(this.state.selectedRows)

        if (!selectAll) {
            Object.assign(selectedRows, data.reduce((memo, it) => {
                memo[it.PayrollBatchEntryID] = it
                return memo
            }, {}))
        } else {
            let selectedRowsKeys = Object.keys(selectedRows)
            data.forEach(it => {
                if (selectedRowsKeys.includes(it.PayrollBatchEntryID.toString())) {
                    delete selectedRows[it.PayrollBatchEntryID]
                }
            })
        }

        this.setState({selectedRows})
    }

    toggleTerminateConfirmDialog = () => {
        this.setState({
            terminateConfirmDialogOpen: !this.state.terminateConfirmDialogOpen
        })
    }

    toggleUndoTerminateConfirmDialog = () => {
        this.setState({
            undoTerminateConfirmDialogOpen: !this.state.undoTerminateConfirmDialogOpen
        })
    }

    handleToggleContactAdvancesDialog = () => {
        this.setState({
            isContactAdvancesDialogOpen: !this.state.isContactAdvancesDialogOpen
        })
    }

    setSelectedRows = (selectedRows) => {
        this.setState({selectedRows})
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedInvoiceItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleToggleInvoiceDetailsDialog = (item = null) => {
        this.setState({
            selectedInvoiceItem: item,
            invoiceDetails: !this.state.invoiceDetails
        })
    }

    handleAddTerminationInvoiceItem = () => {
        let terminationInvoiceItems = this.state.terminationInvoiceItems

        terminationInvoiceItems.push({
            AccountID: new Field('AccountID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-3'}),
            Amount: new Field('Amount', '', ['empty'], false, 'money', {addContainerClass: 'col-span-3'}, {isNegativeAllowed: true}),
            Description: new Field('Description', '', ['empty'], false, 'textarea', {
                addContainerClass: 'col-span-7'
            }),
        })
        this.setState({
            terminationInvoiceItems: terminationInvoiceItems
        })
    }

    handleRemoveTerminationInvoiceItems = (i) => {
        let terminationInvoiceItems = this.state.terminationInvoiceItems
        delete terminationInvoiceItems[i]
        this.setState({
            terminationInvoiceItems: terminationInvoiceItems
        })
    }

    handleFilterInvoiceClear = () => {
        this.setState({
            queryFilterFieldsInvoices: this.getQueryFilterFieldsInvoices(),
        }, () => {
            this.saveFilters()
            this.fetchInvoiceData()
        })
    }

    handleTerminationInputChange = (name, value) => {
        this.setState({
            terminationInvoiceFields: FieldsManager.updateField(this.state.terminationInvoiceFields, name, value),
        })
    }

    handleTerminationItemsInputChange = (name, value, i) => {
        let terminationInvoiceItems = this.state.terminationInvoiceItems
        terminationInvoiceItems[i] = FieldsManager.updateField(terminationInvoiceItems[i], name, value)

        this.setState({
            terminationInvoiceItems: terminationInvoiceItems,
        })
    }

    /** Helpers
     ================================================================= */
    getID = () => {
        return this.props.DriverID
    }

    getResourceName = () => {
        return Resources.DriversPayroll
    }

    getQuery = () => {
        return {
            DriverID: this.getID(),
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
            searchFields: JSON.stringify({
                ...(this.state.queryFilterFields.EndDate.value && {EndDate: ['Date', '<', this.state.queryFilterFields.EndDate.value]}),
                ...(this.state.queryFilterFields.StartDate.value && {StartDate: ['Date', '>', this.state.queryFilterFields.StartDate.value]})
            })
        }
    }

    getQueryInvoices = () => {
        return {
            limit: this.state.limit,
            sort: this.state.sortInvoice,
            sortBy: this.state.sortByInvoice,
            offset: this.state.offsetInvoices,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFieldsInvoices),
            searchFields: JSON.stringify({DriverID: this.getID()})
        }
    }

    getQueryFilterFieldsInvoices = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            limit: new Field('limit', 5, [''], false, 'select', {hideLabel: true}, {menuPlacement: 'top'})
        }
    }

    getFields = (item = null) => {
        const fieldTemplates = {
            PayrollBatch: new Field('PayrollBatch', '', [], false, 'text'),
            Date: new Field('Date', '', [], false, 'date'),
            BookDate: new Field('BookDate', '', [''], false, 'date', {
                label: 'CheckPostingDate'
            }),
            Gross: new Field('Gross', '', [], false, 'money'),
            TotalAmount: new Field('TotalAmount', '', [], false, 'money', {
                label: 'NetAmount'
            }),
            PayToOrganizationID: new Field('PayToOrganizationID', '', [], false, 'custom', {
                render: (it) => {
                    return it.PayToOrganizationID ? it.PayToOrganization : ''
                }
            }),
            IncludeIn1099: new Field('IncludeIn1099', '', [], false, 'checkbox'),
            IsPayed: new Field('IsPayed', '', [], false, 'checkbox')
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getOpenPayEntryFields = (item = null) => {
        const fieldTemplates = {
            Item: new Field('Item', '', [], false, 'text', {hideTable: true}),
            Name: new Field('Name', '', [], false, 'text'),
            TotalAmount: new Field('TotalAmount', '', [], false, 'money')
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getSummaryFields = (item = null) => {
        const fieldTemplates = {
            GrossSoFarYTD: new Field('GrossSoFarYTD', '', [], false, 'text'),
            TotalAmountYTD: new Field('TotalAmountYTD', '', [], false, 'text'),
            GrossSoFarPreviousYTD: new Field('GrossSoFarPreviousYTD', '', [], false, 'text'),
            TotalAmountPreviousYTD: new Field('TotalAmountPreviousYTD', '', [], false, 'text'),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    unpaidItemsFields = (item = null) => {
        const fieldTemplates = {
            LoadNumber: new Field('LoadNumber', '', [], false, 'text'),
            Customer: new Field('Customer', '', [], false, 'text'),
            Amount: new Field('Amount', '', [], false, 'money')
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    escrowBalancesFields = (item = null) => {
        const fieldTemplates = {
            Notes: new Field('Notes', '', [], false, 'text'),
            Amount: new Field('Amount', '', [], false, 'money'),
            MaxAmount: new Field('MaxAmount', '', [], false, 'money')
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    loanBalancesFields = (item = null) => {
        const fieldTemplates = {
            Description: new Field('Description', '', [], false, 'text'),
            Amount: new Field('Amount', '', [], false, 'money')
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            StartDate: new Field('StartDate', '', [], false, 'date', {labelType: 'stack'}),
            EndDate: new Field('EndDate', '', [], false, 'date', {labelType: 'stack'}),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {
                menuPlacement: 'top',
                hasPortal: true
            })
        }
    }

    getFuelCardsFields = () => {
        return {
            FuelCard: new Field('FuelCard', '', [''], false, 'text'),
            Description: new Field('Description', '', [''], false, 'text')
        }
    }

    handleToggleViewModal = (selectedItem = null) => {
        this.setState({selectedItem: selectedItem, isPayrollViewDialogOpen: !this.state.isPayrollViewDialogOpen})
    }

    handleToggleEditModal = (selectedItem = null) => {
        this.setState({selectedItem: selectedItem, isPayrollEditDialogOpen: !this.state.isPayrollEditDialogOpen})
    }

    toggleExpandSection = (sectionName) => {
        {
            this.setState({
                [sectionName]: !this.state[sectionName]
            })
        }
    }

    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, Object.keys(this.getQuery()).map(it => it))
    }

    handleFilterClear = () => {
        this.setState({
            queryFilterFields: this.getQueryFilterFields(),
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    downloadDocument = (item) => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.PayrollBatchesItemsDocument,
            query: {
                PayrollBatchEntryID: this.state?.selectedItem?.PayrollBatchEntryID ?? item.PayrollBatchEntryID,
                type: 1,
                name: (this.state?.selectedItem?.Name ?? item?.Name) + ' ' + (this.state?.selectedItem?.PayrollBatch ?? item?.PayrollBatch) + '.pdf'
            },
            errorMessage: true,
            successMessage: 'Successfully downloaded payroll batch!'
        }))
    }

    downloadDocumentBulk = (item, name) => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.PayrollBatchesItemsDocuments,
            query: {
                PayrollBatchEntryIDs: item,
                format: 'ZIP',
                name: 'Payroll_statements_' + name + '_' + currentDate() + '.zip'
            },
            errorMessage: true,
            successMessage: 'Successfully downloaded payroll batches!'
        }))
    }

    handleCreateTerminationModal = () => {
        if (this.state.createTerminationModal) {
            this.setState({
                createTerminationModal: false,
                terminationInvoiceFields: this.getCreateTerminationFields(),
                terminationInvoiceItems: []
            })
        } else {
            this.setState({createTerminationModal: true})
        }
    }

    getCreateTerminationFields = () => {
        return {
            OfficeID: new Field('OfficeID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-1'}),
            ContactGroupID: new Field('ContactGroupID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-1'}),
            RefNumber: new Field('RefNumber', '', ['empty'], false, 'text', {addContainerClass: 'col-span-1'}),
            Date: new Field('Date', currentDate(), ['empty'], false, 'date'),
            DueDate: new Field('DueDate', currentDate(), ['empty'], false, 'date'),
            BookDate: new Field('BookDate', currentDate(), ['empty'], false, 'date'),
            InternalNotes: new Field('InternalNotes', '', [''], false, 'textarea', {addContainerClass: 'col-span-full'}),
            ExternalNotes: new Field('ExternalNotes', '', [''], false, 'textarea', {addContainerClass: 'col-span-full'}),
            UseEscrow: new Field('UseEscrow', '1', [], false, 'checkbox', {addContainerClass: 'col-span-full'}),
        }
    }

    getTerminationTableFields = () => {
        return {
            AutoCounter: new Field('AutoCounter', '', [], false, 'text'),
            RefNumber: new Field('RefNumber', '', ['empty'], false, 'text'),
            Amount: new Field('Amount', '', [''], false, 'money', {}),
            AmountTransferred: new Field('AmountTransferred', '', [''], false, 'money', {
                addTableHeaderClass: 'ml-auto',
                render: (item) => {
                    return (
                        <div className="flex justify-end w-full">
                            {genericMoneyFormatter(item.Amount - item.AmountTransferred)}
                        </div>
                    )
                }
            }),
            Date: new Field('Date', '', [''], false, 'date'),
            DueDate: new Field('DueDate', '', [''], false, 'date'),
            InvoiceStatusID: new Field('InvoiceStatusID', '', [''], false, 'custom', {
                render: (it) => {
                    return renderInvoiceStatusBadge(it)
                }
            }),
        }
    }

    /** Render
     ================================================================= */
    renderBatchItemPDF = (translate) => {
        return (
            <ModalDefault
                show={!!this.state.selectedItem}
                widthClass={'max-w-7xl'}
                limitHeight={true}
                title={translate('modal_heading.payroll_statement')}

                close={this.handleToggleViewModal}
                closeButtonLabel={translate('Close')}
                onClose={this.handleToggleViewModal}
                buttonLabel={translate('btn.download')}
                onButtonClick={this.downloadDocument}
            >
                <FileViewer
                    fileType={'pdf'}
                    filePath={Env.getApiUrl('api/' + Resources.PayrollBatchesItemsDocument, Object.assign({}, {
                        PayrollBatchEntryID: this.state.selectedItem.PayrollBatchEntryID,
                        token: getJWT().access_token,
                        type: 1
                    }))}
                    onError={(e) => {
                        console.log(e)
                    }}/>
            </ModalDefault>
        )
    }

    render() {
        const {translate, resource} = this.props

        const data = getProp(this.props, 'resource.data.list', [])
        const count = getProp(this.props, 'resource.data.count', 0)
        const isLoading = getProp(this.props, 'resource.isLoading', false)

        const summaryData = getProp(this.props, 'secondResource.data', [])
        const isSummaryLoading = getProp(this.props, 'secondResource.isLoading', false)

        const driverData = getProp(this.props, 'secondResource.data.Driver', {})
        const isDriverLoading = getProp(this.props, 'secondResource.isLoading', false)

        const terminationInvoiceData = getProp(this.props, 'thirdResource.data.list', {})
        const isTerminationInvoiceLoading = getProp(this.props, 'thirdResource.isLoading', false)
        const terminationInvoiceCount = getProp(this.props, 'thirdResource.data.count', 0)

        const OnLoadID = driverData.OnLoadID
        const isDriverTerminated = driverData.StatusID === 8

        const netAmount = [summaryData.UnpaidItemsTotal, summaryData.LoanBalancesTotal, summaryData.EscrowBalancesTotal].reduce((accumulator, currentValue) => accumulator + currentValue, 0)

        const StatusID = driverData.StatusID
        const canTerminate = (StatusID === 5 && netAmount === 0)

        const createInvoiceNetAmount = [summaryData.LoanBalancesTotal, summaryData.EscrowBalancesTotal].reduce((accumulator, currentValue) => accumulator + currentValue, 0)
        const canCreateInvoice = (createInvoiceNetAmount > 0) && !(summaryData.EscrowBalancesTotal > summaryData.LoanBalancesTotal)
        const areSomeItemsSelected = !!Object.keys(this.state.selectedRows)?.length

        const metadata = {
            OfficeID: {
                api: 'api/' + Resources.OfficesQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.OfficeName,
                    value: item.OfficeID
                })
            },
            ContactGroupID: {
                api: 'api/' + Resources.ContactGroupsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.ContactGroupName,
                    value: item.ContactGroupID
                })
            },
            AccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: {},
                searchMap: (it) => ({
                    label: it.AccountNumber + ' ' + it.AccountName,
                    value: it.AccountID
                })
            }
        }

        const fieldsHtmlBasicInfo = fieldsToHtml(Object.values(Object.assign({}, this.state.terminationInvoiceFields)), translate, this.handleTerminationInputChange, metadata)

        let additionTerminationItemsTotal = 0
        const additionTerminationItems = this.state.terminationInvoiceItems.map((fields, i) => {
            additionTerminationItemsTotal += numberWithCommasToBack(fields.Amount.value) ?? 0
            return (
                <div
                    key={i}
                    className={classNames('grid gap-4 px-6 py-1 grid-cols-7 items-end')}>
                    <button
                        className={'btn btn-icon'}
                        onClick={() => this.handleRemoveTerminationInvoiceItems(i)}
                    ><TrashIcon className="w-5 h-5 text-tm-gray-600"/>
                    </button>
                    {
                        fieldsToHtml(Object.values(Object.assign({}, fields)), translate, (name, value) => {
                            this.handleTerminationItemsInputChange(name, value, i)
                        }, metadata)
                    }
                </div>
            )
        })

        const terminationInvoiceTotal = summaryData.LoanBalancesTotal - (
            this.state.terminationInvoiceFields?.UseEscrow?.value ? summaryData.EscrowBalancesTotal : 0
        ) + additionTerminationItemsTotal

        return (
            <div className="space-y-8">
                <>
                    <div>
                        {isDriverLoading && (
                            <div className="flex justify-end">
                                <LoaderSmall/>
                            </div>
                        )}

                        {!isDriverLoading && isDriverTerminated && (
                            <div className="flex justify-end space-x-4">
                                <DriverStatusBadge
                                    item={{
                                        StatusID: StatusID,
                                        OnLoadID: OnLoadID
                                    }}
                                    translate={translate}
                                    addClass={"h-8"}
                                />

                                {(checkPerm(Resources.DriversTerminate, UPDATE_PERM) && StatusID === 8) && (
                                    <div>
                                        <button className={'btn btn-primary'}
                                                onClick={this.toggleUndoTerminateConfirmDialog}>
                                            Undo Terminate
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}

                        {(!isDriverLoading && !isDriverTerminated) && (
                            <div className="flex justify-end space-x-4">
                                {/*<DriverStatusBadge*/}
                                {/*    item={{*/}
                                {/*        StatusID: StatusID,*/}
                                {/*        OnLoadID: OnLoadID*/}
                                {/*    }}*/}
                                {/*    translate={translate}*/}
                                {/*    addClass={"h-8"}*/}
                                {/*/>*/}
                                {checkPerm(Resources.DriversTerminate, UPDATE_PERM) && (
                                    <div>
                                        <button className={'btn btn-primary'}
                                                disabled={!canTerminate}
                                                onClick={this.toggleTerminateConfirmDialog}>
                                            Terminate
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}

                        <DriverPayrollSummary
                            data={summaryData}
                            getSummaryFields={this.getSummaryFields}
                            toggleExpandSection={this.toggleExpandSection}
                            isSectionExpanded={this.state.isSummarySectionExpanded}
                            isLoading={isSummaryLoading}
                            translate={translate}
                        />
                    </div>
                </>

                <DriverPayrollOpenPayEntries
                    contact={driverData}
                    data={summaryData}
                    getSummaryFields={this.getSummaryFields}
                    toggleExpandSection={this.toggleExpandSection}
                    unpaidItemsFields={this.unpaidItemsFields()}
                    loanBalancesFields={this.loanBalancesFields()}
                    escrowBalancesFields={this.escrowBalancesFields()}
                    openPayEntryFields={this.getOpenPayEntryFields()}
                    openPayEntryData={this.state.openPayEntryData}
                    isSectionExpanded={this.state.isOpenPayEntriesSectionExpanded}
                    isContactAdvancesDialogOpen={this.state.isContactAdvancesDialogOpen}
                    setIsContactAdvancesDialogOpen={this.handleToggleContactAdvancesDialog}
                    isLoading={isSummaryLoading}
                    translate={translate}
                />

                {checkPerm(Resources.DriversTerminateInvoice, READ_PERM) && (
                    <div className="flex items-center">
                        <button
                            className="btn btn-icon text-tm-gray-500 hover:text-tm-gray-700 w-8 h-8 -ml-2 md mr-2"
                            onClick={() => this.toggleExpandSection('isTerminationInvoices')}
                        >
                            <ChevronRightIcon
                                className={
                                    classNames(
                                        'w-5 h-5',
                                        (this.state.isTerminationInvoices) ? 'rotate-90' : undefined
                                    )
                                }
                            />
                        </button>

                        <Subtitle subtitle={translate('text.termination_invoices')}/>
                        {!isLoading && driverData.StatusID !== 8 && (
                            <button
                                className={'btn btn-outline m-2'}
                                disabled={!canCreateInvoice}
                                onClick={this.handleCreateTerminationModal}
                            >
                                Create termination invoice
                            </button>
                        )}
                    </div>
                )}


                {this.state.isTerminationInvoices && checkPerm(Resources.DriversTerminateInvoice, READ_PERM) && (
                    <div>
                        <div className={'mb-5'}>
                            <ActiveFilters
                                filterFields={this.state.queryFilterFieldsInvoices}
                                onLabelClick={this.handleFilterInputChangeInvoices}
                                onClearFiltersClick={this.handleFilterInvoiceClear}
                                translate={translate}
                            />
                        </div>
                        <TableCard>
                            <TableFilters
                                hideLimit
                                filterFields={this.state.queryFilterFieldsInvoices}
                                handleInputChange={this.handleFilterInputChangeInvoices}
                                translate={translate}
                            />

                            <ResourceTable
                                data={terminationInvoiceData}
                                count={terminationInvoiceCount}

                                tableKey="DriverInvoiceTerminationID"

                                fields={this.getTerminationTableFields()}
                                translate={translate}
                                isLoading={isTerminationInvoiceLoading}

                                limit={this.state.queryFilterFieldsInvoices?.limit?.value ?? this.state.limit}
                                offset={this.state.offsetInvoices}
                                page={this.state.paginationPage}
                                onOffsetChange={this.handleUpdateOffsetInvoices}

                                sort={this.state.sortInvoice}
                                sortBy={this.state.sortByInvoice}
                                onSortChange={this.handleUpdateSortInvoice}

                                onRowClick={checkPerm(Resources.InvoicesInvoice, READ_PERM) ? this.handleToggleInvoiceDetailsDialog : null}

                                actions={[
                                    {
                                        tooltipText: () => translate('text.details'),
                                        action: (it) => this.handleToggleInvoiceDetailsDialog(it),
                                        icon: () => EyeIcon,
                                        hidden: () => !checkPerm(Resources.InvoicesInvoice, READ_PERM),
                                        title: () => translate('text.details'),
                                        visible: (it) => it?.InvoiceID
                                    },
                                    {
                                        tooltipText: () => translate('btn.delete'),
                                        action: (it) => this.handleToggleConfirmModal(it),
                                        icon: () => TrashIcon,
                                        hidden: () => false,
                                        title: () => translate('btn.delete'),
                                        visible: (it) => (checkPerm(Resources.DriversTerminateInvoice, DELETE_PERM) && it.AmountTransferred === 0 && driverData.StatusID !== 8),
                                    }
                                ]}
                            />

                            <NoRecordsTable
                                show={(terminationInvoiceData.length === 0) && !isTerminationInvoiceLoading}
                                canCreate={false}
                                title={translate('text.no_matching_records')}
                                className={'pt-16 px-6'}
                            />

                            <TableCardFooter
                                show={!!terminationInvoiceCount || isLoading}
                            >
                                <Pagination
                                    count={terminationInvoiceCount}
                                    isLoading={isTerminationInvoiceLoading}
                                    handleQueryChange={
                                        (name, value, currentPage) => name === 'offset'
                                            ? this.handleUpdateOffsetInvoices(value, currentPage)
                                            : this.handleFilterInputChangeInvoices(name, value)
                                    }
                                    pageOffset={this.state.offsetInvoices}
                                    queryFields={this.state.queryFilterFieldsInvoices}
                                    translate={translate}
                                />
                            </TableCardFooter>

                            <TableBulkActions
                                selectedRows={this.state.selectedRows}
                                tableKey={'PayrollBatchEntryID'}
                                fields={this.getFields()}
                                translate={translate}
                                options={this.state.tableOptions}
                                setSelectedRows={this.setSelectedRows}
                                onSelectAllClick={this.handleSelectAllClick}
                            >
                            </TableBulkActions>
                        </TableCard>
                    </div>
                )}

                <div className="space-y-4">
                    <div className="flex justify-between">
                        <div className="flex items-center">
                            <button
                                className="btn btn-icon text-tm-gray-500 hover:text-tm-gray-700 w-8 h-8 -ml-2 md mr-2"
                                onClick={() => this.toggleExpandSection('isPayrollSectionExpanded')}
                            >
                                <ChevronRightIcon
                                    className={
                                        classNames(
                                            'w-5 h-5',
                                            this.state.isPayrollSectionExpanded ? 'rotate-90' : undefined
                                        )
                                    }
                                />
                            </button>

                            <Subtitle subtitle={translate('text.payroll_statements')}/>
                        </div>

                        <DriverPayrollStatementsSearch
                            DriverID={this.getID()}
                            onRowClick={checkPerm(Resources.PayrollBatchesItemsDocument, READ_PERM) && this.handleToggleViewModal}
                            onExpenseClick={() => null}
                            translate={translate}
                        />
                    </div>

                    {this.state.isPayrollSectionExpanded && (
                        <div>
                            <div className={'mb-5'}>
                                <ActiveFilters
                                    filterFields={this.state.queryFilterFields}
                                    onLabelClick={this.handleFilterInputChange}
                                    onClearFiltersClick={this.handleFilterClear}
                                    translate={translate}
                                />
                            </div>

                            <div
                                className={"flex flex-col max-h-full md:bg-inverse md:border border-tm-gray-300 md:shadow-card rounded-card"}>
                                <TableFilters
                                    hideLimit
                                    filterFields={this.state.queryFilterFields}
                                    handleInputChange={this.handleFilterInputChange}
                                    customHeaderHtml={
                                        <PopoverDatePicker
                                            queryFields={this.state.queryFilterFields}
                                            onQueryInputChange={this.handleFilterInputChange}
                                            updateFields={this.handleUpdateQueryFields}
                                            translate={translate}
                                        />
                                    }
                                    translate={translate}
                                />

                                <ResourceTable
                                    data={data}
                                    count={count}

                                    tableKey="PayrollBatchEntryID"
                                    onSelectRow={this.handleSelectRowClick}
                                    selectedRows={this.state.selectedRows}
                                    onSelectAllClick={this.handleSelectAllClick}
                                    options={this.tablePageDefaults}

                                    fields={this.state.fields}
                                    translate={translate}
                                    isLoading={isLoading}

                                    limit={this.state.queryFilterFields?.limit?.value ?? this.state.limit}
                                    offset={this.state.offset}
                                    page={this.state.paginationPage}
                                    onOffsetChange={this.handleUpdateOffset}


                                    sort={this.state.sort}
                                    sortBy={this.state.sortBy}
                                    onSortChange={this.handleUpdateSort}

                                    // onRowClick={(it) => this.handleToggleViewModal(it)}
                                    onRowClick={checkPerm(Resources.PayrollBatchesItemsDocument, READ_PERM) && this.handleToggleViewModal}
                                    onEdit={checkPerm(Resources.DriversPayroll, UPDATE_PERM) && this.handleToggleEditModal}

                                    actions={[
                                        {
                                            tooltipText: () => translate('btn.preview'),
                                            action: (it) => this.handleToggleViewModal(it),
                                            icon: () => EyeIcon,
                                            hidden: () => false,
                                            title: () => translate('btn.preview'),
                                            visible: () => checkPerm(Resources.PayrollBatchesItemsDocument, READ_PERM),
                                        },
                                        {
                                            tooltipText: () => translate('btn.download'),
                                            action: (it) => this.downloadDocument(it),
                                            icon: () => ArrowDownTrayIcon,
                                            hidden: () => false,
                                            title: () => translate('btn.download'),
                                            visible: () => checkPerm(Resources.PayrollBatchesItemsDocument, READ_PERM),
                                        },
                                    ]}
                                />


                                <NoRecordsTable
                                    show={(data.length === 0) && !isLoading}
                                    canCreate={false}
                                    title={translate('text.no_matching_records')}
                                    className={'pt-16 px-6'}
                                />
                                <TableCardFooter>
                                    <Pagination
                                        count={count}
                                        isLoading={resource.isLoading}
                                        handleQueryChange={
                                            (name, value, currentPage) => name === 'offset'
                                                ? this.handleUpdateOffset(value, currentPage)
                                                : this.handleFilterInputChange(name, value)
                                        }
                                        pageOffset={this.state.offset}
                                        queryFields={this.state.queryFilterFields}
                                        translate={translate}
                                    />
                                </TableCardFooter>

                                <TableBulkActions
                                    selectedRows={this.state.selectedRows}
                                    tableKey={'PayrollBatchEntryID'}
                                    fields={this.getFields()}
                                    translate={translate}
                                    options={this.state.tableOptions}
                                    setSelectedRows={this.setSelectedRows}
                                    onSelectAllClick={this.handleSelectAllClick}
                                >
                                    <div className="flex items-center h-8">
                                        <div className={classNames('flex divide-x-2 divide-primary-shade pr-2')}>
                                            <div className="flex gap-x-1 px-2">
                                                <Tippy content={translate('text.bulk_download')}>
                                                    <button
                                                        onClick={() => {
                                                            this.downloadDocumentBulk(Object.values(this.state.selectedRows).map((it) => it.PayrollBatchEntryID), data[0]?.Name)
                                                            this.setState({selectedRows: {}})
                                                        }}
                                                        className="flex p-1 rounded-btn hover:bg-primary-shade"
                                                    >
                                                        <ArrowDownTrayIcon
                                                            className="w-5 h-5 text-primary-contrast"/>
                                                    </button>
                                                </Tippy>
                                            </div>

                                        </div>
                                    </div>
                                </TableBulkActions>
                            </div>
                        </div>
                    )}


                    <div className="space-y-4">
                        <div className="flex justify-between mb-5">
                            <div className="flex items-center">
                                <button
                                    className="btn btn-icon text-tm-gray-500 hover:text-tm-gray-700 w-8 h-8 -ml-2 md mr-2"
                                    onClick={() => this.toggleExpandSection('isFuelCardsSectionExpanded')}
                                >
                                    <ChevronRightIcon
                                        className={
                                            classNames(
                                                'w-5 h-5',
                                                this.state.isFuelCardsSectionExpanded ? 'rotate-90' : undefined
                                            )
                                        }
                                    />
                                </button>

                                <Subtitle subtitle={translate('text.fuel_cards')}/>
                            </div>
                        </div>
                    </div>

                    {this.state.isFuelCardsSectionExpanded && (
                        <div>
                            <TableCard addClass={"mb-5"}>
                                <ResourceTable
                                    data={summaryData.FuelCards ?? []}

                                    fields={this.getFuelCardsFields()}
                                    translate={translate}
                                    isLoading={isLoading}
                                />
                                <NoRecordsTable
                                    show={(summaryData.FuelCards?.length === 0) && !isLoading}
                                    canCreate={false}
                                    title={translate('text.no_matching_records')}
                                    className={'pt-16 px-6 mb-5'}
                                />
                            </TableCard>
                        </div>
                    )}

                    <ModalDefault
                        show={!!this.state.isBulkPreviewModalOpen}
                        widthClass={'max-w-7xl'}
                        limitHeight={true}
                        title={translate('modal_heading.upload_confirm')}

                        closeButtonLabel={translate('Close')}
                        onClose={() => this.setState({isBulkPreviewModalOpen: false})}
                    >
                        {areSomeItemsSelected && this.state.isBulkPreviewModalOpen && (
                            <FileViewer
                                fileType={'pdf'}
                                filePath={Env.getApiUrl('api/' + Resources.PayrollBatchesItemsDocuments, Object.assign({}, {
                                    PayrollBatchEntryIDs: Object.values(this.state.selectedRows),
                                    token: getJWT().access_token,
                                    type: 1
                                }))}
                                onError={(e) => {
                                    console.log(e)
                                }}
                            />
                        )}
                    </ModalDefault>

                    <ModalConfirm
                        title={translate('Confirm terminate')}
                        show={!!this.state.terminateConfirmDialogOpen}
                        textClassName={'text-sm text-tm-gray-900'}
                        onClose={() => this.toggleTerminateConfirmDialog()}
                        buttonLabel={translate('btn.confirm')}
                        closeButtonLabel={translate('Cancel')}
                        translate={translate}
                        onConfirm={() => {
                            const params = {
                                DriverID: this.getID()
                            }

                            this.props.dispatch(updateResource({
                                user: LocalStorage.get('user'),
                                params: params,
                                resource: Resources.DriversTerminate,
                                errorMessage: true,
                                successMessage: translate('message.driver_status_to_terminated'),
                                piggyResource: this.getResourceName(),
                                piggyQuery: this.getQuery(),
                                piggySecondResource: Resources.PayrollSummary,
                                secondPiggyQuery: this.getQuery()
                            }))
                        }}
                    >
                        {isLoading && (
                            <LoaderSmall/>
                        )}

                        {!isLoading && (
                            <div className={'text-sm'}>{translate('message.are_you_sure_terminate_driver')}</div>
                        )}

                    </ModalConfirm>

                    <ModalConfirm
                        title={translate('Confirm undo terminate')}
                        show={!!this.state.undoTerminateConfirmDialogOpen}
                        textClassName={'text-sm text-tm-gray-900'}
                        onClose={() => this.toggleUndoTerminateConfirmDialog()}
                        buttonLabel={translate('btn.confirm')}
                        closeButtonLabel={translate('Cancel')}
                        translate={translate}
                        onConfirm={() => {
                            const params = {
                                DriverID: this.getID(),
                                Undo: 1
                            }

                            this.props.dispatch(updateResource({
                                user: LocalStorage.get('user'),
                                params: params,
                                resource: Resources.DriversTerminate,
                                errorMessage: true,
                                successMessage: translate('message.driver_reverted_to_inactive'),
                                piggyResource: this.getResourceName(),
                                piggyQuery: this.getQuery(),
                                piggySecondResource: Resources.PayrollSummary,
                                secondPiggyQuery: this.getQuery()
                            }))
                        }}
                    >
                        {isLoading && (
                            <LoaderSmall/>
                        )}

                        {!isLoading && (
                            <div className={'text-sm'}>{translate('message.are_you_sure_undo_terminate_driver')}</div>
                        )}

                    </ModalConfirm>

                    {!isLoading && !!this.state.isPayrollViewDialogOpen && this.renderBatchItemPDF(translate)}
                </div>

                <ModalDefault
                    show={!!this.state.createTerminationModal}
                    title={'Create termination invoice'}
                    widthClass={'max-w-xl'}
                    gridColsClass={'grid-cols-3'}
                    buttonLabel={translate('btn.submit')}
                    buttonDisabled={terminationInvoiceTotal < 0}
                    onButtonClick={() => {
                        this.setState({
                            terminationInvoiceFields: FieldsManager.validateFields(this.state.terminationInvoiceFields),
                            terminationInvoiceItems: this.state.terminationInvoiceItems.map((fields) => FieldsManager.validateFields(fields))
                        }, () => {
                            if (
                                FieldsManager.checkFieldsForErrors(this.state.terminationInvoiceFields)
                                &&
                                this.state.terminationInvoiceItems.reduce((memo, fields) => {
                                    return memo && FieldsManager.checkFieldsForErrors(fields)
                                }, true)
                            ) {
                                let params = FieldsManager.getFieldKeyValues(this.state.terminationInvoiceFields)
                                params.CreditBackItems = this.state.terminationInvoiceItems.map((fields) => {
                                    return FieldsManager.getFieldKeyValues(fields)
                                })
                                params.DriverID = this.getID()
                                this.props.dispatch(createThirdResource({
                                    user: LocalStorage.get('user'),
                                    params: params,
                                    resource: Resources.DriversTerminateInvoice,
                                    piggyResource: Resources.DriversTerminateInvoice,
                                    query: this.getQueryInvoices(),
                                    errorMessage: true,
                                    successMessage: 'Termination invoice created'
                                }))
                                this.handleCreateTerminationModal()
                            }
                        })
                    }}

                    closeButtonLabel={translate('btn.Close')}
                    onClose={() => this.handleCreateTerminationModal()}
                >
                    <div
                        className={classNames('grid gap-4 p-6 grid-cols-3')}>
                        {fieldsHtmlBasicInfo}
                        <p className={'font-bold mt-1 '}>
                            Invoice total {genericMoneyFormatter(terminationInvoiceTotal)}</p>
                        {terminationInvoiceTotal < 0 && (
                            <p className={'font-bold mt-1 text-red-600'}><ExclamationTriangleIcon
                                className={'w-5 h-5 text-red-600'}/>
                                Invoice total cannot be negative value</p>
                        )}
                    </div>

                    <div
                        className={classNames('px-6 py-1')}
                    >
                        <InfoParagraph type="info" className={'p-0'}>
                            {'You can optionally add invoice adjustments. Each item will be added as an additional line item to the invoice.'}
                        </InfoParagraph>
                        <button className={'btn btn-outline mx-2 my-5'}
                                onClick={this.handleAddTerminationInvoiceItem}
                        >Add adjustment
                        </button>
                    </div>
                    <div>
                        {additionTerminationItems}
                    </div>
                </ModalDefault>

                <ModalSaveResource
                    title={translate('text.update_payroll_entry')}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-6"
                    show={this.state.isPayrollEditDialogOpen}
                    onClose={() => this.handleToggleEditModal()}
                    fields={includeFields(
                        this.getFields(this.state.selectedItem),
                        ['IncludeIn1099']
                    )}
                    onSubmit={(params) => {
                        if (params) {
                            params.PayrollBatchEntryID = this.state.selectedItem.PayrollBatchEntryID
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get('user'),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName(),
                                errorMessage: true,
                                successMessage: 'Entry updated.',
                            }))
                            this.handleToggleEditModal()
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadata}
                />

                <ModalConfirm
                    title={'Confirm'}
                    show={!!this.state.confirmModalOpen}
                    text={translate('text.confirm_delete')}
                    onClose={this.handleToggleConfirmModal}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={() => {
                        this.props.dispatch(deleteThirdResource({
                            user: LocalStorage.get('user'),
                            query: {DriverInvoiceTerminationID: this.state.selectedInvoiceItem.DriverInvoiceTerminationID},
                            resource: Resources.DriversTerminateInvoice,
                            piggyResource: Resources.DriversTerminateInvoice,
                            piggyQuery: {searchFields: JSON.stringify({DriverID: this.getID()})},
                            errorMessage: true,
                            successMessage: 'Termination invoice deleted'
                        }))
                        this.handleToggleConfirmModal()
                        this.fetchSummaryData()
                    }}
                />

                <ModalDefault
                    show={this.state.invoiceDetails}
                    widthClass={'max-w-5xl w-screen'}
                    title={'Invoice'}

                    closeButtonLabel={translate('btn.Close')}
                    onClose={() => this.handleToggleInvoiceDetailsDialog()}
                >
                    <InvoiceExpenseDetailsDialog
                        resource={this.props.info}
                        isLoading={this.props.info.isLoading}
                        translate={translate}
                        dispatch={this.props.dispatch}
                        showLoadReferenceLinks={true}

                        disableComments={true}
                        canDeletePayment={false}
                        disableAddReference={true}
                        disableTransactionImport={true}

                        // Data events
                        onFetchData={() => {
                            if (this.state.selectedInvoiceItem) {
                                this.props.dispatch(getInfoResource({
                                    user: LocalStorage.get('user'),
                                    query: {
                                        InvoiceID: this.state.selectedInvoiceItem?.InvoiceID
                                    },
                                    resource: Resources.InvoicesInvoice
                                }))
                            }
                        }}
                    />
                </ModalDefault>
            </div>
        )
    }
}
