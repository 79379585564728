import Button from "../../../../common/components/button";
import {checkPerm} from "../../../../common/util/util-helpers";
import Resources from "../../../../data/services/resources";
import {
    ROUTE_STATUS_ACTIVATED,
    ROUTE_STATUS_CODE_SENT, ROUTE_STATUS_FINISHED_DRIVER, ROUTE_STATUS_FINISHED_OPERATOR,
    ROUTE_STATUS_TRACKED,
    UPDATE_PERM
} from "../../../../util/util-constants";
import {updateResource} from "../../../../data/actions/resource";
import LocalStorage from "../../../../util/localStorage";
import Badge from "../../../../common/components/badge";
import {InformationCircleIcon} from "@heroicons/react/24/outline";
import React, {useState} from "react";
import {toFrontDateTimeFromUTC} from "../../../../common/util/util-dates";
import {fieldsToHtml} from "../../../../common/util/util-fields";
import {Field, FieldsManager} from "../../../../data/services/fields";
import ModalConfirm from "../../../../common/components/modal/modal-confirm";
import DisplayDataFieldGroupContainer
    from "../../../../common/components/display-data/display-data-field-group-container";
import ModalDefault from "../../../../common/components/modal/modal-default";
import {useDispatch} from "react-redux";
import {Loader} from "../../../../common/components/loader";
import {getCountryCodes} from "../../../../common/util/countryCodes";

export default function Accur8Tracking({
                                           trackingData,
                                           driverCell,
                                           driverName,
                                           isLoading,
                                           translate,
                                           loadID,
                                           piggyResource
                                       }) {

    /** Helpers
     ================================================================= */
    const handlePhoneFieldInputChange = (name, value) => {
        let SMSPhoneField = FieldsManager.updateField(SMSModalPhoneField, name, value);
        setSMSModalPhoneField(SMSPhoneField);
    };

    function getSMSModalPhoneField() {
        return {
            AreaCode: new Field('AreaCode', LocalStorage.get('user')?.Office?.CountryCode ?? '+1', ['empty'], false, 'select'),
            PhoneNumber: new Field('PhoneNumber', '', ['integer'], false, 'text')
        }
    }

    const getTrackingStatusBadgeProperties = (trackingStatusID) => {
        switch (trackingStatusID) {
            case ROUTE_STATUS_CODE_SENT:
                return {
                    status: 'Code sent',
                    style: 'warning',
                    content: `SMS code was sent to ${LastSentPhoneNumber} at ${toFrontDateTimeFromUTC(RouteSentUtc)}`
                }
            case ROUTE_STATUS_ACTIVATED:
                return {
                    status: 'Activated',
                    style: 'success',
                    content: `Tracking was activated at ${toFrontDateTimeFromUTC(RouteActivatedUtc)}`
                }
            case ROUTE_STATUS_TRACKED:
                return {
                    status: 'In progress',
                    style: 'success',
                    content: 'Tracking is active, and locations are being received.'
                }
            case ROUTE_STATUS_FINISHED_DRIVER:
                return {
                    status: 'Finished by driver',
                    style: 'success',
                    content: `Tracking was finished by the driver at ${toFrontDateTimeFromUTC(RouteFinishedUtc)}`
                }
            case ROUTE_STATUS_FINISHED_OPERATOR:
                return {
                    status: 'Finished by user',
                    style: 'success',
                    content: `Tracking was finished by the user at ${toFrontDateTimeFromUTC(RouteFinishedUtc)}`
                }
            default:
                return {status: 'No tracking data', style: 'outline', content: 'No tracking data'}
        }
    }

    /** Constants
     ================================================================= */
    const dispatch = useDispatch();

    const [isSendSMSModalOpen, setIsSendSMSModalOpen] = useState(false);
    const [SMSModalPhoneField, setSMSModalPhoneField] = useState(getSMSModalPhoneField());
    const [isResend, setIsResend] = useState(false);
    const [isTrackingDetailsDialogOpen, setIsTrackingDetailsDialogOpen] = useState(false);

    const {
        RouteStatusID: trackingStatusID,
        LastSentPhoneNumber,
        RouteActivatedUtc,
        RouteFinishedUtc,
        RouteSentUtc
    } = trackingData

    const {style, content, status} = getTrackingStatusBadgeProperties(trackingStatusID)
    trackingData['RouteStatus'] = status

    /** View
     ================================================================= */
    const phoneFieldHTML = fieldsToHtml(Object.values(Object.assign({}, SMSModalPhoneField)), translate, handlePhoneFieldInputChange, {
        AreaCode: getCountryCodes()
    })

    return (
        <div>
            <h1 className={"text-lg leading-6 font-medium text-tm-gray-900 my-2 pb-5"}>ACCUR8 Tracking</h1>
            <div className={"bg-inverse rounded-xl border-tm-gray-300 border shadow-card px-3 py-3"}>

                {isLoading && (
                    <Loader disableContainer/>
                )}

                {!isLoading && (
                    <div className={"flex mr-2 mb-4"}>
                        {!trackingStatusID &&  (
                            <Button
                                isLoading={isLoading}
                                hasPerm={checkPerm(Resources.LoadTrackingStart, UPDATE_PERM)}
                                appearance={'primary'}
                                onClick={() => {
                                    if (driverCell) handlePhoneFieldInputChange('PhoneNumber', driverCell)
                                    setIsSendSMSModalOpen(true)
                                }}
                            >
                                {translate('btn.start_tracking')}
                            </Button>
                        )}

                        {trackingStatusID === ROUTE_STATUS_CODE_SENT && (
                            <Button
                                isLoading={isLoading}
                                hasPerm={true}
                                appearance={'muted'}
                                onClick={() => {
                                    if (driverCell) handlePhoneFieldInputChange('PhoneNumber', driverCell)
                                    setIsSendSMSModalOpen(true)
                                    setIsResend(true)
                                }}
                            >
                                {translate('btn.resend_sms')}
                            </Button>
                        )}

                        {(trackingStatusID === ROUTE_STATUS_ACTIVATED || trackingStatusID === ROUTE_STATUS_TRACKED) && (
                            <Button
                                isLoading={isLoading}
                                hasPerm={true} // check
                                appearance={'primary'}
                                onClick={() => {
                                    dispatch(updateResource({
                                        user: LocalStorage.get('user'),
                                        params: {LoadID: loadID},
                                        resource: Resources.LoadTrackingFinish,
                                        piggyResource: piggyResource,
                                        query: {id: loadID},
                                        errorMessage: true,
                                        successMessage: "Tracking finished!"
                                    }))
                                }}
                            >
                                {translate('btn.finish_tracking')}
                            </Button>
                        )}

                        {(trackingStatusID === ROUTE_STATUS_FINISHED_OPERATOR || trackingStatusID === ROUTE_STATUS_FINISHED_DRIVER) && (
                            <Button
                                isLoading={isLoading}
                                hasPerm={true}
                                appearance={'primary'}
                                onClick={() => {
                                    dispatch(updateResource({
                                        user: LocalStorage.get('user'),
                                        params: {LoadID: loadID},
                                        resource: Resources.LoadTrackingReset,
                                        piggyResource: piggyResource,
                                        query: {id: loadID},
                                        errorMessage: true,
                                    }))
                                }}
                            >
                                {translate('btn.restart_tracking')}
                            </Button>
                        )}
                    </div>
                )}

                {!!trackingStatusID && !isLoading && (
                    <div className={"flex mr-2 items-center"}>
                        <Badge type={style} addClass={'h-8 items-center'}>
                            {content}
                        </Badge>
                        <button
                            onClick={() => setIsTrackingDetailsDialogOpen(true)}
                            className={"z-10"}
                        >
                            <InformationCircleIcon className={"ml-2 w-6 h-6 text-primary"}/>
                        </button>
                    </div>
                )}
            </div>

            <ModalConfirm
                title={'Send SMS Confirmation'}
                show={isSendSMSModalOpen}
                onClose={() => {
                    setIsSendSMSModalOpen(false)
                    setSMSModalPhoneField(getSMSModalPhoneField())
                }}
                buttonLabel={translate('btn.confirm')}
                closeButtonLabel={'Cancel'}
                translate={translate}
                type={'info'}
                onConfirm={() => {
                    const validatedPhoneField = FieldsManager.validateFields(SMSModalPhoneField)
                    if (FieldsManager.checkFieldsForErrors(validatedPhoneField)) {
                        dispatch(updateResource({
                            user: LocalStorage.get('user'),
                            params: {
                                LoadID: loadID,
                                Phone: `${validatedPhoneField.AreaCode.value}${validatedPhoneField.PhoneNumber.value}`,
                                SendSMS: 0
                            },
                            resource: isResend ? Resources.LoadTrackingResend : Resources.LoadTrackingStart,
                            piggyResource: piggyResource,
                            query: {id: loadID},
                            errorMessage: true,
                            successMessage: "Tracking started!"
                        }))
                        setIsSendSMSModalOpen(false)
                        setIsResend(false)
                    } else {
                        setSMSModalPhoneField(validatedPhoneField)
                    }
                }
                }
            >
                <div className={"mb-2"}>
                    {`Send tracking SMS code to ${driverName ?? 'driver'} at:`}
                </div>
                <div>
                    {phoneFieldHTML}
                </div>
            </ModalConfirm>

            <ModalDefault
                show={isTrackingDetailsDialogOpen}
                title={translate("text.route_status")}
                widthClass={"max-w-3xl"}
                translate={translate}
                onClose={() => setIsTrackingDetailsDialogOpen(false)}
                closeButtonLabel={translate("btn.close")}
            >
                <div className={"my-4"}>
                    <DisplayDataFieldGroupContainer
                        fields={{
                            RouteStatus: new Field('RouteStatus', '', [''], false, "text"),
                            TrackingCode: new Field('TrackingCode', '', [''], false, "text"),
                            LastSentPhoneNumber: new Field('LastSentPhoneNumber', '', [''], false, "text"),
                            RouteSentUtc: new Field('RouteSentUtc', '', [''], false, "datetime"),
                            RouteActivatedUtc: new Field('RouteActivatedUtc', '', [''], false, "datetime"),
                            RouteFinishedUtc: new Field('RouteFinishedUtc', '', [''], false, "datetime"),
                        }}
                        data={trackingData}
                        translate={translate}
                    />
                </div>
            </ModalDefault>
        </div>
    )
}
