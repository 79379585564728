import {classNames} from "../../../util/util-helpers";
import Tooltip from "../../tooltip";
import {PaperClipIcon} from "@heroicons/react/24/outline";
import {BarsArrowDownIcon, BarsArrowUpIcon} from "@heroicons/react/20/solid";
import React from "react";

export default function CommandCenterTableHeader({
                                                     columns,
                                                     tableOptions,
                                                     isClosestMatchListingActive,
                                                     areCommentsExpanded,
                                                     onToggleStickyCol,
                                                     onSort,
                                                     sortBy,
                                                     sort,
                                                     groupSortBy,
                                                     groupSort,
                                                     dispatchGroupSortBy,
                                                     dispatchGroupSort,
                                                     hasThirdSortColumn,
                                                     translate
                                                 }) {
    return <header className="sticky top-0 z-20">
        {/*Sort by GROUP_BY_DISPATCH_GROUP_PLUS_DISPATCH*/}
        <div className="flex">
            {
                columns.filter(col => !tableOptions.columns?.[col.name]?.hidden).map(col => {
                        const canSort = !!col?.metadata?.canSort && !isClosestMatchListingActive;
                        const DynamicElement = canSort ? "button" : "div"

                        return <>
                            <div
                                key={col.name}

                                className={classNames(
                                    "group relative cc-header col-" + col.name
                                )}
                            >
                                <div className="flex flex-col w-full">
                                    {["DriverStatusID", "DriverPositionType", "GrossMileage", "Truck", "LastLocation"].includes(col.name) && (
                                        <div
                                            className="absolute inset-0 border border-transparent group-hover:border-primary rounded-t-btn"
                                        >
                                            <Tooltip
                                                content={tableOptions?.columns?.[col?.name]?.isSticky ? "Unfreeze column" : "Freeze column"}>
                                                <button
                                                    onClick={() => onToggleStickyCol(col.name)}
                                                    className="hidden group-hover:z-10 group-hover:block absolute bottom-1.5 left-0">
                                                    <div

                                                        className="btn-icon p-1 bg-primary text-primary-contrast">
                                                        <PaperClipIcon className="w-5 h-5"/>
                                                    </div>
                                                </button>
                                            </Tooltip>
                                        </div>
                                    )}

                                    <DynamicElement
                                        className={canSort ? "-mx-0.5 px-0.5 py-1 mb-0.5 hover:bg-tm-gray-200 flex items-center justify-between relative z-1 rounded-btn leading-3" : undefined}
                                        onClick={canSort ? (() => onSort(col.name)) : undefined}>
                                        {col?.metadata?.label ?? translate("field." + col.name)}

                                        {canSort && (
                                            <div className={
                                                classNames(
                                                    col.name === sortBy || col.name === groupSortBy || col.name === dispatchGroupSortBy ? "text-tm-gray-800" : "text-tm-gray-300",
                                                    "inline-flex flex-shrink-0 ml-2 w-5 h-5"
                                                )
                                            }>
                                                {col.name === "DispatchContactGroup" && !!hasThirdSortColumn && (
                                                    <>
                                                        {!(col.name === dispatchGroupSortBy && dispatchGroupSort === "DESC") && (
                                                            <BarsArrowUpIcon/>
                                                        )}

                                                        {col.name === dispatchGroupSortBy && dispatchGroupSort === "DESC" && (
                                                            <BarsArrowDownIcon/>
                                                        )}
                                                    </>
                                                )}

                                                {(col.name === "PrimaryDispatch" || col.name === "ContactGroup" || (col.name === "DispatchContactGroup" && !hasThirdSortColumn)) && (
                                                    <>
                                                        {!(col.name === groupSortBy && groupSort === "DESC") && (
                                                            <BarsArrowUpIcon/>
                                                        )}

                                                        {col.name === groupSortBy && groupSort === "DESC" && (
                                                            <BarsArrowDownIcon/>
                                                        )}
                                                    </>
                                                )}

                                                {col.name !== "PrimaryDispatch" && col.name !== "ContactGroup" && col.name !== "DispatchContactGroup" && (
                                                    <>
                                                        {!(col.name === sortBy && sort === "DESC") && (
                                                            <BarsArrowUpIcon/>
                                                        )}

                                                        {col.name === sortBy && sort === "DESC" && (
                                                            <BarsArrowDownIcon/>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </DynamicElement>
                                </div>
                            </div>

                            {!!areCommentsExpanded && !["PrimaryDispatch", "DispatchContactGroup", "ContactGroup", "Driver", "DriverStatusID", "DriverPositionType", "GrossMileage", "Truck", "LastLocation"].includes(col.name) && (
                                <div
                                    className="cc-cell group cc-header px-1 w-64 justify-center">Notes</div>
                            )}
                        </>
                    }
                )
            }
        </div>
    </header>
}