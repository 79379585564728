import React, {useCallback, useEffect, useState} from 'react';
import {Field, FieldsManager} from "../../../../data/services/fields";
import ResourceTable from "../../../../common/components/resource-table";
import useQueryNew from "../../../../hooks/use-query-new";
import {useDispatch, useSelector} from "react-redux";
import {getResource} from "../../../../data/actions/resource";
import LocalStorage from "../../../../util/localStorage";
import TableCard from "../../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../../common/components/resource-table/table-components/table-filters";
import NoRecordsTable from "../../../../common/components/no-records-found/no-records-table";
import {checkPerm, getDefaultTableOptions, updateQuery} from "../../../util/util-helpers";
import ActiveFilters from "../../resource-table/table-components/active-filters";
import {READ_PERM} from "../../../util/util-consts";
import ButtonIcon from "../../button/button-icon";
import {download} from "../../../../data/actions/download";
import {currentDate} from "../../../util/util-dates";
import DocumentArrowDownIcon from "@heroicons/react/20/solid/DocumentArrowDownIcon";
import PayDateFilter from "../../../../views/accounting/carrier-pay/carrier-pay-date-filter";
import {excludeFields} from "../../../util/util-fields";

export default function ReportTab({
                                      additionalQuery,
                                      beforeActiveFilters,
                                      resourcePath,
                                      storagePath,
                                      selects = {},
                                      getFields,
                                      tableKey,
                                      hasDateFilters,
                                      translate
                                  }) {
    const dispatch = useDispatch();
    const [queryFields, setQueryFields] = useQueryNew(getQueryFields(), storagePath);
    const [tableOptions, setTableOptions] = useState(() => getTableOptions(storagePath, translate));


    const resource = useSelector(state => state.resource);
    const data = resource?.data?.list ?? [];
    const isLoading = resource.isLoading ?? false;

    const fetchData = useCallback(() => {
        dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: resourcePath,
            query: FieldsManager.getFieldKeyValues(queryFields)
        }))
    }, [resourcePath, queryFields, dispatch]);

    function downloadExcel() {
        dispatch(download({
            user: LocalStorage.get('user'),
            resource: resourcePath,
            query: Object.assign({
                format: 'EXCEL',
                name: 'gross_report' + currentDate() + '.xlsx'
            }, FieldsManager.getFieldKeyValues(queryFields))
        }))
    }

    function handleQueryChange(name, value) {
        let queryUpdate = updateQuery(queryFields, [], name, value);

        if (name === 'query') {
            queryUpdate.offset.value = "0"
        }

        if (name === 'limit') {
            queryUpdate.offset.value = "0"
        }

        setQueryFields(queryUpdate);
    }

    function getQueryFields() {
        return Object.assign({
                query: new Field('query', '', [''], false, 'search', {hideLabel: true, labelType: "float"})
            },
            additionalQuery,
            {
                offset: new Field('offset', '0', [''], false, 'text'),
                sort: new Field('sort', 'ASC', [''], false, 'text'),
                sortBy: new Field('sortBy', tableKey, [''], false, 'text'),
                limit: new Field('limit', "10", [''], false, 'select', {
                    hideLabel: true,
                    labelType: "float"
                }, {menuPlacement: "top"})
            });
    }


    function getTableOptions(pagePath, translate) {
        const tablePageDefaults = {
            behaviour: {
                canAdjustWidth: false,
                hasMenu: false
            },
            style: {
                condensed: true,
                isGPUAccelerated: true
            }
        }

        return getDefaultTableOptions(getFields(), tablePageDefaults, storagePath, translate);
    }

    function handleClearFiltersClick() {
        const queryFieldsUpdate = Object.values(queryFields).reduce((memo, it) => {
            if (!["sort", "sortBy", "limit", "offset"].includes(it.name)) {
                it.value = "";
            }

            memo[it.name] = it;
            return memo;
        }, {})

        setQueryFields(queryFieldsUpdate);
    }

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <>
            {beforeActiveFilters}

            <div className="sm:flex mb-2">
                <ActiveFilters
                    filterFields={queryFields}
                    onLabelClick={handleQueryChange}
                    onClearFiltersClick={handleClearFiltersClick}
                    translate={translate}
                />

                <div className="ml-auto flex sm:justify-start justify-end items-center">
                    <ButtonIcon
                        hasPerm={checkPerm(resourcePath, READ_PERM)}
                        onClick={downloadExcel}
                    >
                        <DocumentArrowDownIcon className="w-6 h-6"/>
                    </ButtonIcon>
                </div>
            </div>

            <TableCard>
                <TableFilters
                    forceDialog
                    hideLimit
                    filterFields={
                        hasDateFilters
                            ? excludeFields(queryFields, ['StartDate', 'EndDate', 'DateType'])
                            : queryFields
                    }
                    handleInputChange={handleQueryChange}
                    translate={translate}
                    onRefreshTable={fetchData}
                    selects={selects}
                    isLoading={isLoading}
                    customHtml={
                        hasDateFilters
                            ? (
                                <PayDateFilter
                                    queryFields={queryFields}
                                    translate={translate}
                                    updateQueryFields={(queryFieldsUpdate) => {
                                        setQueryFields(queryFieldsUpdate)
                                    }}
                                    onQueryChange={handleQueryChange}
                                />
                            )
                            : undefined
                    }
                />

                <ResourceTable
                    tableKey={tableKey}
                    data={data}
                    fields={getFields()}
                    options={tableOptions}
                    isLoading={isLoading}
                    translate={translate}
                    queryFields={queryFields}
                    onSortChange={(fieldName) => handleQueryChange("sortBy", fieldName)}
                    saveTableOptions={setTableOptions}
                    maxHeightClass={"max-h-[calc(100vh-23rem)]"}
                />

                <NoRecordsTable
                    show={(data.length === 0) && !isLoading}
                    title={'No matching records found'}
                    className={"pb-12 pt-16 px-6"}
                />
            </TableCard>
        </>
    );
}