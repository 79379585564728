import React, {useEffect, useRef, useState} from 'react'
import axios from 'axios'
import Env from '../../../util/env'
import {processError, processResponse} from '../../../data/services/api-util'
import {useDispatch, useSelector} from 'react-redux'
import {createPublicResource, getPublicResource, updatePublicResource} from '../../../data/actions/publicResource'
import Resources from '../../../data/services/resources'
import {Field, FieldsManager} from "../../../data/services/fields";
import PublicCarrierInfo from "./tabs/public-carrier-info";
import {cloneDeep, dirtyClone} from "../../../common/util/util-vanilla";
import moment from "moment-timezone";
import {uploadDocument} from "../../../data/actions/download";
import LocalStorage from "../../../util/localStorage";
import PublicCarrierDocuments from "./tabs/public-carrier-documents";
import LoadInfoTracks from "../../dispatch/load-view/load-sections/load-info-tracks";
import PlusIcon from "@heroicons/react/20/solid/PlusIcon";
import LoadsEventsList from '../../dispatch/load-view/load-sidebar/load-events-list'
import {getUserTimeFormat} from "../../../common/util/util-dates";
import ModalDefault from "../../../common/components/modal/modal-default";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import Buttons from "../../../common/components/buttons";
import NoRecords from "../../../common/components/no-records-found/no-records";
import Card from "../../../common/components/card";
import {LoaderLarge} from "../../../common/components/loader";
import NavResponsive from "../../../common/components/nav-responsive";
import FieldsToHtml from "../../../common/components/fields/fields-to-html"
import {getProp} from "../../../common/util/util-helpers";
import {fieldsToHtml, fillFieldsFromData} from "../../../common/util/util-fields";
import useQuery from "../../../hooks/use-query";
import {DEFAULT_QUERY_LIMIT} from "../../../common/util/util-consts";

export default function PublicCarrierView({translate, match}) {
    /** Store
     ================================================================= */
    const dispatch = useDispatch();
    const resource = useSelector((state) => state.publicResource);
    const isLoading = resource.isLoading;

    /** State
     ================================================================= */
    const [tabs, setTabs] = useState(getTabs());
    const [selects, setSelects] = useState({});
    const [fieldsInfo, setFieldsInfo] = useState(getFields());
    const [fieldsTrack, setFieldsTrack] = useState(getTrackFields(selects));
    const [files, setFiles] = useState([]);
    const [filesToAdd, setFilesToAdd] = useState([]);

    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [isEventsModalOpen, setIsEventsModalOpen] = useState(false);
    const [isTrackModalOpen, setIsTrackModalOpen] = useState(false);

    const [isStateDirty, setIsStateDirty] = useState(false);
    const [isTrackStateDirty, setIsTrackStateDirty] = useState(false);
    const [queryFields] = useQuery(getQueryFields(), "public-carrier-view");


    /** Constants
     ================================================================= */
    const data = getProp(resource, 'data', []);
    const isDocumentUploadAllowed = !!getProp(resource, "data.load/info.CarrierAllowUpload", false);
    const events = getProp(resource, 'data.load/events.list', []);
    const hasEvents = !!events.length;
    const token = match.params.token;

    const mapRef = useRef(null);
    const groupRef = useRef(null);

    /** Data events
     ================================================================= */
    const fetchLookups = async () => {
        let result = await axios.get(
            Env.getApiUrl('api/lookup/public?token=' + token),
            {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }
        )
            .then((response) => {
                return processResponse(response)
            })
            .catch((error) => {
                return processError(error)
            })

        let DocumentTypeID = getProp(result, 'data.DocumentType', []).reduce((memo, it) => {
            memo[it.DocumentTypeID] = it.DocumentType
            return memo
        }, {})
        let StateID = getProp(result, 'data.State', []).reduce((memo, it) => {
            memo[it.StateID] = it.State
            return memo
        }, {})
        let CountryID = getProp(result, 'data.Country', []).reduce((memo, it) => {
            memo[it.CountryID] = it.Country
            return memo
        }, {})
        let CountryCode = getProp(result, 'data.Country', []).reduce((memo, it) => {
            memo[it.CountryID] = it.CountryCode
            return memo
        }, {})

        setSelects({
            DocumentTypeID: DocumentTypeID,
            CountryID: CountryID,
            CountryCode: CountryCode,
            StateID: StateID,
        });
    }

    const submit = () => {
        dispatch(updatePublicResource({
            params: Object.assign(FieldsManager.getFieldKeyValues(fieldsInfo), {
                token: token
            }),
            errorMessage: true,
            successMessage: 'Successfully saved!',
            resource: Resources.LoadShareInfo,
            piggyResource: Resources.LoadCarrierPublic,
            query: {
                token: token
            }
        }));

        let fileIndex = 0;
        if (Object.values(files).find(it => it.isNew)) {
            const filesToUpload = Object.values(files).filter(it => it.isNew);
            let fileMetadata = filesToAdd[fileIndex];

            dispatch(uploadDocument({
                user: LocalStorage.get('user'),
                files: filesToUpload,
                params: {
                    token: token,
                    DocumentTypeID: fileMetadata?.fields?.DocumentTypeID?.value,
                    ExpiryDate: fileMetadata?.fields?.ExpiryDate?.value
                },
                resource: Resources.LoadShareDocuments,
                errorMessage: true, successMessage: `Attachment has been uploaded successfully.`,
            }));

            fileIndex++;
        }
    }

    const handleTrackSubmit = () => {
        const validatedFields = FieldsManager.validateFields(fieldsTrack);

        if (checkFieldsForError(validatedFields)) {
            const params = FieldsManager.getFieldKeyValues(validatedFields);
            delete params.LocationDateTime;

            dispatch(createPublicResource({
                resource: Resources.LoadShareTracks,
                piggyResource: Resources.LoadCarrierPublic,
                query: {
                    token: token
                },
                params: Object.assign(
                    params,
                    {
                        token: token,
                    })
            }))
            setIsTrackModalOpen(false);
        } else {
            setFieldsTrack(Object.assign({}, validatedFields));
        }
    }

    /** UI events
     ================================================================= */
    const handleTabChange = (resource) => {
        setTabs(
            tabs.map((it) => {
                it.current = it.resource === resource;
                return it
            })
        )
    }

    const handleInputChange = (name, value) => {
        const fields = FieldsManager.updateField(fieldsInfo, name, value);
        fields[name].errorMessage = "";

        setFieldsInfo(Object.assign({}, fields));
        setIsStateDirty(true)
    }

    const handleFileInputChange = (name, value, index) => {
        let filesTemp = cloneDeep(filesToAdd);
        filesTemp[index].fields = FieldsManager.updateField(filesTemp[index].fields, name, value);
        filesTemp[index].fields[name].errorMessage = "";
        setFilesToAdd(filesTemp);
    }

    const handleAddDocuments = () => {
        let validatedFilesToAdd = Object.values(filesToAdd).reduce((memo, it) => {
            it.fields = FieldsManager.validateFields(it.fields);
            it.isNew = true;
            memo.push(it);

            return memo;
        }, []);

        let isFormValid = true;

        validatedFilesToAdd.forEach(it => {
            if (!FieldsManager.checkFieldsForErrors(it.fields)) {
                isFormValid = false;
            }
        })

        if (isFormValid) {
            let filesTemp = cloneDeep(files);
            setFiles(filesTemp.concat(cloneDeep(validatedFilesToAdd)));
            setIsStateDirty(true);
            setIsUploadModalOpen(false);
        } else {
            setFilesToAdd(validatedFilesToAdd);
        }
    }

    const handleSelectDocuments = (addedFiles) => {
        addedFiles.map(file => {
            if (!file?.fields) {
                file.fields = getFileFields();
            }

            return file;
        })

        setFilesToAdd(addedFiles);
        setIsUploadModalOpen(true);
    }

    const handleRemoveDocument = (index) => {
        let filesTemp = cloneDeep(files);
        filesTemp.splice(index, 1);
        setFiles(filesTemp);
    }

    const handleRemoveDocumentToAdd = (index) => {
        let filesTemp = cloneDeep(filesToAdd);
        filesTemp.splice(index, 1);
        setFilesToAdd(filesTemp);

        if (!filesTemp.length) {
            setIsUploadModalOpen(false);
        }
    }

    const handleRemoveFile = (index) => {
        let filesTemp = cloneDeep(files);
        filesTemp.splice(index, 1);
        setFiles(filesTemp);
    }

    const handleCancelUpload = () => {
        setIsUploadModalOpen(false);
        setFilesToAdd([]);
    }

    const handleCancelClick = () => {
        setFieldsInfo(getFields(getProp(resource.data, "load/info", {})));
        setIsStateDirty(false);
    }

    const handleTrackInputChange = (name, value) => {
        let newFields = FieldsManager.updateField(fieldsTrack, name, value);

        newFields[name].errorMessage = "";

        setFieldsTrack(Object.assign({}, newFields));
        setIsTrackStateDirty(true);
    }

    const currentTab = tabs.find(tab => tab.current);

    const setLocations = (fieldsTmp) => {
        let updatedFields = dirtyClone(fieldsTrack)
        for (const [key, value] of Object.entries(fieldsTmp)) {
            if (updatedFields[key]) updatedFields = FieldsManager.updateField(updatedFields, key, value)
        }
        setFieldsTrack(updatedFields);
        setIsTrackStateDirty(true);
    }

    function getQueryFields() {
        return {
            offset: new Field('offset', 0, ['']),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {
                labelType: "float",
                hideLabel: true
            }, {menuPlacement: "top"})
        }
    }

    /** Life cycle
     ================================================================= */
    useEffect(() => {
        dispatch(getPublicResource({
            resource: Resources.LoadCarrierPublic,
            query: {
                token: token
            }
        }));

        fetchLookups();
    }, [])


    useEffect(() => {
        if (resource?.data) {
            setFieldsInfo(getFields(getProp(resource.data, "load/info", {})));
            setTabs(getTabs(data, currentTab?.name));
        }

        if (resource?.create?.id) {
            setIsEventsModalOpen(false);
        }
    }, [resource])

    useEffect(() => {
        if (currentTab.name === 'map') {
            const PickupLatitude = Number(getProp(data, 'load/info', 0).PickupLatitude);
            const PickupLongitude = Number(getProp(data, 'load/info', 0).PickupLongitude);
            const DestinationLatitude = Number(getProp(data, 'load/info', []).DestinationLatitude);
            const DestinationLongitude = Number(getProp(data, 'load/info', []).DestinationLongitude);

            handleFitBounds(PickupLatitude, PickupLongitude, DestinationLatitude, DestinationLongitude, mapRef, groupRef);
        }
    }, [currentTab]);


    if (!resource.isLoading && resource.data === null) {
        return <p className="text-center p-8 text-xl">The provided link does not have the view option enabled.</p>
    }

    /** Component Body
     ================================================================= */
    return (
        <React.Fragment>
            <div className="p-4 bg-tm-gray-100 min-h-screen">
                <div className="flex-col justify-between">
                    <div className="max-w-7xl mx-auto">
                        <div className="mb-2">
                            <h1
                                className="mr-5 text-2xl"
                            >
                                {translate('text.Load')}
                            </h1>
                        </div>

                        <NavResponsive
                            tabs={tabs}
                            onTabChange={handleTabChange}
                            translate={translate}
                        />

                        {isLoading && (
                            <Card addClass="h-[calc(100vh-12rem)] mt-8">
                                <LoaderLarge/>
                            </Card>
                        )}

                        {currentTab.name === 'info' && !isLoading && (
                            <PublicCarrierInfo
                                fieldsHtml={fieldsToHtml(Object.values(fieldsInfo), translate, handleInputChange, selects)}
                                data={data}
                                selects={selects}
                                translate={translate}
                            />
                        )}

                        {currentTab.name === 'map' && (
                            <LoadInfoTracks
                                data={data}
                                mapHeaderButtons={[{
                                    className: "shadow relative -ml-px inline-flex items-center rounded-xl border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-tm-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-link]",
                                    iconLeading: PlusIcon,
                                    label: translate("btn.add"),
                                    onClick: () => setIsTrackModalOpen(true)
                                }]}
                                mapRef={mapRef}
                                groupRef={groupRef}
                                queryFields={queryFields}
                                handleFitBounds={handleFitBounds}
                                translate={translate}
                                isPublic={true}
                            />
                        )}

                        {currentTab.name === 'statusUpdates' && !isLoading && (
                            <div className="max-w-xl mx-auto pt-8">
                                <Card>
                                    <header className="flex items-center justify-between">
                                        <div
                                            className="text-lg leading-6 font-medium text-tm-gray-900">
                                            {translate('card_header.events')}
                                        </div>

                                        <button
                                            onClick={() => setIsEventsModalOpen(true)}
                                            className="btn btn-primary py-1"
                                        >
                                            {translate("btn.create")}
                                        </button>
                                    </header>

                                    {hasEvents && (
                                        <LoadsEventsList
                                            events={events}
                                            isLoading={isLoading}
                                            translate={translate}
                                        />
                                    )}

                                    <NoRecords
                                        show={!hasEvents}
                                        addClass={"py-12"}
                                        title={translate("text.load_no_events")}
                                    />
                                </Card>
                            </div>
                        )}

                        {currentTab.name === 'documents' && !isLoading && (
                            <PublicCarrierDocuments
                                onAddDocuments={handleAddDocuments}
                                onCancelUpload={handleCancelUpload}
                                onFileInputChange={handleFileInputChange}
                                onSelectDocuments={handleSelectDocuments}
                                onRemoveDocument={handleRemoveDocument}
                                onRemoveDocumentToAdd={handleRemoveDocumentToAdd}
                                onRemoveFile={handleRemoveFile}
                                isUploadModalOpen={isUploadModalOpen}
                                isDocumentUploadAllowed={isDocumentUploadAllowed}
                                files={files}
                                filesToAdd={filesToAdd}
                                selects={selects}
                                translate={translate}
                            />
                        )}
                    </div>

                    {(currentTab.name === 'info' || currentTab.name === 'documents') && (
                        <footer
                            className="w-full z-40 h-16 flex justify-end items-center gap-3 fixed bottom-0 right-0 px-8 bg-inverse border-tm-gray-300 border-t">
                            <div className="flex space-x-2">
                                <Buttons
                                    buttons={
                                        [{
                                            disabled: !isStateDirty,
                                            type: "primary",
                                            label: translate("btn.save"),
                                            onClick: () => submit()
                                        }, {
                                            disabled: !isStateDirty,
                                            type: "outline",
                                            label: translate("btn.cancel"),
                                            onClick: handleCancelClick
                                        }]
                                    }
                                />
                            </div>
                        </footer>
                    )}
                </div>
            </div>

            <ModalSaveResource
                title={'Create Load Event'}
                className="max-w-lg"
                gridColsClass="grid-cols-3"
                show={isEventsModalOpen}
                onClose={() => setIsEventsModalOpen(false)}
                fields={getEventFields()}
                onSubmit={(params) => {
                    if (params) {
                        params.token = token
                        dispatch(createPublicResource({
                            resource: Resources.LoadShareEvents,
                            piggyResource: Resources.LoadCarrierPublic,
                            query: {
                                token: token
                            },
                            params: params,
                            errorMessage: true,
                            successMessage: 'Event added successfully!'
                        }))
                    }
                }}
                translate={translate}
            />

            <ModalDefault
                show={isTrackModalOpen}
                widthClass={'max-w-2xl z-50'}
                title="Add location"

                onButtonClick={handleTrackSubmit}
                buttonLabel={translate('btn.save')}
                buttonDisabled={!isTrackStateDirty}

                closeButtonLabel={translate('btn.cancel')}
                onClose={() => setIsTrackModalOpen(false)}
            >
                <div className="p-6 grid grid-cols-12 gap-4">

                    {/*<div className="col-span-3">*/}
                    {/*    <FieldContainer*/}
                    {/*        item={fieldsTrack.LocationDate}*/}
                    {/*        translate={translate}*/}
                    {/*    >*/}
                    {/*        <FieldDate*/}
                    {/*            showTimeSelect={false}*/}
                    {/*            addClass={'form-control text-center'}*/}
                    {/*            onChange={handleTrackInputChange}*/}
                    {/*            {...fieldsTrack.LocationDate}*/}
                    {/*        />*/}
                    {/*    </FieldContainer>*/}
                    {/*</div>*/}

                    {/*<div className="col-span-3">*/}
                    {/*    <FieldContainer*/}
                    {/*        item={fieldsTrack.LocationDate}*/}
                    {/*        label={translate('field.LocationDateTime')}*/}
                    {/*        translate={translate}*/}
                    {/*    >*/}
                    {/*        <FieldTime*/}
                    {/*            onChange={handleTrackInputChange}*/}
                    {/*            addClass={'form-control text-center'}*/}
                    {/*            {...fieldsTrack.LocationDate}*/}
                    {/*        />*/}
                    {/*    </FieldContainer>*/}
                    {/*</div>*/}


                    {/*<div className="col-span-full">*/}
                    {/*    <FieldContainer*/}
                    {/*        item={fieldsTrack.Notes}*/}
                    {/*        translate={translate}*/}
                    {/*    >*/}
                    {/*        <label className="form-group has-float-label mb-4">*/}
                    {/*            <FieldTextarea*/}
                    {/*                onChange={handleTrackInputChange} {...fieldsTrack.Notes}*/}
                    {/*                placeholder={''}*/}
                    {/*                addClass={'form-control whitespace-pre-wrap'}/>*/}

                    {/*        </label>*/}
                    {/*    </FieldContainer>*/}
                    {/*</div>*/}

                    {/*<div className="col-span-full">*/}
                    {/*    <div className={'separator mb-3'}/>*/}
                    {/*</div>*/}


                    <FieldsToHtml
                        fieldsState={fieldsTrack}
                        onInputChange={handleTrackInputChange}
                        translate={translate}
                    />
                </div>


                {/*<Manually*/}
                {/*    translate={translate}*/}
                {/*    fields={fieldsTrack}*/}
                {/*    setLocations={setLocations}*/}
                {/*    handleInputChange={handleTrackInputChange}*/}
                {/*    State={selects['State']}*/}
                {/*    Country={selects['Country']}*/}
                {/*    countryCode={selects['countryCode']}*/}
                {/*/>*/}
            </ModalDefault>
        </React.Fragment>
    )
}

const getTabs = (data, currentTabName = undefined) => {
    currentTabName = currentTabName ?? "info";

    const areTracksAllowed = !!getProp(data, 'load/info.CarrierAllowLocation', false);
    const areDocumentsAllowed = !!getProp(data, 'load/info.CarrierAllowUpload', false);
    const CarrierAllowNote = !!getProp(data, 'load/info.CarrierAllowNote', false);

    return [
        {
            name: 'info',
            resource: Resources.LoadInfo,
            current: currentTabName === 'info',
            visible: true
        },
        {
            name: 'map',
            resource: "map",
            current: currentTabName === 'map',
            visible: areTracksAllowed
        }, {
            name: 'statusUpdates',
            resource: "statusUpdates",
            current: currentTabName === 'statusUpdates',
            visible: CarrierAllowNote
        }, {
            name: 'documents',
            resource: Resources.LoadShareDocuments,
            current: currentTabName === 'documents',
            visible: areDocumentsAllowed // or has documents?
        }
    ]
}

const getFields = (item = {}) => {
    let fieldTemplates = {
        DriverNameTxt: new Field('DriverNameTxt', '', [], !item?.CarrierAllowEdit, 'text', {}),
        DriverCellTxt: new Field('DriverCellTxt', '', [], !item?.CarrierAllowEdit, 'text', {}),
        TruckTxt: new Field('TruckTxt', '', [], !item?.CarrierAllowEdit, 'text', {}),
        TrailerTxt: new Field('TrailerTxt', '', [], !item?.CarrierAllowEdit, 'text', {}),
        CarrierReferenceNumber: new Field('CarrierReferenceNumber', '', [], !item?.CarrierAllowEdit, 'text', {}),
    }

    return fillFieldsFromData(fieldTemplates, item)
}

const getFileFields = () => {
    return {
        DocumentTypeID: new Field("DocumentTypeID", '', ['empty'], false, 'select', {addContainerClass: "col-span-8"}),
        Description: new Field("Description", '', ['empty'], false, 'textarea', {addContainerClass: "col-span-full"})
    }
}

const getEventFields = () => {
    return {
        EventKey: new Field('EventKey', '', ['empty'], false, 'text', {
            addContainerClass: 'col-span-full'
        }),
        EventValue: new Field('EventValue', '', ['empty'], false, 'text', {
            addContainerClass: 'col-span-full'
        }),
    }
}

const getTrackFields = (selects) => {
    return {
        LocationDate: new Field('LocationDate', moment().format('YYYY-MM-DD HH:mm:ss'), [], false, 'datetime', {addContainerClass: "col-span-4"}),

        LocationDateTime: new Field('LocationDateTime', moment().format(getUserTimeFormat()), [], false, 'time-custom', {addContainerClass: "col-span-4"}),
        Notes: new Field('Notes', '', [], false, 'textarea', {addContainerClass: "col-span-full"}),
        CountryID: new Field('CountryID', '1', ['empty'], false, 'select', {
            addContainerClass: "col-span-4",
            omitSort: true,
            values: selects['Country'],
            render: (item) => selects['Country']?.[item.CountryID]
        }),
        AddressName: new Field('AddressName', '', ['empty'], false, 'text', {
            addContainerClass: "col-start-1 col-span-full",
            omitSort: true
        }),
        CityName: new Field('CityName', '', ['empty'], false, 'text', {
            addContainerClass: "col-span-4",
            omitSort: true
        }),
        StateID: new Field('StateID', '', [], false, 'select', {
            addContainerClass: "col-span-4",
            omitSort: true,
            values: selects['State'],
            render: (item) => selects['State']?.[item.StateID]
        }),
        PostalCode: new Field('PostalCode', '', ['empty'], false, 'text', {
            addContainerClass: "col-span-4",
            omitSort: true
        }),

    }
}

const handleFitBounds = (startLatitude, startLongitude, endLatitude, endLongitude, mapRef, groupRef) => {
    if (!isNaN(startLatitude) && !isNaN(startLongitude) && !isNaN(endLatitude) && !isNaN(endLongitude) && mapRef.current) {
        const corner1 = L.latLng(startLatitude, startLongitude)
        const corner2 = L.latLng(endLatitude, endLongitude)
        const bounds = L.latLngBounds(corner1, corner2)
        const map = mapRef.current.leafletElement
        map.fitBounds(bounds)
    } else {
        const map = mapRef?.current?.leafletElement
        const group = groupRef?.current?.leafletElement
        if (map && group) {
            map.fitBounds(group.getBounds())
        }
    }
}

const checkFieldsForError = (fieldsToCheck) => {
    let result = true
    Object.keys(fieldsToCheck).forEach(it => {
        if (!fieldsToCheck[it].value && fieldsToCheck[it].validate.includes('empty')) result = false
    })
    return result
}
