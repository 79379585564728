import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {download} from '../../../data/actions/download'
import {
    getDefaultTableOptions,
    getDefaultTableOptionsJSON, getSystemName,
    openInNewTab,
    returnOffsetAndPagination,
    saveTableColumns,
} from '../../../common/util/util-helpers'
import {Field, FieldsManager} from '../../../data/services/fields'
import Tippy from '@tippyjs/react'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DEFAULT_DATABASE_DATETIME_FORMAT,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    DELETE_PERM,
    DRIVER_STATUS_TYPES,
    READ_PERM,
    TRUCK_STATUS_TYPES,
    UPDATE_PERM
} from '../../../util/util-constants'
import {checkPerm, classNames, getLookup, getProp, mergeDeep} from '../../../common/util/util-helpers'
import {currentDate, toFrontDateTimeFromUTC} from '../../../common/util/util-dates'
import {cloneDeep} from '../../../common/util/util-vanilla'
import {
    ArrowPathIcon,
    CalculatorIcon,
    CheckBadgeIcon,
    ClipboardIcon,
    EyeIcon,
    TrashIcon,
    UserGroupIcon
} from "@heroicons/react/24/outline";
import moment from "moment";
import TrucksTableBadge from "../../fleet/trucks-view/trucks-table-badge";
import {ExclamationTriangleIcon} from '@heroicons/react/20/solid'
import GetDispatchManagerFields from "./dispatch-manager-fields";
import {createDialogResource, deleteDialogResource, updateDialogResource} from "../../../data/actions/dialogResource";
import PayDateFilter from "../../accounting/carrier-pay/carrier-pay-date-filter";
import {showGlobalModal, showModal} from "../../../data/actions/ui";
import DriverNotesTab from "../../personnel/driver-single/driverNotesTab";
import LoadPlaningModalBody from '../load-view/load-sections/dialog/load-planing-modal'
import {getInfoResource} from "../../../data/actions/infoResource";
import AssetNotAssignedBadges from "./asset-not-assigned-badges";
import ModalDefault from "../../../common/components/modal/modal-default";
import CopyToClipboardButton from "../../../common/components/buttons/copy-to-clipboard-button";
import NoRecords from "../../../common/components/no-records-found/no-records";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import ResourceTable from "../../../common/components/resource-table";
import Modal from "../../../common/components/modal";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import ContactsTab from "../../../common/components/tabs/contacts-tab";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import Page from "../../../common/components/layout/layout-components/page";
import Layout from "../../../common/components/layout";
import CellButtonWithCopy from "../../../common/components/resource-table/table-components/cell-button-copy";
import DriverStatusBadge from "../../../common/components/badge/driver-status-badge";
import {excludeFields, fillFieldsFromData} from "../../../common/util/util-fields";
import {getSecondResource} from "../../../data/actions/secondResource";
import FleetShareTableMap
    from "../../../common/components/command-center-table/fleet-share-table/fleet-share-table-map";
import CellButton from "../../../common/components/resource-table/table-components/cell-button";
import {DEFAULT_QUERY_LIMIT} from "../../../common/util/util-consts";

const BATCH_TABLE_KEY = "UnitID"

class DispatchManagerView extends Component {
    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1)
        this.tablePageDefaults = {
            behaviour: {
                rowSelect: true,
                canAdjustWidth: true,
                hasMenu: true
            },
            style: {
                condensed: true,
                isGPUAccelerated: true
            }
        }

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: 'CreateUpdateDate',
            trailersSort: 'ASC',
            trailersSortBy: 'Trailer',
            trucksSort: 'ASC',
            trucksSortBy: 'Truck',
            driversSort: 'ASC',
            driversSortBy: 'DriverID',
            queryFilterFields: this.getQueryFilterFields(),
            tableOptions: getDefaultTableOptions(GetDispatchManagerFields(this.props.translate, this.props.dispatch, {}, (item) => this.setMapModalData(item)), this.tablePageDefaults, this.pagePath, this.props.translate),
            loadPreviewData: {},
            createUpdateDialog: false,
            confirmModalOpen: false,
            isDialogConfirmDeleteModalOpen: false,
            isLoadPreviewDialogOpen: false,
            primaryModalShow: false,
            driverNotesModalOpen: false,
            // Batch actions
            selectedItems: {},
            selectedRows: {},
            breakpoint: {},
            notAssignedTrucks: false,
            notAssignedDrivers: false,
            notAssignedTrailers: false,
            isMapModalOpen: false,
            lastLocation: null,
            editUnassignedModalOpen: false,
            selectedNotAssignedItemDriver: null,
            selectedNotAssignedItemTruck: null,
            selectedNotAssignedItemTrailer: null,
            notAssignedDriversDataSorted: {},
            notAssignedTrucksDataSorted: {},
            notAssignedTrailersDataSorted: {}

        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps, prevState) {
        if (!!this.props.dialogResource.create && !prevProps.dialogResource.create) {
            this.setState({
                loadPreviewData: this.props.dialogResource.create
            })
        }

        if ((this.state.driversSortBy !== prevState.driversSortBy) || (this.state.driversSort !== prevState.driversSort)) {
             getProp(this.props.resource.data, 'NotAssigned.Drivers', []).sort(this.sortDriversByColumn)
        }

        if ((this.state.trucksSortBy !== prevState.trucksSortBy) || (this.state.trucksSort !== prevState.trucksSort)) {
            getProp(this.props.resource.data, 'NotAssigned.Trucks', []).sort(this.sortTrucksByColumn)
        }

        if ((this.state.trailersSortBy !== prevState.trailersSortBy) || (this.state.trailersSort !== prevState.trailersSort)) {
            getProp(this.props.resource.data, 'NotAssigned.Trailers', []).sort(this.sortTrailersByColumn)
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    fetchDataInfo = (item) => {
        this.props.dispatch(getInfoResource({
            user: LocalStorage.get('user'),
            resource: Resources.DriverInfo,
            query: {id: item?.DriverID}
        }))
    }

    fetchCoDriverInfo = (id) => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            resource: Resources.DriverInfo,
            query: {id: id}
        }))
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign({format: 'EXCEL', name: 'assignments_' + currentDate() + '.xlsx'}, this.getQuery())
        }))
    }

    handleSetPrimary = (item) => {
        this.props.dispatch(updateDialogResource({
            user: LocalStorage.get('user'),
            params: {ContactInDriverID: item.ContactInDriverID},
            resource: Resources.DriverDispatchPrimary,
            piggyResource: Resources.DriverDispatch,
            query: Object.assign(this.getQuery(), {id: this.state.selectedItem?.DriverID}),
            errorMessage: true,
            successMessage: `Driver ${item.FirstName} ${item.LastName} successfully set as primary contact!`
        }))
    }

    handlePreviewLoadClick = (params) => {
        this.props.dispatch(createDialogResource({
            user: LocalStorage.get('user'),
            params: params,
            resource: Resources.LoadPreview,
        }));
    }

    /** UI Events
     ================================================================= */
    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleToggleDispatchersModal = (item = null) => {
        this.setState({
            selectedItem: item,
            driverDispatchersModalOpen: !this.state.driverDispatchersModalOpen
        })
    }

    handleToggleNotesModal = (item = null) => {
        this.setState({
            selectedItem: item,
            driverNotesModalOpen: !this.state.driverNotesModalOpen
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleToggleEditModal = (selectedItem = null) => {
        this.setState({selectedItem: selectedItem, editModalOpen: !this.state.editModalOpen})
    }

    handleToggleUnassignedEditModalDriver = (selectedNotAssignedItem = null) => {
        this.setState({selectedNotAssignedItemDriver: selectedNotAssignedItem, editUnassignedModalOpen: !this.state.editUnassignedModalOpen})
    }

    handleToggleUnassignedEditModalTruck = (selectedNotAssignedItem = null) => {
        this.setState({selectedNotAssignedItemTruck: selectedNotAssignedItem, editUnassignedModalOpen: !this.state.editUnassignedModalOpen})
    }

    handleToggleUnassignedEditModalTrailer = (selectedNotAssignedItem = null) => {
        this.setState({selectedNotAssignedItemTrailer: selectedNotAssignedItem, editUnassignedModalOpen: !this.state.editUnassignedModalOpen})
    }



    handleToggleViewModal = (selectedItem = null) => {
        this.setState({selectedItem: selectedItem, isViewModalOpen: !this.state.isViewModalOpen})
        this.fetchDataInfo(selectedItem)
        if (selectedItem['CoDriverID']) this.fetchCoDriverInfo(selectedItem['CoDriverID'])
    }

    handleToggleCreateModal = () => {
        this.setState({createModalOpen: !this.state.createModalOpen})
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        let queryFieldsUpdate = FieldsManager.updateField(this.state.queryFilterFields, name, value);

        if (name === 'StartDate') {
            queryFieldsUpdate.EndDate.props.minDate = value
        }

        if (name === 'EndDate') {
            queryFieldsUpdate.StartDate.props.maxDate = value
        }

        this.setState({
            queryFilterFields: queryFieldsUpdate,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            isColumnsDialogVisible: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(GetDispatchManagerFields(this.props.translate, this.props.dispatch, {}, (item) => this.setMapModalData(item)), {}, this.props.translate,);
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        }, () => {
            if (this.state.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
                this.handleFilterInputChange('limit', 10);
            }
        });
    }

    setPrimaryResource = (item) => {
        const text = 'text.confirm_set_primary_contact'
        this.setState({
            confirmText: this.props.translate(text),
            selectedItem: item,
            setPrimaryModalShow: true
        })
    }

    handleShowDetails = (item) => {
        this.props.dispatch(showModal('ViewContactCard', {ContactID: item.ContactID}));
    };

    deleteResource = (item) => {
        if (item.IsPrimary) {
            return
        }

        this.setState({
            selectedItem: item,
            isDialogConfirmDeleteModalOpen: true,
        })
    }

    hideSetPrimaryDialog = () => {
        this.setState({setPrimaryModalShow: false})
    }

    hideDialogConfirmDeleteDialog = () => {
        this.setState({isDialogConfirmDeleteModalOpen: false})
    }

    toggleLoadPreviewDialog = () => {
        this.setState({
            isLoadPreviewDialogOpen: !this.state.isLoadPreviewDialogOpen,
            loadPreviewData: {}
        })
    }

    handleToggleTrucksDialog = () => {
        this.setState({
            notAssignedTrucks: !this.state.notAssignedTrucks
        })
    }

    handleToggleTrailersDialog = () => {
        this.setState({
            notAssignedTrailers: !this.state.notAssignedTrailers
        })
    }

    handleToggleDriversDialog = () => {
        this.setState({
            notAssignedDrivers: !this.state.notAssignedDrivers
        })
    }

    handleTruckClick = (item) => {
        this.props.dispatch(showGlobalModal('ViewTruckCard', item.TruckID))
    }

    handleTrailerClick = (item) => {
        this.props.dispatch(showGlobalModal('ViewTrailerCard', item.TrailerID))
    }
    handleDriverClick = (item) => {
        this.props.dispatch(showGlobalModal('ViewDriverCard', item.DriverID))
    }

    /** Helpers
     ================================================================= */
    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'limit', 'query', 'offset', 'paginationPage'])
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            DriverID: new Field('DriverID', '', [], false, 'select-search', {}),
            DriverStatusID: new Field('DriverStatusID', '', [], false, 'select', {}),
            TruckID: new Field('TruckID', '', [], false, 'select-search', {}),
            TruckStatusID: new Field('TruckStatusID', '', [], false, 'select', {}),
            TrailerID: new Field('TrailerID', '', [], false, 'select-search', {}),
            TrailerStatusID: new Field('TrailerStatusID', '', [], false, 'select', {}),
            TrailerTypeID: new Field('TrailerTypeID', '', [], false, 'select', {}),
            IsTeamDriver: new Field('IsTeamDriver', '', [], false, 'checkbox', {}),
            StatusMismatch: new Field('StatusMismatch', '', [], false, 'checkbox', {}),
            DispatchContactID: new Field('DispatchContactID', '', [], false, 'select-search', {}, {menuPlacement: "top"}),
            StartDate: new Field('StartDate', '', [], false, 'date', {}, {}),
            EndDate: new Field('EndDate', '', [], false, 'date', {}, {}),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {
                hideLabel: true,
                labelType: 'float'
            }, {menuPlacement: "top"})
        }
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSortTrucks = (sortBy) => {
        this.setState({
            trucksSortBy: sortBy,
            trucksSort: (this.state.trucksSortBy === sortBy) ? (this.state.trucksSort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        })
    }

    sortTrucksByColumn = (a, b) => {
        if (this.state.trucksSort === 'ASC') {
            return a[this.state.trucksSortBy] > b[this.state.trucksSortBy] ? 1 : -1
        } else {
            return a[this.state.trucksSortBy] < b[this.state.trucksSortBy] ? 1 : -1
        }
    }

    handleUpdateSortTrailers = (sortBy) => {
        this.setState({
            trailersSortBy: sortBy,
            trailersSort: (this.state.trailersSortBy === sortBy) ? (this.state.trailersSort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        })
    }

    sortTrailersByColumn = (a, b) => {
        if (this.state.trailersSort === 'ASC') {
            return a[this.state.trailersSortBy] > b[this.state.trailersSortBy] ? 1 : -1
        } else {
            return a[this.state.trailersSortBy] < b[this.state.trailersSortBy] ? 1 : -1
        }
    }

    handleUpdateSortDrivers = (sortBy) => {
        this.setState({
            driversSortBy: sortBy,
            driversSort: (this.state.driversSortBy === sortBy) ? (this.state.driversSort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        })
    }

    sortDriversByColumn = (a, b) => {
        if (this.state.driversSort === 'ASC') {
            return a[this.state.driversSortBy] > b[this.state.driversSortBy] ? 1 : -1
        } else {
            return a[this.state.driversSortBy] < b[this.state.driversSortBy] ? 1 : -1
        }
    }

    setMapModalData = (item) => {
        this.setState({isMapModalOpen: true, lastLocation: item})
    }


    getTrucksFields = () => {
        return {
            Truck: new Field('Truck', '', [], false, 'text', {
                label: "Number",
                isColumnFrozen: true,
                render: (it) => <CellButtonWithCopy
                    onClick={() => this.props.dispatch(showGlobalModal('ViewTruckCard', it.TruckID))}
                    copyText={it.Truck}
                    translate={this.props.translate}
                >
                    {it.Truck}
                </CellButtonWithCopy>
            }),
            StatusID: new Field('StatusID', '', [''], false, 'custom', {
                render: (item) => (
                    <div className={"flex space-x-2 items-center"}>
                        <TrucksTableBadge
                            item={item}
                            translate={this.props.translate}
                        />
                    </div>
                )
            }),
            TruckLocation: new Field('TruckLocation', '', [''], false, 'text', {label: 'Location'}),
            TruckGPSLocation: new Field('TruckGPSLocation', '', [''], false, 'custom', {
                render: (item) => {
                    if (item.LocationDate) {
                        const LocationDate = moment(item.LocationDate);
                        const currentDate = moment();

                        const dateDifference = moment.duration(LocationDate.diff(currentDate)).asDays();

                        return (
                            <CellButton
                                onClick={() => {
                                    this.setMapModalData(item);
                                }}
                            >
                                <Tippy content={this.props.translate("text.GPS_older_than_7_days")}
                                       disabled={dateDifference > -7}>
                                    <div
                                        className={classNames("text-left", (dateDifference < -7) ? "text-red-500" : 'text-primary')}>
                                        {item?.CityName || item?.State || item?.PostalCode ?
                                            <div>{item?.CityName ? item.CityName + ", " : ""} {item?.GPSState} {item?.PostalCode}</div> : ""}
                                        {(item.LocationDate ?
                                            <div>({toFrontDateTimeFromUTC(item.LocationDate)})</div> : undefined)}
                                    </div>
                                </Tippy>
                            </CellButton>
                        )
                    } else {
                        return (<div className="flex items-center">No data</div>)
                    }
                }, omitSort: true
            }),
            TruckOwnershipType: new Field('TruckOwnershipType', '', [''], false, "text"),
            AssetCompanyStatusID: new Field('AssetCompanyStatusID', '', [], false, 'select'),
            AssignmentNotes: new Field('AssignmentNotes', '', [''], false, 'text', {
                render: (it) => <CellButtonWithCopy
                    copyText={it.AssignmentNotes}
                    translate={this.props.translate}
                    addClass="mr-8"
                >
                    <Tippy content={<div className="whitespace-pre-wrap">{it.AssignmentNotes}</div>}
                           disabled={!it.AssignmentNotes}>
                        <span className="truncate max-w-sm">{it.AssignmentNotes}</span>
                    </Tippy>
                </CellButtonWithCopy>
            }),
            TruckUtilizationStatus: new Field('TruckUtilizationStatus', '', [], false, 'text', {}, {isClearable: true}),
            TruckUtilizationNotes: new Field('TruckUtilizationNotes', '', [''], false, 'text', {
                render: (it) => <CellButtonWithCopy
                    copyText={it.TruckUtilizationNotes}
                    translate={this.props.translate}
                    addClass="mr-8"
                >
                    <Tippy content={<div className="whitespace-pre-wrap">{it.TruckUtilizationNotes}</div>}
                           disabled={!it.TruckUtilizationNotes}>
                        <span className="truncate max-w-sm">{it.TruckUtilizationNotes}</span>
                    </Tippy>
                </CellButtonWithCopy>
            }),
        }
    }

    getTrailersFields = () => {
        return {
            Trailer: new Field('Trailer', '', [], false, 'text', {
                label: "Number",
                isColumnFrozen: true,
                render: (it) => <CellButtonWithCopy
                    onClick={() => this.props.dispatch(showGlobalModal('ViewTrailerCard', it.TrailerID))}
                    copyText={it.Trailer}
                    translate={this.props.translate}
                >
                    {it.Trailer}
                </CellButtonWithCopy>
            }),
            StatusID: new Field('StatusID', '', [''], false, 'custom', {
                render: (item) => (
                    <div className={"flex space-x-2 items-center"}>
                        <TrucksTableBadge
                            item={item}
                            translate={this.props.translate}
                        />
                    </div>
                )
            }),
            TrailerLocation: new Field('TrailerLocation', '', [''], false, 'text', {label: 'Location'}),
            TrailerGPSLocation: new Field('TrailerGPSLocation', '', [''], false, 'custom', {
                render: (item) => {
                    if (item.LocationDate) {
                        const LocationDate = moment(item.LocationDate);
                        const currentDate = moment();

                        const dateDifference = moment.duration(LocationDate.diff(currentDate)).asDays();

                        return (
                            <CellButton
                                onClick={() => {
                                    this.setMapModalData(item);
                                }}
                            >
                                <Tippy content={this.props.translate("text.GPS_older_than_7_days")}
                                       disabled={dateDifference > -7}>
                                    <div
                                        className={classNames("text-left", (dateDifference < -7) ? "text-red-500" : 'text-primary')}>
                                        {item?.CityName || item?.State || item?.PostalCode ?
                                            <div>{item?.CityName ? item.CityName + ", " : ""} {item?.GPSState} {item?.PostalCode}</div> : ""}
                                        {(item.LocationDate ?
                                            <div>({toFrontDateTimeFromUTC(item.LocationDate)})</div> : undefined)}
                                    </div>
                                </Tippy>
                            </CellButton>
                        )
                    } else {
                        return (<div className="flex items-center">No data</div>)
                    }
                }, omitSort: true
            }),
            TrailerOwnershipType: new Field('TrailerOwnershipType', '', [''], false, "text"),
            AssetCompanyStatusID: new Field('AssetCompanyStatusID', '', [], false, 'select'),
            AssignmentNotes: new Field('AssignmentNotes', '', [''], false, 'text', {
                render: (it) => <CellButtonWithCopy
                    copyText={it.AssignmentNotes}
                    translate={this.props.translate}
                    addClass="mr-8"
                >
                    <Tippy content={<div className="whitespace-pre-wrap">{it.AssignmentNotes}</div>}
                           disabled={!it.AssignmentNotes}>
                        <span className="truncate max-w-sm">{it.AssignmentNotes}</span>
                    </Tippy>
                </CellButtonWithCopy>
            }),
            TrailerUtilizationStatus: new Field('TrailerUtilizationStatus', '', [], false, 'text', {}, {isClearable: true}),
            TrailerUtilizationNotes: new Field('TrailerUtilizationNotes', '', [''], false, 'text', {
                render: (it) => <CellButtonWithCopy
                    copyText={it.TrailerUtilizationNotes}
                    translate={this.props.translate}
                    addClass="mr-8"
                >
                    <Tippy content={<div className="whitespace-pre-wrap">{it.TrailerUtilizationNotes}</div>}
                           disabled={!it.TrailerUtilizationNotes}>
                        <span className="truncate max-w-sm">{it.TrailerUtilizationNotes}</span>
                    </Tippy>
                </CellButtonWithCopy>
            }),
        }
    }

    getDriverFields = () => {
        return {
            DriverID: new Field('DriverID', '', [], false, 'select-search', {
                isColumnFrozen: true,
                render: (it) => <CellButtonWithCopy
                    onClick={() => this.props.dispatch(showGlobalModal('ViewDriverCard', it.DriverID))}
                    copyText={it.Driver}
                    translate={this.props.translate}
                >
                    {it.Driver}
                </CellButtonWithCopy>
            }),
            StatusID: new Field('StatusID', '', [''], false, 'custom', {
                render: (item) => (
                    <div className={"flex space-x-2 items-center"}>
                        <DriverStatusBadge
                            item={item}
                            translate={this.props.translate}
                        />
                    </div>
                )
            }),
            DriverPositionType: new Field('DriverPositionType', '', [], false, ''),
            DriverAssignmentStatusID: new Field('DriverAssignmentStatusID', '', [], false, 'select'),
            AssignmentNotes: new Field('AssignmentNotes', '', [''], false, 'text', {
                render: (it) => <CellButtonWithCopy
                    copyText={it.AssignmentNotes}
                    translate={this.props.translate}
                    addClass="mr-8"
                >
                    <Tippy content={<div className="whitespace-pre-wrap">{it.AssignmentNotes}</div>}
                           disabled={!it.AssignmentNotes}>
                        <span className="truncate max-w-sm">{it.AssignmentNotes}</span>
                    </Tippy>
                </CellButtonWithCopy>
            })
        }
    }

    getFieldsUnassignedEdit = (item = null) => {
        if (this.state.notAssignedDrivers) {
            item = this.state.selectedNotAssignedItemDriver
        } else if (this.state.notAssignedTrucks) {
            item = this.state.selectedNotAssignedItemTruck
        } else if (this.state.notAssignedTrailers) {
            item = this.state.selectedNotAssignedItemTrailer
        }

        const fieldTemplates = {
            ...(this.state.notAssignedDrivers && {
                DriverAssignmentStatusID: new Field('DriverAssignmentStatusID', '', [], false, 'select', {addContainerClass: "col-span-full"}),
                AssignmentNotes: new Field('AssignmentNotes', '', [''], false, 'textarea', {addContainerClass: "col-span-full"})
            }),
            ...(this.state.notAssignedTrucks && {
                AssetCompanyStatusID: new Field('AssetCompanyStatusID', '', [], false, 'select', {addContainerClass: "col-span-full"}),
                AssignmentNotes: new Field('AssignmentNotes', '', [''], false, 'textarea', {addContainerClass: "col-span-full"}),
                TruckUtilizationStatusID: new Field('TruckUtilizationStatusID', '', [], false, 'select', {addContainerClass: "col-span-full"}, {isClearable: true}),
                TruckUtilizationNotes: new Field('TruckUtilizationNotes', '', [''], false, 'textarea', {addContainerClass: "col-span-full"})
            }),
            ...(this.state.notAssignedTrailers && {
                AssetCompanyStatusID: new Field('AssetCompanyStatusID', '', [], false, 'select', {addContainerClass: "col-span-full"}),
                AssignmentNotes: new Field('AssignmentNotes', '', [''], false, 'textarea', {addContainerClass: "col-span-full"}),
                TrailerUtilizationStatusID: new Field('TrailerUtilizationStatusID', '', [], false, 'select', {addContainerClass: "col-span-full"}, {isClearable: true}),
                TrailerUtilizationNotes: new Field('TrailerUtilizationNotes', '', [''], false, 'textarea', {addContainerClass: "col-span-full"})

            })
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getFields = (item = null) => {
        const containerClass = 'col-span-full sm:col-span-6 lg:col-span-3'

        const fieldTemplates = {
            DriverID: new Field('DriverID', '', ['empty'], false, 'select-search', {
                addContainerClass: containerClass,
                render: (item) => {
                    if (!item) return null;

                    return (
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                checkPerm(Resources.DriverInfo, UPDATE_PERM) && openInNewTab(`/drivers/info/${item.DriverID}`);
                            }}
                            className="btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
                        >
                            {item.Driver}
                        </button>
                    )
                }
            }),
            DriverStatusID: new Field('DriverStatusID', '', [''], false, 'select', {
                addContainerClass: containerClass,
                render: (item) => (
                    <div className={"flex space-x-2 items-center"}>
                        <DriverStatusBadge
                            item={Object.assign({}, item, {
                                DriverStatusID: item.DriverStatusID
                            })}
                            translate={this.props.translate}
                        />
                        {item.TruckStatusID != item.DriverStatusID && (
                            <Tippy
                                content={<span>{this.props.translate("txt.unit_status_missmatch", [
                                    this.props.translate(DRIVER_STATUS_TYPES[item.DriverStatusID]),
                                    this.props.translate(TRUCK_STATUS_TYPES[item.TruckStatusID])
                                ])}</span>}
                            >
                                <ExclamationTriangleIcon className="w-5 h-5 mr-2 text-red-600"/>
                            </Tippy>
                        )}
                    </div>
                ),
                hideDialog: true
            }, {
                isClearable: true,
                values: DRIVER_STATUS_TYPES
            }),
            CoDriverID: new Field('CoDriverID', '', [], false, 'select-search', {
                addContainerClass: containerClass,
                render: (item) => {
                    if (!item.CoDriverID) {
                        return null;
                    }

                    return (
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                checkPerm(Resources.DriverInfo, UPDATE_PERM) && openInNewTab(`/drivers/info/${item.CoDriverID}`);
                            }}
                            className="btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
                        >
                            {item.CoDriver}
                        </button>
                    )
                }
            }),
            TruckID: new Field('TruckID', '', ['empty'], false, 'select-search', {
                addContainerClass: containerClass,
                render: (item) => {
                    if (!item.TruckID) {
                        return null;
                    }

                    return (
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                checkPerm(Resources.Trucks, UPDATE_PERM) && openInNewTab(`/trucks/info/${item.TruckID}`);
                            }}
                            className="btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
                        >
                            {item.Truck}
                        </button>
                    )
                }
            }),
            TruckStatusID: new Field('TruckStatusID', '', [''], false, 'select', {
                addContainerClass: containerClass,
                render: (item) => (
                    <div className={"flex space-x-2 items-center"}>
                        <TrucksTableBadge
                            item={Object.assign({}, item, {
                                TruckStatusID: item.TruckStatusID
                            })}
                            translate={this.props.translate}
                        />
                        {item.TruckStatusID != item.DriverStatusID && (
                            <Tippy
                                content={<span>{this.props.translate("txt.unit_status_missmatch", [
                                    this.props.translate(DRIVER_STATUS_TYPES[item.DriverStatusID]),
                                    this.props.translate(TRUCK_STATUS_TYPES[item.TruckStatusID])
                                ])}</span>}
                            >
                                <ExclamationTriangleIcon className="w-5 h-5 mr-2 text-red-600"/>
                            </Tippy>
                        )}
                    </div>
                ),
                hideDialog: true
            }, {
                isClearable: true,
                values: TRUCK_STATUS_TYPES
            }),
            TrailerID: new Field('TrailerID', '', [], false, 'select-search', {
                addContainerClass: containerClass,
                render: (item) => {
                    if (!item.TrailerID) {
                        return null;
                    }

                    return (
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                checkPerm(Resources.Trailers, UPDATE_PERM) && openInNewTab(`/trailers/info/${item.TrailerID}`);
                            }}
                            className="btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
                        >
                            {item.Trailer}
                        </button>
                    )
                }
            }),
            Notes: new Field('Notes', '', [''], false, 'textarea', {
                addContainerClass: "col-span-full",
                label: "UnitNotes"
            }),
            ContactID: new Field('ContactID', '', [''], false, 'select-search', {
                addContainerClass: "col-span-full",
                label: "SetPrimaryDispatcher"
            }),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getResource = () => {
        return Resources.Units
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource, secondResource} = this.props

        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)
        const isLoading = getProp(resource, 'isLoading', false)
        const notAssignedDriversData = getProp(resource.data, 'NotAssigned.Drivers', [])
        const notAssignedTrucksData = getProp(resource.data, 'NotAssigned.Trucks', [])
        const notAssignedTrailersData = getProp(resource.data, 'NotAssigned.Trailers', [])
        const infoData = getProp(this.props, "info.data", []);
        const infoLoading = getProp(this.props, 'info.isLoading', false)
        const coDriverData = getProp(secondResource, 'data', {})

        const selectedItem = this.state.selectedItem;

        const unitInfo =
            (selectedItem?.Driver ?
                ("Driver Information:"
                    + "\n - Name: " + selectedItem?.Driver)
                + "\n - Phone: " + (infoData?.Phone ?? "/")
                : "")
            +
            (selectedItem?.CoDriver !== " " ?
                ("\nCoDriver Information:"
                    + "\n - Name: " + coDriverData['Driver'])
                + "\n - Phone: " + (coDriverData['Phone'] ?? "/")
                : "")
            +
            (selectedItem?.Truck ?
                ("\nTruck Details:"
                    + "\n - Truck Number: " + selectedItem?.Truck)
                + "\n - License Plate: " + (selectedItem?.TruckLicenceNumber ?? "/")
                : "")
            +
            (selectedItem?.Trailer ?
                ("\nTrailer Details:"
                    + "\n - Trailer Number: " + selectedItem?.Trailer)
                + "\n - License Plate: " + (selectedItem?.TrailerLicenceNumber ?? "/")
                : "")

        const metadata = {
            DriverID: {
                api: 'api/' + Resources.DriversQuick,
                query: Object.assign({}, DEFAULT_METADATA_SELECT_SEARCH_QUERY(), {}),
                searchMap: (item) => ({
                    value: item.DriverID,
                    label: item.FirstName + ' ' + item.LastName
                })
            },
            CoDriverID: {
                api: 'api/' + Resources.DriversQuick,
                query: Object.assign({}, DEFAULT_METADATA_SELECT_SEARCH_QUERY(), {}),
                searchMap: (item) => ({
                    value: item.DriverID,
                    label: item.FirstName + ' ' + item.LastName
                })
            },
            TruckID: {
                api: 'api/' + Resources.TrucksQuick,
                query: Object.assign({}, DEFAULT_METADATA_SELECT_SEARCH_QUERY(), {}),
                searchMap: (item) => ({
                    value: item.TruckID,
                    label: item.Truck
                })
            },
            TrailerID: {
                api: 'api/' + Resources.TrailersQuick,
                query: Object.assign({}, {
                    limit: 20,
                    offset: 0
                }, {}),
                searchMap: (item) => ({
                    value: item.TrailerID,
                    label: item.Trailer
                })
            },
            TrailerTypeID: getLookup("TrailerType"),
            DispatchContactID: {
                api: "api/" + Resources.ContactsQuick,
                query: Object.assign({}, DEFAULT_METADATA_SELECT_SEARCH_QUERY(), {IsDispatcher: 1}),
                searchMap: (item) => ({
                    value: item.ContactID,
                    label: item.FirstName + " " + item.LastName,
                    Contact: item
                })
            },
            DriverStatusID: DRIVER_STATUS_TYPES,
            TruckStatusID: TRUCK_STATUS_TYPES,
            TrailerStatusID: TRUCK_STATUS_TYPES,
            ContactID: {
                api: 'api/' + Resources.ContactsQuick,
                query: Object.assign({}, DEFAULT_METADATA_SELECT_SEARCH_QUERY(), {IsSystemUser: 1}),
                searchMap: (item) => ({
                    value: item.ContactID,
                    label: item.FirstName + ' ' + item.LastName
                })
            },
        }

        return (
            <Layout
                onBreakpointChange={this.handleBreakpointChange}
                isAccessible={!(resource.errorStatus === 2)}
                {...this.props}
            >
                <Page>
                    <PageHeader
                        title={translate('page.heading.Assignments')}
                        buttonLabel={translate('btn.create_new')}
                        onButtonClick={this.handleToggleCreateModal}
                        hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                        afterTitle={(
                            <>
                                <PageHeaderInfo
                                    dispatch={this.props.dispatch}
                                />
                            </>
                        )}
                    >

                        {checkPerm(Resources.LoadPreview, CREATE_PERM) && (
                            <Tippy
                                content={translate('btn.plan_load')}
                                delay={[400, 0]}
                                trigger="mouseenter"
                            >
                                <button
                                    className={
                                        classNames(
                                            this.state.areStatsVisible ? 'text-primary border-primary' : undefined,
                                            'btn btn-header'
                                        )
                                    }
                                    onClick={this.toggleLoadPreviewDialog}
                                >
                                    <CalculatorIcon
                                        className="w-5 h-5"
                                    />
                                </button>
                            </Tippy>
                        )}
                    </PageHeader>

                    <AssetNotAssignedBadges
                        isLoading={isLoading}
                        notAssignedDriversData={notAssignedDriversData}
                        notAssignedTrucksData={notAssignedTrucksData}
                        notAssignedTrailersData={notAssignedTrailersData}
                        onToggleDriversDialogClick={this.handleToggleDriversDialog}
                        onToggleTrucksDialogClick={this.handleToggleTrucksDialog}
                        onToggleTrailersDialogClick={this.handleToggleTrailersDialog}
                        translate={translate}
                    />

                    <div className="sm:flex">
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleClearFiltersClick}
                            translate={translate}
                        />

                        <div className="ml-auto flex sm:justify-start justify-end items-center">
                            <Tippy content={translate('text.download_excel')}>
                                <button
                                    className="btn-icon"
                                    onClick={this.downloadExcel}
                                >
                                    <DocumentArrowDownIcon className="w-5 h-5"/>
                                </button>
                            </Tippy>

                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>
                    </div>

                    <TableCard addClass={'relative'}>
                        <TableFilters
                            hideLimit
                            filterFields={excludeFields(this.state.queryFilterFields, ['StartDate', 'EndDate', 'DateType'])}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                            onRefreshTable={this.fetchData}
                            forceDialog={true}
                            selects={metadata}
                            isLoading={resource.isLoading}

                            customHtml={
                                <PayDateFilter
                                    queryFields={this.state.queryFilterFields}
                                    translate={translate}
                                    updateQueryFields={(queryFieldsUpdate) => {
                                        this.setState({
                                            queryFilterFields: queryFieldsUpdate,
                                            offset: 0
                                        }, () => this.fetchData())
                                        this.saveFilters(queryFieldsUpdate)
                                    }}
                                    onQueryChange={this.handleFilterInputChange}
                                    hasDateType={false}
                                    isDateTypeClearable={false}
                                />
                            }
                        />

                        <ResourceTable
                            data={data}
                            fields={GetDispatchManagerFields(this.props.translate, this.props.dispatch, {}, (item) => this.setMapModalData(item))}
                            verticalTableIsVisible={this.state.breakpoint.index <= 1}

                            translate={translate}
                            isLoading={resource.isLoading}

                            options={this.state.tableOptions}

                            limit={this.state.queryFilterFields.limit.value}
                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}
                            saveTableOptions={this.setOptions}

                            onRowClick={checkPerm(Resources.DriverInfo, READ_PERM) ? this.handleToggleViewModal : null}
                            onEdit={checkPerm(this.getResource(), UPDATE_PERM) ? this.handleToggleEditModal : null}
                            onDelete={checkPerm(this.getResource(), DELETE_PERM) ? this.handleToggleConfirmModal : null}

                            hasViewPerm={checkPerm(Resources.DriverInfo, READ_PERM)}
                            hasEditPerm={checkPerm(this.getResource(), UPDATE_PERM)}
                            hasDeletePerm={checkPerm(this.getResource(), DELETE_PERM)}

                            actions={[
                                {
                                    action: this.handleToggleViewModal,
                                    icon: EyeIcon,
                                    hasPerm: checkPerm(Resources.DriverInfo, READ_PERM),
                                    label: false, // make this a function
                                    title: translate('btn.view'),
                                    disabled: false,
                                    class: false,
                                    iconClass: false,
                                    order: 10
                                },
                                {
                                    action: this.handleToggleDispatchersModal,
                                    icon: UserGroupIcon,
                                    visible: (item) => !!item.DriverID,
                                    hasPerm: checkPerm(Resources.DriverDispatch, READ_PERM),
                                    label: false,
                                    title: translate('btn.driver_dispatchers'),
                                    disabled: false,
                                    class: false,
                                    iconClass: false
                                },
                                {
                                    action: this.handleToggleNotesModal,
                                    icon: ClipboardIcon, // make this a function
                                    hasPerm: checkPerm(Resources.DriverNotes, READ_PERM),
                                    title: translate('btn.driver_notes'),
                                    disabled: false,
                                    class: false,
                                    iconClass: false
                                }
                            ]}

                            // onSelectRow={this.handleSelectRowClick}
                            // selectedRows={this.state.selectedRows}
                            // onSelectAllClick={this.handleSelectAllClick}
                            tableKey={BATCH_TABLE_KEY}
                        />

                        {/*Table footer*/}
                        {!(!data.length && !resource.isLoading) && (
                            <TableCardFooter>
                                <Pagination
                                    count={count}
                                    isLoading={resource.isLoading}
                                    hideRowsPerPage={this.state.breakpoint.index <= 1}
                                    handleQueryChange={
                                        (name, value, currentPage) => name === "offset"
                                            ? this.handleUpdateOffset(value, currentPage)
                                            : this.handleFilterInputChange(name, value)
                                    }
                                    pageOffset={this.state.offset}
                                    queryFields={this.state.queryFilterFields}
                                    translate={translate}
                                />
                            </TableCardFooter>
                        )}

                        <NoRecordsTable
                            show={(data.length === 0) && !resource.isLoading}
                            title={'No matching records found'}

                            onClearFilterClick={this.handleClearFiltersClick}
                            clearFilterBtnLabel={translate('text.clear_all_filters')}
                            clearFilterText={translate('text.try_without_filters')}
                            filters={this.state.queryFilterFields}
                            className={"pb-12 pt-16 px-6"}
                        />
                    </TableCard>
                </Page>

                <ModalDefault
                    title={translate("modal_heading.driver_dispatchers")}
                    show={this.state.driverDispatchersModalOpen}
                    widthClass={"max-w-6xl"}
                    limitHeight={true}

                    closeButtonLabel={translate("btn.cancel")}
                    onClose={this.handleToggleDispatchersModal}
                    addClass={"p-3"}
                >
                    <ContactsTab
                        {...this.props}
                        id={this.state.selectedItem?.DriverID}
                        resourceName={Resources.DriverDispatch}
                        resourceImagePath={Resources.ContactImage}
                        organizationID={this.state.selectedItem?.DriverID}

                        hideDefaultDeleteAction={true}
                        hideDefaultViewAction={true}
                        customActions={[
                            {
                                action: this.setPrimaryResource,
                                icon: CheckBadgeIcon,
                                hasPerm: checkPerm(Resources.DriverDispatchPrimary, UPDATE_PERM),
                                visible: (item) => !item.IsPrimary,
                                title: translate('btn.set_contact_as_primary'),

                            },
                            {
                                action: this.handleShowDetails,
                                icon: EyeIcon,
                                hasPerm: checkPerm(Resources.Contacts, READ_PERM),
                                title: translate('btn.view')
                            },
                            {
                                action: this.deleteResource,
                                customContent: (item) => <TrashIcon
                                    className={classNames("h-4 w-4", item.IsPrimary ? "text-tm-gray-400" : "text-tm-gray-600")}
                                />,
                                hasPerm: checkPerm(Resources.Contacts, DELETE_PERM),
                                title: (item) => item.IsPrimary ? translate("text.primary_contact_delete") : translate("btn.delete")
                            },
                        ]}

                        primaryKey={'ContactInDriverID'}
                        disableMemberOfOrganization
                        disableContactCreate
                        additionalQuery={{
                            IsEmployee: 1
                        }}
                        systemUserQuery={{
                            IsDispatcher: 1
                        }}
                        additionalFields={{
                            SetDate: new Field('SetDate', moment().format(DEFAULT_DATABASE_DATETIME_FORMAT), ['empty'], false, 'date', {addContainerClass: 'relative w-40'}),
                        }}
                        queryFilterFields={{
                            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true, labelType: "float"})
                        }}
                        fields={{
                            FirstName: new Field('FirstName', '', [], false, 'custom', {
                                columnName: 'DispatcherName',
                                render: (item) => item.FirstName + ' ' + item.LastName
                            }),
                            Email: new Field('Email', '', []),
                            Notes: new Field('Notes', '', []),
                            IsPrimary: new Field('IsPrimary', '', [], false, 'checkbox')
                        }}
                        selectableCollumn
                        dialogTableSelectFields={{
                            FirstName: new Field('FirstName', '', [], false, 'custom', {
                                columnName: 'DispatcherName',
                                render: (item) => item.FirstName + ' ' + item.LastName
                            }),
                            Email: new Field('Email', '', []),
                        }}
                    />
                </ModalDefault>

                <ModalDefault
                    title={translate("modal_heading.Notes_for", [this.state.selectedItem?.Driver])}
                    show={this.state.driverNotesModalOpen}
                    widthClass={"max-w-6xl"}
                    limitHeight={true}

                    closeButtonLabel={translate("btn.cancel")}
                    onClose={this.handleToggleNotesModal}
                >
                    <DriverNotesTab
                        id={this.state.selectedItem?.DriverID}
                        secondResource={this.props.secondResource}
                        translate={translate}
                        dispatch={this.props.dispatch}
                        resourceName={Resources.DriverNotes}
                        titleVisible={false}
                    />
                </ModalDefault>

                <ModalSaveResource
                    title={translate("text.UpdateAssignment")}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-6"
                    show={!!this.state.editModalOpen}
                    onClose={() => this.handleToggleEditModal()}
                    fields={excludeFields(GetDispatchManagerFields(this.props.translate, this.props.dispatch, this.state.selectedItem), ['TrailerType'])}
                    onSubmit={(params) => {
                        if (params) {
                            params.UnitID = this.state.selectedItem.UnitID
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResource(),
                                piggyResource: this.getResource(),
                                errorMessage: true,
                                successMessage: "Unit updated.",
                            }));
                            this.handleToggleEditModal()
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadata}
                />

                <ModalSaveResource
                    title={translate("text.CreateAssignment")}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-6"
                    show={this.state.createModalOpen}
                    onClose={() => this.handleToggleCreateModal()}
                    fields={this.getFields()}
                    onSubmit={(params) => {
                        if (params) {
                            this.props.dispatch(createResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResource(),
                                piggyResource: this.getResource(),
                                errorMessage: true, successMessage: "Unit created.",
                            }));
                            this.handleToggleCreateModal()
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadata}
                />

                <ModalConfirm
                    title={'Confirm delete'}
                    show={!!this.state.confirmModalOpen}
                    text={'Are you sure you want to delete this unit?'}
                    onClose={this.handleToggleConfirmModal}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={() => {
                        this.setState({
                            ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                        }, () => {
                            this.props.dispatch(deleteResource({
                                user: LocalStorage.get('user'),
                                query: {UnitID: this.state.selectedItem.UnitID},
                                piggyQuery: this.getQuery(),
                                errorMessage: true, successMessage: `Unit deleted`,
                                resource: this.getResource(),
                                piggyResource: this.getResource()
                            }))
                            this.handleToggleConfirmModal()
                        })
                    }}
                />

                <ModalConfirm
                    type={"info"}
                    title={translate("text.Confirm")}
                    show={!!this.state.setPrimaryModalShow}
                    text={this.state.confirmText}
                    onClose={this.hideSetPrimaryDialog}
                    buttonLabel={translate("btn.confirm")}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={() => {
                        this.handleSetPrimary(this.state.selectedItem)
                        this.hideSetPrimaryDialog()
                    }}
                />

                <ModalConfirm
                    type={"info"}
                    title={translate("text.Confirm")}
                    show={this.state.isDialogConfirmDeleteModalOpen}
                    text={translate("message.are_you_sure_delete_item")}
                    onClose={this.hideDialogConfirmDeleteDialog}
                    buttonLabel={translate("btn.confirm")}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={() => {
                        const item = this.state.selectedItem
                        const query = {
                            id: item.DriverID,
                            IsEmployee: 1,
                            sort: "ASC",
                            offset: 0,
                            limit: 10
                        }

                        this.props.dispatch(deleteDialogResource({
                            user: LocalStorage.get('user'),
                            query: {
                                ContactID: item.ContactID,
                                ...query
                            },
                            piggyQuery: {
                                ContactInDriverID: item.ContactInDriverID,
                                ...query
                            },
                            errorMessage: true,
                            successMessage: translate(`text.contact_deleted_from_${Resources.DriverDispatch}`, [`${item.FirstName} ${item.LastName}`]),
                            resource: Resources.DriverDispatch,
                            piggyResource: Resources.DriverDispatch
                        }))

                        this.hideDialogConfirmDeleteDialog()
                    }}
                />

                <TableOptionsDialog
                    show={this.state.isColumnsDialogVisible}
                    pagePath={this.pagePath}
                    columns={this.state.tableOptions.columns}
                    setTableColumnOptions={this.handleSetTableColumnOptions}
                    getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                    onClose={this.handleToggleColumnSettingsDialog}
                    translate={translate}
                />

                <Modal
                    show={this.state.isLoadPreviewDialogOpen}
                    widthClass={"max-w-7xl"}
                    onClose={this.toggleLoadPreviewDialog}
                >
                    <LoadPlaningModalBody
                        title={translate("dialog_heading.load_planing")}
                        dispatch={this.props.dispatch}
                        history={this.props.history}
                        previewData={this.state.loadPreviewData}
                        dialogResource={this.props.dialogResource}
                        isLoading={this.props.dialogResource.isLoading}
                        onSubmit={this.handlePreviewLoadClick}
                        onResetLoadReviewState={() => this.setState({loadPreviewData: {}})}


                        onClose={this.toggleLoadPreviewDialog}
                        translate={translate}
                    />
                </Modal>

                <ModalDefault
                    show={this.state.notAssignedTrucks}
                    widthClass={"max-w-screen"}
                    limitHeight={true}
                    title={translate('modal_heading.trucks_not_assigned')}

                    closeButtonLabel={translate('btn.Close')}
                    onClose={() => this.handleToggleTrucksDialog()}

                    options={
                        <div className="absolute top-0 right-16 bottom-0 flex items-center">
                            <button
                                className={
                                    classNames(
                                        "btn btn-header z-10"
                                    )
                                }
                                onClick={this.fetchData}
                            >
                                <ArrowPathIcon className={
                                    classNames(
                                        "w-5 h-5",
                                        isLoading ? "animate-spin" : undefined
                                    )
                                }/>
                            </button>
                        </div>
                    }
                >
                    <TableCard
                        className="flex flex-col md:bg-inverse md:border border-tm-gray-300 md:shadow-card h-dialog-body">
                        <ResourceTable
                            data={notAssignedTrucksData}
                            commonTable={true}
                            fields={this.getTrucksFields()}
                            options={{
                                behaviour: {
                                    hasVirtualRows: true,
                                    canAdjustWidth: true
                                },
                                style: {
                                    horizontalLines: true,
                                    verticalLines: false,
                                    floatingActions: true
                                }
                            }}
                            isLoading={isLoading}
                            translate={translate}

                            onView={(item) => this.handleTruckClick(item)}
                            onRowClick={(item) => checkPerm(Resources.Trucks, READ_PERM) && this.handleTruckClick(item)}

                            onEdit={(item) => this.handleToggleUnassignedEditModalTruck(item)}

                            hasEditPerm={checkPerm(Resources.TrucksInfo, UPDATE_PERM)}
                            hasViewPerm={checkPerm(Resources.Trucks, READ_PERM)}

                            sort={this.state.trucksSort}
                            sortBy={this.state.trucksSortBy}
                            onSortChange={this.handleUpdateSortTrucks}
                        />

                        <NoRecords
                            addClass={'mt-5'}
                            show={(notAssignedTrucksData.length === 0) && !isLoading}
                            title={translate('text.no_records')}
                        />
                    </TableCard>
                </ModalDefault>

                <ModalDefault
                    show={this.state.notAssignedTrailers}
                    widthClass={"max-w-screen"}
                    limitHeight={true}
                    title={translate('modal_heading.trailers_not_assigned')}

                    closeButtonLabel={translate('btn.Close')}
                    onClose={() => this.handleToggleTrailersDialog()}

                    options={
                        <div className="absolute top-0 right-16 bottom-0 flex items-center">
                            <button
                                className={
                                    classNames(
                                        "btn btn-header z-10"
                                    )
                                }
                                onClick={this.fetchData}
                            >
                                <ArrowPathIcon className={
                                    classNames(
                                        "w-5 h-5",
                                        isLoading ? "animate-spin" : undefined
                                    )
                                }/>
                            </button>
                        </div>
                    }
                >
                    <TableCard
                        className="flex flex-col md:bg-inverse md:border border-tm-gray-300 md:shadow-card h-dialog-body">
                        <ResourceTable
                            data={notAssignedTrailersData}
                            commonTable={true}
                            fields={this.getTrailersFields()}
                            options={{
                                behaviour: {
                                    hasVirtualRows: true,
                                    canAdjustWidth: true
                                },
                                style: {
                                    horizontalLines: true,
                                    verticalLines: false,
                                    floatingActions: true
                                }
                            }}
                            isLoading={isLoading}
                            translate={translate}

                            onView={(item) => this.handleTrailerClick(item)}
                            onRowClick={(item) => checkPerm(Resources.Trailers, READ_PERM) && this.handleTrailerClick(item)}

                            onEdit={(item) => this.handleToggleUnassignedEditModalTrailer(item)}

                            hasEditPerm={checkPerm(Resources.Trailer, UPDATE_PERM)}
                            hasViewPerm={checkPerm(Resources.Trailers, READ_PERM)}

                            sort={this.state.trailersSort}
                            sortBy={this.state.trailersSortBy}
                            onSortChange={this.handleUpdateSortTrailers}
                        />

                        <NoRecords
                            addClass={'mt-5'}
                            show={(notAssignedTrailersData.length === 0) && !isLoading}
                            title={translate('text.no_records')}
                        />
                    </TableCard>
                </ModalDefault>

                <ModalDefault
                    show={this.state.notAssignedDrivers}
                    widthClass={"max-w-screen"}
                    limitHeight={true}
                    title={translate('modal_heading.drivers_not_assigned')}

                    closeButtonLabel={translate('btn.Close')}
                    onClose={() => this.handleToggleDriversDialog()}

                    options={
                        <div className="absolute top-0 right-16 bottom-0 flex items-center">
                            <button
                                className={
                                    classNames(
                                        "btn btn-header z-10"
                                    )
                                }
                                onClick={this.fetchData}
                            >
                                <ArrowPathIcon className={
                                    classNames(
                                        "w-5 h-5",
                                        isLoading ? "animate-spin" : undefined
                                    )
                                }/>
                            </button>
                        </div>
                    }
                >
                    <TableCard
                        className="flex flex-col md:bg-inverse md:border border-tm-gray-300 md:shadow-card h-dialog-body">
                        <ResourceTable
                            data={notAssignedDriversData}
                            commonTable={true}
                            fields={this.getDriverFields()}
                            options={{
                                behaviour: {
                                    hasVirtualRows: true,
                                    canAdjustWidth: true
                                },
                                style: {
                                    horizontalLines: true,
                                    verticalLines: false,
                                    floatingActions: true
                                }
                            }}
                            isLoading={isLoading}
                            translate={translate}

                            onView={(item) => this.handleDriverClick(item)}
                            onRowClick={(item) => checkPerm(Resources.Drivers, READ_PERM) && this.handleDriverClick(item)}
                            onEdit={(item) => this.handleToggleUnassignedEditModalDriver(item)}

                            hasEditPerm={checkPerm(Resources.DriverInfo, UPDATE_PERM)}
                            hasViewPerm={checkPerm(Resources.Drivers, READ_PERM)}
                            sort={this.state.driversSort}
                            sortBy={this.state.driversSortBy}
                            onSortChange={this.handleUpdateSortDrivers}
                        />

                        <NoRecords
                            addClass={'mt-5'}
                            show={(notAssignedDriversData.length === 0) && !isLoading}
                            title={translate('text.no_records')}
                        />
                    </TableCard>
                </ModalDefault>

                <ModalDefault
                    show={this.state.isViewModalOpen}
                    limitHeight={true}
                    title={
                        <div className={"flex"}>
                            {translate("modal_heading.unitInfo")}

                            <CopyToClipboardButton
                                addClass={"bottom-1"}
                                clipboardText={unitInfo}
                                translate={translate}
                            />

                        </div>
                    }
                    isLoading={infoLoading}
                    widthClass={"max-w-screen"}
                    translate={translate}
                    onClose={() => this.setState({isViewModalOpen: false})}
                    closeButtonLabel={translate('btn.close')}
                >
                    <div className="p-5 bg-tm-gray-100">
                        <div className="mb-8">
                            <div
                                className="text-base p-5 bg-inverse rounded-card whitespace-pre-line shadow"
                            >
                                {unitInfo}
                            </div>
                        </div>
                    </div>
                </ModalDefault>


                <ModalDefault
                    show={this.state.isMapModalOpen}
                    widthClass={"max-w-7xl"}
                    title={translate("text.last_known_truck_location")}

                    closeButtonLabel={translate("btn.close")}
                    onClose={() => this.setState({isMapModalOpen: false})}
                >
                    <FleetShareTableMap
                        translate={translate}
                        selectedLocation={this.state.lastLocation}
                    />
                </ModalDefault>

                <ModalSaveResource
                    title={this.state.notAssignedDrivers ? translate("text.edit_un_assigned_driver") : this.state.notAssignedTrucks ? translate("text.edit_un_assigned_trucks") : this.state.notAssignedTrailers ? translate("text.edit_un_assigned_trailers") : ""}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-6"
                    show={!!this.state.editUnassignedModalOpen}
                    onClose={() => this.setState({editUnassignedModalOpen: false})}
                    fields={this.getFieldsUnassignedEdit()}
                    onSubmit={(params) => {
                        if (params) {
                            if (this.state.notAssignedDrivers) {
                            params.DriverID = this.state.selectedNotAssignedItemDriver.DriverID
                            params.quick = 1;

                            this.props.dispatch(updateResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: params,
                                resource: Resources.DriverInfo,
                                piggyResource: this.getResource(),
                                errorMessage: true,
                                successMessage: "Driver updated.",
                            }));
                            this.handleToggleUnassignedEditModalDriver()
                        }

                        if (this.state.notAssignedTrucks) {
                            params.TruckID = this.state.selectedNotAssignedItemTruck.TruckID
                            params.quick = 1;

                            this.props.dispatch(updateResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: params,
                                resource: Resources.TrucksInfo,
                                piggyResource: this.getResource(),
                                errorMessage: true,
                                successMessage: "Trucks updated.",
                            }));
                            this.handleToggleUnassignedEditModalTruck()
                        }

                            if (this.state.notAssignedTrailers) {
                                params.TrailerID = this.state.selectedNotAssignedItemTrailer.TrailerID
                                params.quick = 1;
                                this.props.dispatch(updateResource({
                                    user: LocalStorage.get("user"),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: Resources.Trailer,
                                    piggyResource: this.getResource(),
                                    errorMessage: true,
                                    successMessage: "Trailer updated.",
                                }));
                                this.handleToggleUnassignedEditModalTrailer()
                            }
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadata}
                />
            </Layout>
        )
    }
}

export default connect(state => state)(DispatchManagerView)
