import {render} from "react-dom";

import {createElement} from "react";
import App from "./app";

// Theme files
import "./common/styles/variables.css"
import "./styles/main.css";
import "./styles/vendor/dropzone.min.css";
import "./styles/vendor/component-custom-switch.min.css";
import "./styles/styles.css";
import "./styles/cron.css";
import "./styles/react-leaflet-markercluster.css";
import "./styles/react-leaflet.css";
import "./common/styles/style.css"; // TODO: This should be deleted eventually
import './common/styles/tooltip-style.css'

import "./font/iconsmind-s/css/iconsminds.css"; // Todo: search for iconsmind references in project, replace with hero icons and delete file
import "./font/simple-line-icons/css/simple-line-icons.css"; // Todo: search for simple-icon references in project, replace with hero icons and delete file

import * as serviceWorker from "./serviceWorker";
import {_ENV_MODE} from "./util/env";
import * as Sentry from '@sentry/react'

/*
 * Globals
 =========================================================================================*/
window._DEV_MODE = (_ENV_MODE === 'local');

if (_ENV_MODE === 'prod' || _ENV_MODE === 'production') {
    Sentry.init({
        dsn: "https://04bf66bb7846ce6349218c655c91d729@o4508998666485760.ingest.us.sentry.io/4508998682214400",
        integrations: [
            new Sentry.BrowserTracing({
                tracePropagationTargets: ["https:fast-api.accur8.services/"],
            }),
            new Sentry.Replay(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0,
        // Session Replay
        replaysSessionSampleRate: 0.2,
        replaysOnErrorSampleRate: 1.0,
        release: "truck-main-frontend@" + process.env.REACT_APP_VERSION + "+" + process.env.REACT_APP_COMMIT_HASH,
        ignoreErrors: [
            'ResizeObserver loop limit exceeded',
            'ResizeObserver loop completed with undelivered notifications'
        ]
    });
}

/*
 * Locale data preload
 =====================================================================================*/

import(`./assets/locale/${process.env.REACT_APP_LOCALE ?? 'en.json'}`)
    .then((module) => {
        const localeData = module.default;

        render(
            createElement(App, { locale: "en", localeData }),
            document.getElementById("root")
        );
    })
    .catch((err) => {
        console.error("Error loading locale data:", err);
    });


/*
 * Service worker
 ===================================================================================*/
serviceWorker.unregister();
