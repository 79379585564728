import React, {Component} from 'react'
import Layout from "../../../common/components/layout";
import {
    checkPerm,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getProp,
    mergeDeep,
    returnOffsetAndPagination,
    saveTableColumns
} from '../../../common/util/util-helpers';
import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY, DEFAULT_QUERY_LIMIT,
    DELETE_PERM,
    UPDATE_PERM
} from "../../../common/util/util-consts";
import LocalStorage from "../../../util/localStorage";
import {createResource, deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import {Field, FieldsManager} from "../../../data/services/fields";
import {cloneDeep} from "../../../common/util/util-vanilla";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import Resources from "../../../data/services/resources";
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import {connect} from "react-redux";
import ResourceTable from "../../../common/components/resource-table";


class InsuranceView extends Component {

    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1)

        this.tablePageDefaults = {
            behaviour: {
                rowSelect: true,
                canAdjustWidth: true,
                hasMenu: true
            },
            style: {
                condensed: true,
                isGPUAccelerated: true
            }
        }

        this.state = {
            // Defaults and filters
            ...DEFAULT_CRUD_STATE,
            sortBy: 'StartDate',
            fields: this.getFields(),
            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate),
            queryFilterFields: this.getQueryFilterFields(),
            prefilled: '',
            isColumnsDialogVisible: false,

            // Dialogs
            confirmDialog: false,
            confirmDialogHideAnimation: false,
            breakpoint: {}
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    deleteResource = (item) => {
        this.setState({text: this.props.translate('message.confirm_delete_generic', [item.InsuranceName])}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(deleteResource({
                        user: LocalStorage.get('user'),
                        query: Object.assign({}, {id: item[this.getPrimaryKey()]}),
                        piggyQuery: this.getQuery(),
                        errorMessage: true,
                        successMessage: this.props.translate(`message.archived_generic`, [item.InsuranceName]),
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    /** UI Events
     ================================================================= */
    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        }, () => {
            if (this.state.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
                this.handleFilterInputChange('limit', 10);
            }
        });
    }

    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        })
    }

    handleToggleEditModel = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            isColumnsDialogVisible: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate)
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }
    /** Helpers
     ================================================================= */
    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {
                menuPlacement: "top",
                hasPortal: true
            })
        }
    }

    /*
        'InsuranceName' => 'nvarchar(64)', // Uklanjamo ide kategorije tj tip.

        'InsuranceCategoryID' => 'int NULL', // General liability, physical damage

        'PolicyNumber' => 'nvarchar(256)',

        'StartDate' => 'datetime NULL',
        'EndDate' => 'datetime NULL',

        'TotalPremium' => 'decimal(16,4) NULL',// U dolarima koliko je placena polisa - > 100 000 total

        'Deductible'=> 'decimal(16,4) NULL', // Ucesce u procentima

        'BasedOn' => 'int NULL',// 1) Per truck/unit, 2) Per mile, 3) Based on revenue ($100)
        'BasedOnCount' => 'int NULL', // 10 trucks

        'ContactID' => 'int NULL', // Skloniti, ali da ima link na vendora pa se u njemu namestaju kontakti

        'InsuranceAgentID' => 'int NULL',// Organization ID, mediator sa vise osiguravajucih kuca - pravno lice nije fizicko
        'VendorID' => 'bit NULL'// Organization ID, krajnji provajder osiguranja
     */
    getFields = (item = null) => {
        const fieldTemplates = {
            InsuranceName: new Field('InsuranceName', this.state?.queryFilterFields?.query?.value ?? '', ['empty'], false, 'text', {
                addContainerClass: 'relative col-span-6'
            }),
            PolicyNumber: new Field('PolicyNumber', '', ['empty'], false, 'text', {
                addContainerClass: 'relative col-span-6'
            }),
            StartDate: new Field('StartDate', '', ['empty'], false, 'date', {
                addContainerClass: 'relative col-span-3'
            }),
            EndDate: new Field('EndDate', '', ['empty'], false, 'date', {
                addContainerClass: 'relative col-span-3'
            }),

            TotalPremium: new Field('TotalPremium', '', ['empty'], false, 'float', {
                addContainerClass: 'relative col-span-3'
            }),
            Deductible: new Field('Deductible', '', ['empty'], false, 'float', {
                addContainerClass: 'relative col-span-3'
            }),
            BasedOn: new Field('BasedOn', '', ['empty'], false, 'select', {
                addContainerClass: 'relative col-span-3'
            }),
            BasedOnCount: new Field('BasedOnCount', '', ['empty'], false, 'float', {
                addContainerClass: 'relative col-span-3'
            }),

            //InsuranceCategoryID: new Field('InsuranceCategoryID', '', ['empty'], false, 'select'),
            VendorID: new Field('VendorID', '', ['empty'], false, 'select-search', {
                addContainerClass: 'relative col-span-6'
            }),
            InsuranceAgentID: new Field('InsuranceAgentID', '', [], false, 'select-search', {
                addContainerClass: 'relative col-span-3'
            }),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    saveFilters = () => {
        let filters = []
        Object.keys(this.getQuery()).map(it => filters.push(it))
        LocalStorage.persistState(this.pagePath, this.state, filters)
    }

    getResource = () => {
        return Resources.Insurance
    }

    getPrimaryKey = () => {
        return 'InsuranceID'
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props
        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)

        const metadata = {
            BasedOn: {
                1: 'Per truck/unit,',
                2: 'Per mile',
                3: 'Based on revenue ($100)',
                4: 'Other'
            },
            InsuranceCategoryID: {
                1: 'Cat 1',
                2: 'Cat 2'
            },
            VendorID: {
                api: 'api/' + Resources.OrganizationsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (it) => ({
                    label: it.LegalName,
                    value: it.OrganizationID
                })
            },
            InsuranceAgentID: {
                api: 'api/' + Resources.OrganizationsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (it) => ({
                    label: it.LegalName,
                    value: it.OrganizationID
                })
            },
        }

        return (
            <Layout
                onBreakpointChange={this.handleBreakpointChange}
                isAccessible={!(resource.errorStatus === 2)}
                {...this.props}
            >
                <Page>
                    {/** Header **/}
                    <PageHeader
                        title={translate('page.heading.insurance')}
                        buttonLabel={translate('btn.create_new')}
                        onButtonClick={() => this.handleToggleCreateModal()}
                        hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                        afterTitle={(
                            <PageHeaderInfo
                                dispatch={this.props.dispatch}
                            />
                        )}
                    />

                    <div className="sm:flex">
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleClearFiltersClick}
                            translate={translate}
                        />

                        <div className="ml-auto flex sm:justify-start justify-end items-center">
                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>
                    </div>

                    {/** Table **/}
                    <TableCard addClass={'relative'}>
                        <TableFilters
                            hideLimit
                            filterFields={this.state.queryFilterFields}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                            onRefreshTable={this.fetchData}
                            isLoading={resource.isLoading}
                        />

                        <ResourceTable
                            data={data}
                            fields={this.getFields()}
                            verticalTableIsVisible={this.state.breakpoint.index <= 1}

                            translate={translate}
                            isLoading={resource.isLoading}

                            options={this.state.tableOptions}

                            limit={this.state.queryFilterFields.limit.value}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}
                            saveTableOptions={this.setOptions}
                            onRowClick={checkPerm(this.getResource(), UPDATE_PERM) ? this.handleToggleEditModel : null}

                            onView={null}
                            onEdit={this.handleToggleEditModel}
                            onDelete={this.deleteResource}

                            hasEditPerm={checkPerm(this.getResource(), UPDATE_PERM)}
                            hasDeletePerm={checkPerm(this.getResource(), DELETE_PERM)}
                        />

                        <TableCardFooter
                            show={!(!data.length && !resource.isLoading)}
                        >
                            <Pagination
                                count={count}
                                isLoading={resource.isLoading}
                                hideRowsPerPage={this.state.breakpoint.index <= 1}
                                handleQueryChange={
                                    (name, value, currentPage) => name === "offset"
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                            />
                        </TableCardFooter>

                        <NoRecordsTable
                            show={(data.length === 0) && !resource.isLoading}
                            canCreate={checkPerm(this.getResource(), CREATE_PERM)}
                            title={translate('text.no_matching_records')}
                            text={translate('text.create_new_resource', [this.state.queryFilterFields.query.value])}
                            btnLabel={translate('title.create_insurance')}
                            onBtnClick={() => {
                                this.setState({prefilled: this.state.queryFilterFields.query?.value}, () => {
                                    this.handleToggleCreateModal()
                                })
                            }}
                            onClearFilterClick={this.handleClearFiltersClick}
                            clearFilterBtnLabel={translate('text.clear_all_filters')}
                            clearFilterText={translate('text.try_without_filters')}
                            filters={this.state.queryFilterFields}
                        />
                    </TableCard>

                    <TableOptionsDialog
                        show={this.state.isColumnsDialogVisible}
                        pagePath={this.pagePath}
                        columns={this.state.tableOptions.columns}
                        setTableColumnOptions={this.handleSetTableColumnOptions}
                        getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                        onClose={this.handleToggleColumnSettingsDialog}
                        translate={translate}
                    />

                    {/** CRUD dialogs **/}
                    <ModalSaveResource
                        show={this.state.createModalOpen}
                        title={translate('title.create_insurance')}
                        widthClass="max-w-md"
                        gridColsClass="grid-cols-3"
                        errorMessage={this.state.errorMessage}
                        onClose={this.handleToggleCreateModal}
                        fields={this.getFields(this.state.selectedItem)}
                        onSubmit={(params) => {
                            if (params) {
                                this.props.dispatch(createResource({
                                    user: LocalStorage.get('user'),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: this.getResource(),
                                    piggyResource: this.getResource(),
                                    errorMessage: true,
                                    successMessage: 'Insurance created.'
                                }))
                                this.handleToggleCreateModal(null);
                            }
                        }}
                        translate={this.props.translate}
                        metadata={metadata}
                        handleInputChange={this.props.handleInputChange ?? null}
                    />

                    <ModalSaveResource
                        title={translate('title.edit_insurance')}
                        widthClass="max-w-md"
                        gridColsClass="grid-cols-3"
                        errorMessage={this.state.errorMessage}
                        show={this.state.editModalOpen}
                        onClose={this.handleToggleEditModel}

                        fields={this.getFields(this.state.selectedItem)}
                        onSubmit={(params) => {
                            if (params) {
                                params[this.getPrimaryKey()] = this.state.selectedItem[this.getPrimaryKey()]
                                this.props.dispatch(updateResource({
                                    user: LocalStorage.get('user'),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: this.getResource(),
                                    piggyResource: this.getResource(),
                                    errorMessage: true,
                                    successMessage: 'Insurance updated.'
                                }));
                                this.handleToggleEditModel(null);
                            }
                        }}
                        translate={this.props.translate}
                        metadata={metadata}
                        handleInputChange={this.props.handleInputChange ?? null}
                    />

                    <ModalConfirm
                        title={'Confirm'}
                        show={!!this.state.confirmDialog}
                        text={this.state.text}
                        onClose={() => this.setState({confirmDialog: false})}
                        buttonLabel={translate('btn.confirm')}
                        closeButtonLabel={'Cancel'}
                        translate={translate}
                        onConfirm={this.state.confirmModal}
                    />

                </Page>
            </Layout>
        )
    }
}

export default connect(state => state)(InsuranceView)
