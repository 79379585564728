import React from "react";
import {useDispatch} from "react-redux";
import {classNames} from "../../util/util-helpers";
import CellButton from "../resource-table/table-components/cell-button";
import {showModal} from "../../../data/actions/ui";

export default function InnerGroupTable({
                                            data,
                                            fields,
                                            tableIndex,
                                            positionClass,
                                        }) {

    const dispatch = useDispatch();

    function getCellTitle(tableIndex) {
        if (fields.PrimaryDispatch) {
            if (data[tableIndex]["Dispatcher"]) {
                return data[tableIndex]["Dispatcher"];
            }

            return "No dispatcher"
        }

        if (fields.ContactGroup) {
            if (data[tableIndex]["ContactGroup"]) {
                return data[tableIndex]["ContactGroup"]
            }

            return "Not grouped";
        }
    }

    return <div
        className={classNames(
            "flex flex-col shrink-0 sticky z-10",
            positionClass ? positionClass : 'left-0')
        }
    >
        <div className={
            classNames(
                "col-group border-r border-tm-gray-200 relative truncate",
                tableIndex % 2 === 0 ? "bg-inverse" : "bg-tm-gray-100"
            )}
             style={{backgroundColor:  data[tableIndex]?.DispatchContactGroupColorTag}}
             title={getCellTitle(tableIndex)}
        >
            {fields.PrimaryDispatch && (data[tableIndex]["Dispatcher"]
                    ? <CellButton
                        onClick={() => {
                            dispatch(showModal('ViewContactCard', {ContactID: data[tableIndex]["key"]}));
                        }}
                    >
                        {data[tableIndex]["Dispatcher"]}
                    </CellButton>
                    : "No dispatcher"
            )}

            {fields.DispatchContactGroup && (data[tableIndex]["DispatchContactGroup"]
                    ? <div className="font-bold">
                        {data[tableIndex]["DispatchContactGroup"]}
                    </div>
                    : "Not grouped"
            )}


            {fields.ContactGroup && (data[tableIndex]["ContactGroup"]
                    ? data[tableIndex]["ContactGroup"]
                    : "Not grouped"
            )}
        </div>
    </div>
}