import React from "react";
import {createBrowserHistory} from "history";
import {Redirect, Route as NeutralRoute, Router, Switch} from "react-router-dom";
import Login from "./views/auth/login";
import ResetPassword from "./views/auth/reset-password";
import ResetPasswordConfirm from "./views/auth/reset-password-confirm";
import NotFoundView from "./views/general/not-found";
import RegisterView from "./views/auth/register";
import Cookie from "./util/cookie";
import {_ENV_MODE} from "./util/env";
import {isTokenExpired} from "./data/services/api-util";
import * as Sentry from '@sentry/react';
import LocalStorage from "./util/localStorage";
import LoggingInView from "./views/general/logging-in";
import {getUser} from "./common/util/util-auth";
import HomeView from "./views/home";
import AccountingSummaryPageView from "./views/accounting/summary";
import IncomePageView from "./views/accounting/income/income-page";
import ExpensePageView from "./views/accounting/expense/expense-page";
import AccountingReportsPageView from "./views/accounting/reports/reports-page";
import BillingView from "./views/accounting/customer-billing";
import PurchaseOrder from "./views/accounting/purchase-order-page";
import PurchaseOrderSingleView from "./views/accounting/purchase-order-single";
import ProductsServicesPageView from "./views/accounting/products-services";
import AssetsInventoryPageView from "./views/accounting/assets-inventory";
import TaxesPage from "./views/accounting/taxes/taxes-page";
import JournalView from "./views/accounting/journal";
import AccountingPageView from "./views/accounting/chart-of-accounts/chart-of-accounts";
import PayrollView from "./views/accounting/driver-payroll";
import CarrierPayrollView from "./views/accounting/carrier-pay";
import AccountingSettingsView from "./views/accounting/accounting-settings";
import AccountingReimbursementPageView from "./views/accounting/accounting-reimbursement-view";
import AccountingReconciliationsView from "./views/accounting/reconciliation/reconciliations-view";
import AccountingReconciliationView from "./views/accounting/reconciliation";
import CreditAccountsPageView from "./views/accounting/credit-accounts";
import TransactionDetailsView from "./views/accounting/credit-accounts/single-account-transaction-details";
import MoneyCodesPage from "./views/accounting/money-codes-view";
import Customers from './views/companies/customers-view'
import CustomerCreateNew from "./views/companies/customer-create";
import CustomerView from "./views/companies/customer";
import CarriersView from "./views/companies/carriers-view";
import CarrierView from "./views/companies/carrier";
import Vendors from "./views/companies/vendors";
import VendorView from "./views/companies/vendor";
import ExpiryItemsView from "./views/general/expiry-items-view";
import Resources from "./data/services/resources";
import LoadsView from "./views/dispatch/loads-view";
import TrucksView from "./views/fleet/trucks-view";
import TruckCreateView from "./views/fleet/truck-create";
import TruckView from "./views/fleet/truck";
import TrailerCreateView from "./views/fleet/trailer-create";
import TrailersView from "./views/fleet/trailers";
import TrailerView from "./views/fleet/trailer";
import FuelCardsView from "./views/fleet/fuel-cards-view";
import FuelPurchaseView from "./views/fleet/fuel-purchase-view";
import IntegrationsTab from "./views/general/settings/integrations";
import TranspondersView from "./views/fleet/transponders-view";
import MileageView from "./views/fleet/mileage-view";
import AllTasksView from "./views/tasks/all-tasks";
import TaskGroupView from "./views/tasks/group-tasks";
import RepeatTasksView from "./views/tasks/repeatTasks";
import TasksSettingsView from "./views/tasks/tasks-settings-view";
import TaskGrabData from "./common/components/layout/layout-components/task-grab-data";
import Notifications from "./common/components/notifications";
import {generateCustomNotification} from "./common/util/util-helpers";
import AIChat from "./common/components/layout/layout-components/AIChat";
import ContactsView from "./views/contacts/contacts-view";
import AccountView from "./views/general/account-view";
import ArticlesView from "./views/general/articles-view";
import UsersReportView from "./views/general/users-report-view";
import LoggedDevicesView from "./views/general/logged-devices-view";
import GroupsView from "./views/my-company/groups-view";
import DivisionsView from "./views/my-company/divisions-view";
import OfficesView from "./views/my-company/offices-view";
import SystemUsersView from "./views/my-company/system-users-view";
import ContactPermissionsView from "./views/my-company/contact-permissions-view";
import DriversView from "./views/personnel/drivers-view";
import SettlementTemplateView from "./views/personnel/settlement-template-view";
import DriverSettlementsTemplateCreateEdit
    from "./views/personnel/settlement-template-view/create-edit-settlement-template";
import DriverCreateView from "./views/personnel/driver-single";
import EmployeesPageView from "./views/personnel/employees-page";
import AgentsView from "./views/personnel/agents-view";
import AgentSingleView from "./views/personnel/agent-single";
import LocationsView from "./views/places/locations";
import LocationZonesView from "./views/places/location-zones-view";
import DuplicatesView from "./views/places/duplicate-locations";
import ClaimsView from "./views/safety/claims";
import AccidentsView from "./views/safety/accidents";
import ClaimView from "./views/safety/claim";
import ClaimCreateView from "./views/safety/claims-new";
import AccidentsCreateView from "./views/safety/accidents-new";
import InsuranceView from "./views/safety/insurance";
import SafetySettingsView from "./views/safety/safety-settings-view";
import AccountingAssetCreateView from "./views/accounting/assets-inventory/create-accounting-asset-view";
import FuelReportsView from "./views/fleet/fuel-reports-view";
import PersonnelSettingsView from "./views/personnel/personnel-settings-view";
import EmployeeSingleView from "./views/personnel/employee-single";
import LoadsCreateView from './views/dispatch/load-view';
import LoadView from "./views/dispatch/load-view";
import CarrierCreateView from "./views/companies/carrier-create";
import VendorCreateView from "./views/companies/vendor-create";
import FleetSettings from "./views/fleet/fleet-settings-view";
import QuotesView from "./views/dispatch/quotes-view";
import QuoteView from "./views/dispatch/quote-view";
import DispatchManagerView from "./views/dispatch/dispatch-manager-view";
import CommandCenterView from "./views/dispatch/command-center-view";
import MatricesView from "./views/dispatch/matrices-view";
import DispatchTemplatesView from "./views/dispatch/templates";
import DispatchSettingsView from "./views/dispatch/dispatch-settings-view";
import DownloadCenter from "./views/download-center";
import ReportsView from "./views/fleet/reports-view";
import CompaniesSettingsView from "./views/companies/companies-settings-view";
import TripsView from "./views/dispatch/trips-view";
import TripView from "./views/dispatch/trip-view";
import TermsOfUse from "./views/general/termsOfUse";
import Profiles from "./views/general/profiles";
import RecurringLoadsView from "./views/dispatch/recurringLoads";
import NewCandidatesView from "./views/personnel/new-candidates-view";
import TicketsView from "./views/general/tickets-view";
import UiToolsView from "./views/general/settings/ui-tools-view";
import SettingsView from "./views/general/settings";
import RequestsView from "./views/personnel/requests-view";
import DocumentsView from "./views/documents";
import TrackTimeView from "./views/personnel/track-time-view";
import ExternalTrucksView from "./views/fleet/external-trucks-view";
import PublicLoad from "./views/public/public-load-view";
import PublicCarrierView from "./views/public/public-carrier-view";
import PublicSchedulerView from "./views/public/public-scheduler-view";
import DispatchReportsView from "./views/dispatch/reports-view";
import CompanySettingsView from "./views/my-company/company-settings";
import LoadsViewSplit from "./views/dispatch/loads-view/loads-view-split";
import ScheduledMaintenancePageView from "./views/fleet/maintenance-view";
import FleetExpiryItemsView from "./views/fleet/expiry-items-view";

const PrivateRoute = ({component: Component, ...rest}) => (
    <NeutralRoute
        {...rest}
        render={props => {
            const email = getUser('Contact.Email');

            if (email) {
                Sentry.setUser({
                    email: email,
                    ContactName: `${getUser('Contact.LastName')} ${getUser('Contact.FirstName')}`,
                    ContactID: getUser('Contact.ContactID'),
                    CompanyName: getUser('Contact.CompanyName'),
                    CompanyID: getUser('Contact.CompanyID')
                });
            }

            const token = Cookie.getCookie("access_token_" + _ENV_MODE);

            if (token) {
                if (
                    !LocalStorage.has("user")
                    || getUser("access_token") !== token
                    || isTokenExpired(token)
                ) {
                    LocalStorage.set("redirect_path", props.match.url);

                    return <LoggingInView {...props} />;
                }

                return <Component {...props} />;
            }

            LocalStorage.set("redirect_path", props.match.url);
            return (
                <Redirect to={{
                    pathname: "/login",
                    state: {from: props.location}
                }}/>
            )
        }}
    />
);

const PublicRoute = ({component: Component, ...rest}) => {
    return (
        <NeutralRoute
            {...rest}
            render={props => <Component {...props}/>}
        />
    )
};


export default function Routes(store, translator) {
    function getComponent(Component, props) {
        return (
            <Component {...props} translate={(key, args) => translator.translate(key, args)}/>
        );
    }

    const history = createBrowserHistory();
    store.history = history;

    return (
        <Router history={history}>
            <Switch>
                {/* AUTH */}
                <PublicRoute exact path="/login" component={(props) => getComponent(Login, props)}/>
                <PublicRoute exact path="/logging-in" component={(props) => getComponent(LoggingInView, props)}/>
                <PublicRoute exact path="/register" component={(props) => getComponent(RegisterView, props)}/>
                <PublicRoute exact path="/reset-password"
                             component={(props) => getComponent(ResetPassword, props)}/>
                <PublicRoute exact path="/reset-password-mobile"
                             component={(props) => getComponent(ResetPassword, props)}/>
                <NeutralRoute exact path="/reset-password-confirm/:email/:token/:is_new"
                              component={(props) => getComponent(ResetPasswordConfirm, props)}/>
                <NeutralRoute exact path="/reset-password-mobile-confirm/:email/:token/:is_new"
                              component={(props) => getComponent(ResetPasswordConfirm, props)}/>

                <PrivateRoute exact path="/" component={(props) => getComponent(HomeView, props)}/>
                <PrivateRoute exact path="/home" component={(props) => getComponent(HomeView, props)}/>


                {/*Public routes*/}
                <NeutralRoute exact path="/load/public/:token"
                              component={(props) => getComponent(PublicLoad, props)}/>
                <NeutralRoute exact path="/load/carrier/public/:token"
                              component={(props) => getComponent(PublicCarrierView, props)}/>

                <NeutralRoute exact path="/fleet/public/:token"
                              component={(props) => getComponent(PublicSchedulerView, props)}/>


                {/* Company routes */}
                <PrivateRoute exact path="/customers" component={(props) => getComponent(Customers, props)}/>
                <PrivateRoute exact path="/customer/create"
                              component={(props) => getComponent(CustomerCreateNew, props)}/>
                <PrivateRoute exact path="/customers/info/:OrganizationID/:CustomerID"
                              component={(props) => getComponent(CustomerView, props)}/>
                <PrivateRoute exact path="/carriers" component={(props) => getComponent(CarriersView, props)}/>
                <PrivateRoute exact path="/carrier/create"
                              component={(props) => getComponent(CarrierCreateView, props)}/>
                <PrivateRoute exact path="/carriers/info/:OrganizationID/:id"
                              component={(props) => getComponent(CarrierView, props)}/>
                <PrivateRoute exact path="/vendors" component={(props) => getComponent(Vendors, props)}/>
                <PrivateRoute exact path="/vendor/create"
                              component={(props) => getComponent(VendorCreateView, props)}/>
                <PrivateRoute exact path="/vendors/info/:OrganizationID/:VendorID"
                              component={(props) => getComponent(VendorView, props)}/>
                <PrivateRoute exact path="/companies-expiry-items"
                              component={(props) => getComponent(ExpiryItemsView, Object.assign({}, props, {resourceName: Resources.CompaniesExpiry}))}/>
                <PrivateRoute exact path="/expiry-items"
                              component={(props) => getComponent(FleetExpiryItemsView, Object.assign({}, props, {resourceName: Resources.FleetExpiry}))}/>
                <PrivateRoute exact path="/companies-settings"
                              component={(props) => getComponent(CompaniesSettingsView, props)}/>


                {/* Dispatch routes */}
                <PrivateRoute exact path="/loads" component={(props) => getComponent(LoadsView, props)}/>
                <PrivateRoute exact path="/loads-split" component={(props) => getComponent(LoadsViewSplit, props)}/>

                <PrivateRoute exact path="/loads/create"
                              component={(props) => getComponent(LoadsCreateView, props)}/>
                <PrivateRoute exact path="/loads/info/:id" component={(props) => getComponent(LoadView, props)}/>
                <PrivateRoute exact path="/loads/copy/:id" component={(props) => getComponent(LoadView, props)}/>
                <PrivateRoute exact path="/quotes" component={(props) => getComponent(QuotesView, props)}/>
                <PrivateRoute exact path="/quotes/create"
                              component={(props) => getComponent(QuoteView, props)}/>
                <PrivateRoute exact path="/quotes/info/:id"
                              component={(props) => getComponent(QuoteView, props)}/>
                <PrivateRoute exact path="/quotes/copy/:id"
                              component={(props) => getComponent(QuoteView, props)}/>
                <PrivateRoute exact path="/dispatch-manager"
                              component={(props) => getComponent(DispatchManagerView, props)}/>
                <PrivateRoute exact path="/command-center"
                              component={(props) => getComponent(CommandCenterView, props)}/>
                <PrivateRoute exact path="/matrices"
                              component={(props) => getComponent(MatricesView, props)}/>
                <PrivateRoute exact path="/dispatch-templates"
                              component={(props) => getComponent(DispatchTemplatesView, props)}/>
                <PrivateRoute exact path="/dispatch-expiry-items"
                              component={(props) => getComponent(ExpiryItemsView, Object.assign({}, props, {resourceName: Resources.DispatchExpiry}))}/>
                <PrivateRoute exact path="/dispatch-reports"
                              component={(props) => getComponent(DispatchReportsView, props)}/>
                <PrivateRoute exact path="/dispatch-settings"
                              component={(props) => getComponent(DispatchSettingsView, props)}/>
                <PrivateRoute exact path="/download-center"
                              component={(props) => getComponent(DownloadCenter, props)}/>
                <PrivateRoute exact path="/trips" component={(props) => getComponent(TripsView, props)}/>
                <PrivateRoute exact path="/trip/info/:id" component={(props) => getComponent(TripView, props)}/>
                <PrivateRoute exact path="/recurring-loads"
                              component={(props) => getComponent(RecurringLoadsView, props)}/>

                {/* Fleet */}
                <PrivateRoute exact path="/trucks" component={(props) => getComponent(TrucksView, props)}/>
                <PrivateRoute exact path="/truck/create"
                              component={(props) => getComponent(TruckCreateView, props)}/>
                <PrivateRoute exact path="/trucks/info/:id" component={(props) => getComponent(TruckView, props)}/>
                <PrivateRoute exact path="/trailers" component={(props) => getComponent(TrailersView, props)}/>
                <PrivateRoute exact path="/trailer/create"
                              component={(props) => getComponent(TrailerCreateView, props)}/>
                <PrivateRoute exact path="/trailers/info/:id"
                              component={(props) => getComponent(TrailerView, props)}/>
                <PrivateRoute exact path="/fuel-cards" component={(props) => getComponent(FuelCardsView, props)}/>
                <PrivateRoute exact path="/fuel-purchase"
                              component={(props) => getComponent(FuelPurchaseView, props)}/>
                <PrivateRoute exact path="/integrations-center"
                              component={(props) => getComponent(IntegrationsTab, props)}/>
                <PrivateRoute exact path="/transponders"
                              component={(props) => getComponent(TranspondersView, props)}/>
                <PrivateRoute exact path="/mileage" component={(props) => getComponent(MileageView, props)}/>
                <PrivateRoute exact path="/fuel-report"
                              component={(props) => getComponent(FuelReportsView, props)}/>
                <PrivateRoute exact path="/fleet-settings"
                              component={(props) => getComponent(FleetSettings, props)}/>
                <PrivateRoute exact path="/reports" component={(props) => getComponent(ReportsView, props)}/>
                <PrivateRoute exact path="/maintenance" component={(props) => getComponent(ScheduledMaintenancePageView, props)}/>
                <PrivateRoute exact path="/external-trucks"
                              component={(props) => getComponent(ExternalTrucksView, props)}/>

                {/* Tasks routes */}
                <PrivateRoute exact path="/all-tasks" component={(props) => getComponent(AllTasksView, props)}/>
                <PrivateRoute exact path="/board-tasks" component={(props) => getComponent(TaskGroupView, props)}/>
                <PrivateRoute exact path="/repeat-tasks"
                              component={(props) => getComponent(RepeatTasksView, props)}/>
                <PrivateRoute exact path="/tasks-settings"
                              component={(props) => getComponent(TasksSettingsView, props)}/>

                {/* Contacts routes */}
                <PrivateRoute exact path="/contacts" component={(props) => getComponent(ContactsView, props)}/>

                {/* My company */}
                <PrivateRoute exact path="/groups" component={(props) => getComponent(GroupsView, props)}/>
                <PrivateRoute exact path="/divisions" component={(props) => getComponent(DivisionsView, props)}/>
                <PrivateRoute exact path="/offices" component={(props) => getComponent(OfficesView, props)}/>
                <PrivateRoute exact path="/system-users"
                              component={(props) => getComponent(SystemUsersView, props)}/>
                <PrivateRoute exact path="/contacts/:id"
                              component={(props) => getComponent(ContactPermissionsView, props)}/>
                <PrivateRoute exact path="/company-settings" component={(props) => getComponent(CompanySettingsView, props)}/>

                {/* Personnel */}
                <PrivateRoute exact path="/drivers" component={(props) => getComponent(DriversView, props)}/>
                <PrivateRoute exact path="/driver/create"
                              component={(props) => getComponent(DriverCreateView, props)}/>
                <PrivateRoute exact path="/drivers/info/:id"
                              component={(props) => getComponent(DriverCreateView, props)}/>
                <PrivateRoute exact path="/settlements-template"
                              component={(props) => getComponent(SettlementTemplateView, props)}/>
                <PrivateRoute exact path="/drivers/settlements/template/:id"
                              component={(props) => getComponent(DriverSettlementsTemplateCreateEdit, props)}/>
                <PrivateRoute exact path="/drivers/settlements/template"
                              component={(props) => getComponent(DriverSettlementsTemplateCreateEdit, props)}/>
                <PrivateRoute exact path="/employees-page"
                              component={(props) => getComponent(EmployeesPageView, props)}/>
                <PrivateRoute exact path="/employees/create"
                              component={(props) => getComponent(EmployeeSingleView, props)}/>
                <PrivateRoute exact path="/employees/info/:id"
                              component={(props) => getComponent(EmployeeSingleView, props)}/>
                <PrivateRoute exact path="/agents" component={(props) => getComponent(AgentsView, props)}/>
                <PrivateRoute exact path="/agent-create"
                              component={(props) => getComponent(AgentSingleView, props)}/>
                <PrivateRoute exact path="/agents/info/:id"
                              component={(props) => getComponent(AgentSingleView, props)}/>
                <PrivateRoute exact path="/personnel-expiry-items"
                              component={(props) => getComponent(ExpiryItemsView, Object.assign({}, props, {resourceName: Resources.PersonnelExpiry}))}/>
                <PrivateRoute exact path="/personnel-settings"
                              component={(props) => getComponent(PersonnelSettingsView, props)}/>
                <PrivateRoute exact path="/new-candidates"
                              component={(props) => getComponent(NewCandidatesView, props)}/>
                <PrivateRoute exact path="/requests" component={(props) => getComponent(RequestsView, props)}/>
                <PrivateRoute exact path="/time-tracking"
                              component={(props) => getComponent(TrackTimeView, props)}/>

                {/* Locations */}
                <PrivateRoute exact path="/locations" component={(props) => getComponent(LocationsView, props)}/>
                <PrivateRoute exact path="/location-zones"
                              component={(props) => getComponent(LocationZonesView, props)}/>
                <PrivateRoute exact path="/locations/duplicates"
                              component={(props) => getComponent(DuplicatesView, props)}/>


                {/* Safety */}
                <PrivateRoute exact path="/claims" component={(props) => getComponent(ClaimsView, props)}/>
                <PrivateRoute exact path="/claims/create"
                              component={(props) => getComponent(ClaimCreateView, props)}/>
                <PrivateRoute exact path="/claims/info/:id" component={(props) => getComponent(ClaimView, props)}/>
                <PrivateRoute exact path="/accidents" component={(props) => getComponent(AccidentsView, props)}/>
                <PrivateRoute exact path="/accidents/create"
                              component={(props) => getComponent(AccidentsCreateView, props)}/>
                <PrivateRoute exact path="/accidents/info/:id"
                              component={(props) => getComponent(AccidentsCreateView, props)}/>
                <PrivateRoute exact path="/insurance" component={(props) => getComponent(InsuranceView, props)}/>
                <PrivateRoute exact path="/safety-settings"
                              component={(props) => getComponent(SafetySettingsView, props)}/>


                {/*/!* Accounting *!/*/}
                <PrivateRoute exact path="/accounting-summary"
                              component={(props) => getComponent(AccountingSummaryPageView, props)}/>
                <PrivateRoute exact path="/accounting-income"
                              component={(props) => getComponent(IncomePageView, props)}/>
                <PrivateRoute exact path="/accounting-expenses"
                              component={(props) => getComponent(ExpensePageView, props)}/>
                <PrivateRoute exact path="/accounting-reports"
                              component={(props) => getComponent(AccountingReportsPageView, props)}/>
                <PrivateRoute exact path="/accounting-billing"
                              component={(props) => getComponent(BillingView, props)}/>
                <PrivateRoute exact path="/accounting-purchase-order"
                              component={(props) => getComponent(PurchaseOrder, props)}/>
                <PrivateRoute exact path="/purchase-order/create"
                              component={(props) => getComponent(PurchaseOrderSingleView, props)}/>
                <PrivateRoute exact path="/purchase-order/:id"
                              component={(props) => getComponent(PurchaseOrderSingleView, props)}/>

                <PrivateRoute exact path="/accounting-products-services"
                              component={(props) => getComponent(ProductsServicesPageView, props)}/>
                <PrivateRoute exact path="/accounting-assets-inventory"
                              component={(props) => getComponent(AssetsInventoryPageView, props)}/>
                <PrivateRoute exact path="/accounting-taxes"
                              component={(props) => getComponent(TaxesPage, props)}/>
                <PrivateRoute exact path="/accounting-journal"
                              component={(props) => getComponent(JournalView, props)}/>
                <PrivateRoute exact path="/accounting-accounts"
                              component={(props) => getComponent(AccountingPageView, props)}/>
                <PrivateRoute exact path="/accounting-assets/create"
                              component={(props) => getComponent(AccountingAssetCreateView, props)}/>
                <PrivateRoute exact path="/accounting-assets/:id"
                              component={(props) => getComponent(AccountingAssetCreateView, props)}/>
                <PrivateRoute exact path="/driver-payroll"
                              component={(props) => getComponent(PayrollView, props)}/>
                <PrivateRoute exact path="/carrier-pay"
                              component={(props) => getComponent(CarrierPayrollView, props)}/>
                <PrivateRoute exact path="/accounting-settings"
                              component={(props) => getComponent(AccountingSettingsView, props)}/>
                <PrivateRoute exact path="/accounting-reimbursement"
                              component={(props) => getComponent(AccountingReimbursementPageView, props)}/>
                <PrivateRoute exact path="/accounting/reconciliations"
                              component={(props) => getComponent(AccountingReconciliationsView, props)}/>
                <PrivateRoute exact path="/accounting/reconciliation/:id"
                              component={(props) => getComponent(AccountingReconciliationView, props)}/>
                <PrivateRoute exact path="/accounting-credit-cards"
                              component={(props) => getComponent(CreditAccountsPageView, props)}/>
                <PrivateRoute exact path="/accounting/credit-cards/:id"
                              component={(props) => getComponent(TransactionDetailsView, props)}/>
                <PrivateRoute exact path="/money-codes"
                              component={(props) => getComponent(MoneyCodesPage, props)}/>

                {/*General routes*/}
                <PrivateRoute exact path="/account" component={(props) => getComponent(AccountView, props)}/>
                <PrivateRoute exact path="/articles" component={(props) => getComponent(ArticlesView, props)}/>
                <PrivateRoute exact path="/users-report"
                              component={(props) => getComponent(UsersReportView, props)}/>
                <PrivateRoute exact path="/ticketing" component={(props) => getComponent(TicketsView, props)}/>
                <PrivateRoute exact path="/settings" component={(props) => getComponent(SettingsView, props)}/>
                <PrivateRoute exact path="/documents" component={(props) => getComponent(DocumentsView, props)}/>
                <PrivateRoute exact path="/tools" component={(props) => getComponent(UiToolsView, props)}/>
                <PrivateRoute exact path="/logged-devices"
                              component={(props) => getComponent(LoggedDevicesView, props)}/>
                <PrivateRoute exact path="/my-profile" component={(props) => getComponent(Profiles, props)}/>
                <PrivateRoute exact path="/termsofuse" component={(props) => getComponent(TermsOfUse, props)}/>
                <NeutralRoute component={(props) => getComponent(NotFoundView, props)}/>
            </Switch>

            <Notifications
                history={history} dispatch={store.dispatch} {...store.getState()}
                translate={(key, args) => translator.translate(key, args)}
                generateNotificationBaseOnTitle={generateCustomNotification}
            />

            <TaskGrabData
                dispatch={store.dispatch}
            />

            <AIChat
                translate={(key, args) => translator.translate(key, args)}
            />
        </Router>
    );
}
