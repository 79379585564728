import React, {useState} from 'react'
import Layout from "../../../common/components/layout";
import NavResponsive from "../../../common/components/nav-responsive";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import GrossReportTab from "./gross-report-tab";
import {processTabs} from "../../../common/util/util-helpers";
import Resources from "../../../data/services/resources";
import TrucksGrossReportTab from "./trucks-gross-report-tab";
import LateLoadsReportTab from "./late-loads-report-tab";
import Page from "../../../common/components/layout/layout-components/page";
import CoveredGrossReportTab from "./covered-gross-report-tab";
import ReportTab from "../../../common/components/tabs/report-tab";
import {DEFAULT_METADATA_SELECT_SEARCH_QUERY} from "../../../util/util-constants";
import {Field} from "../../../data/services/fields";
import moment from "moment";
import InfoBar from "../../../common/components/info-paragraph/info-bar";
import CellButton from "../../../common/components/resource-table/table-components/cell-button";
import {showModal} from "../../../data/actions/ui";
import {useDispatch} from "react-redux";

const DispatchReportsView = (props) => {
    const dispatch = useDispatch();

    function getTabs() {
        return processTabs([
            {name: 'GrossReport', resource: Resources.UnitReport},
            {name: 'TrucksGrossReport', resource: Resources.TrucksGrossReport},
            {name: 'CoveredGrossReport', resource: Resources.CoveredGrossReport},
            {name: 'BookedGrossReport', resource: Resources.BookedGrossReport},
            {name: 'LateLoadsReport', resource: Resources.LateLoadsReport},
        ]);
    }

    function handleTabChange(resource) {
        setTabs(
            tabs.map((it) => {
                it.current = it.resource === resource;
                return it
            })
        )
    }

    const [tabs, setTabs] = useState(getTabs())
    const currentTab = tabs.find(tab => tab.current)

    return (
        <Layout {...props}>
            <Page>
                <PageHeader
                    title={props.translate("page.heading.reports")}
                    titleClass="mb-5 text-2xl ml-4"
                />
                <div className="mb-5">
                    <NavResponsive
                        tabs={tabs}
                        onTabChange={handleTabChange}
                        translate={props.translate}
                    />
                </div>

                {currentTab.resource === Resources.UnitReport && (
                    <GrossReportTab
                        translate={props.translate}
                        pagePath={"gross-report"}
                    />
                )}

                {currentTab.resource === Resources.TrucksGrossReport && (
                    <TrucksGrossReportTab
                        translate={props.translate}
                        pagePath={"trucks-gross-report"}
                    />
                )}

                {currentTab.resource === Resources.CoveredGrossReport && (
                    <CoveredGrossReportTab
                        translate={props.translate}
                        pagePath={"covered-gross-report"}
                    />
                )}

                {currentTab.resource === Resources.BookedGrossReport && (
                    <ReportTab
                        beforeActiveFilters={
                            <InfoBar>RPM is calculated using both total and empty miles divided by
                                total invoice amount, including line haul and accessorial price.</InfoBar>
                        }
                        tableKey={'BookedByID'}
                        getFields={() => {
                            return {
                                BookedByID: new Field('BookedByID', '', [''], false, 'text', {
                                    render: (item) => {
                                        return <CellButton
                                            onClick={() => {
                                                dispatch(showModal('ViewContactCard', {ContactID: item?.BookedByID}))
                                            }}
                                        >
                                            {item.BookedBy}
                                        </CellButton>
                                    },
                                    addContainerClass: 'col-span-full sm:col-span-6 lg:col-span-3',
                                    omitSort: true
                                }),
                                'AmountGross': new Field('AmountGross', '', [''], false, 'money', {
                                    addContainerClass: 'col-span-full sm:col-span-6 lg:col-span-3',
                                    omitSort: true
                                }),
                                'AmountGrossAccesorial': new Field('AmountGrossAccesorial', '', [''], false, 'money', {
                                    addContainerClass: 'col-span-full sm:col-span-6 lg:col-span-3',
                                    omitSort: true
                                }),
                                'AmountExpenses': new Field('AmountExpenses', '', [''], false, 'money', {
                                    addContainerClass: 'col-span-full sm:col-span-6 lg:col-span-3',
                                    omitSort: true
                                }),
                                'AmountNet': new Field('AmountNet', '', [''], false, 'money', {
                                    addContainerClass: 'col-span-full sm:col-span-6 lg:col-span-3',
                                    omitSort: true
                                }),
                                'EmptyMiles': new Field('EmptyMiles', '', [''], false, 'text', {
                                    addContainerClass: 'col-span-full sm:col-span-6 lg:col-span-3',
                                    omitSort: true
                                }),
                                'TotalMiles': new Field('TotalMiles', '', [''], false, 'text', {
                                    addContainerClass: 'col-span-full sm:col-span-6 lg:col-span-3',
                                    omitSort: true
                                }),
                                'RPM': new Field('RPM', '', [''], false, 'text', {
                                    addContainerClass: 'col-span-full sm:col-span-6 lg:col-span-3',
                                    omitSort: true
                                }),
                                'LoadRefs': new Field('LoadRefs', '', [''], false, 'text', {
                                    addContainerClass: 'col-span-full sm:col-span-6 lg:col-span-3',
                                    omitSort: true
                                }),
                            }
                        }}
                        hasDateFilters={true}
                        additionalQuery={{
                            StartDate: new Field('StartDate', moment().subtract('month', 1).format('YYYY-MM-DD HH:mm:ss'), [], false, 'date', {}, {}),
                            EndDate: new Field('EndDate', moment().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss'), [], false, 'date', {}, {}),
                            DateType: new Field('DateType', 1, [''], false, 'button-group', {
                                data: {1: 'Pickup', 2: 'Delivery'},
                                addClass: 'w-full',
                                addButtonClass: 'uppercase font-semibold',
                                addContainerClass: 'col-span-2',
                                labelType: 'stack',
                                activeFilterLabel: "filter_pickup_date"
                            }, {}),
                            BookedByIDs: new Field('BookedByIDs', '', [''], false, 'select-search', {}, {
                                multi: true,
                                all: true
                            }),
                        }}
                        resourcePath={currentTab.resource}
                        storagePath={currentTab.name}
                        selects={{
                            BookedByIDs: {
                                api: 'api/' + Resources.ContactsQuick,
                                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                searchMap: (it) => ({
                                    label: it.FirstName + ' ' + it.LastName + ' - ' + it.Email,
                                    value: it.ContactID
                                })
                            },
                        }}
                        translate={props.translate}
                    />
                )}

                {currentTab.resource === Resources.LateLoadsReport && (
                    <LateLoadsReportTab
                        translate={props.translate}
                        pagePath={"late-loads-report"}
                    />
                )}
            </Page>
        </Layout>
    )
}

export default DispatchReportsView