import React, {Component} from 'react'
import {connect} from 'react-redux'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {download} from '../../../data/actions/download'
import {
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    returnOffsetAndPagination,
    saveTableColumns,
} from '../../../common/util/util-helpers'
import {Field, FieldsManager} from '../../../data/services/fields'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DELETE_PERM,
    FUEL_SURCHARGE_MATRIX_TYPES,
    UPDATE_PERM
} from '../../../util/util-constants'
import {checkPerm, getProp, mergeDeep} from '../../../common/util/util-helpers'
import {currentDate} from '../../../common/util/util-dates'
import FuelSurchargeCreateUpdateModal from './fuel-surcharge-create-update-modal'
import {
    createDataSelect,
    deleteDataSelect,
    getDataSelect,
    updateDataSelect
} from '../../../data/selectors/resourceSelectors'
import {cloneDeep, genericMoneyFormatter} from '../../../common/util/util-vanilla'
import moment from 'moment-timezone'
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import Modal from "../../../common/components/modal";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import InfoParagraph from "../../../common/components/info-paragraph";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import ResourceTable from "../../../common/components/resource-table";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import ButtonIcon from "../../../common/components/button/button-icon";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import Button from "../../../common/components/button";
import {DEFAULT_QUERY_LIMIT} from "../../../common/util/util-consts";

class FuelSurchargeMatrixTab extends Component {
    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1) + "_fuel_surcharge_tab"

        this.state = {
            // Defaults and filters
            ...DEFAULT_CRUD_STATE,
            sortBy: '',

            tableOptions: getDefaultTableOptions(this.getFields(), {}, this.pagePath, this.props.translate),
            queryFilterFields: this.getQueryFilterFields(),

            // Dialogs
            confirmDialog: false,
            isCreateUpdateModalOpen: false,
            selectedItem: null,
            isColumnsModalOpen: false,
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps) {

        if (prevProps.breakpoint !== this.props.breakpoint && this.props.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
            this.handleFilterInputChange('limit', 10);
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.getData({
            resource: this.getResource(),
            query: this.getQuery()
        })
    }

    downloadExcel = () => {
        this.props.downloadExcel({
            query: Object.assign({
                format: 'EXCEL',
                name: 'fuel_surcharge_' + currentDate() + '.xlsx',
            }, this.getQuery()),
            resource: this.getResource()
        })
    }

    archiveResource = (item) => {

        this.setState({text: `${this.props.translate("message.are_you_sure_delete_resource")}?`}, () => {
            this.handleShowConfirmDialog(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                    confirmDialog: false
                }, () => {
                    this.props.deleteData({
                        query: {
                            FuelSurchargeMatrixID: item.FuelSurchargeMatrixID
                        },
                        piggyQuery: this.getQuery(),
                        errorMessage: true, successMessage: `${item.FuelSurchargeMatrix} has been deleted`,
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    })
                })
            }, item)
        })
    }

    /** UI Events
     ================================================================= */
    handleShowConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    handleToggleCreateUpdateModal = (item = null) => {
        this.setState({
            selectedItem: item,
            isCreateUpdateModalOpen: !this.state.isCreateUpdateModalOpen
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            isColumnsDialogVisible: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate);
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    /** Helpers
     ================================================================= */
    getFields = (item = {}) => {
        const fieldTemplates = {
            FuelSurchargeMatrix: new Field('FuelSurchargeMatrix', '', ['empty'], false, 'text'),
            MatrixTypeID: new Field('MatrixTypeID', '', ['empty'], false, 'custom', {
                render: (item) => <div>{FUEL_SURCHARGE_MATRIX_TYPES[item.MatrixTypeID]}</div>
            })
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, Object.keys(this.getQuery()).map(it => it))
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {
                menuPlacement: "top",
                hasPortal: true
            })
        }
    }

    getResource = () => {
        return Resources.FuelSurcharge
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props
        const isLoading = resource.isLoading;

        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)

        const AverageFuelPrice = getProp(this.props.resource, 'data.price', [0, 0])[1]
        const AverageFuelPriceDate = moment(getProp(this.props.resource, 'data.price', [0, 0])[0], 'YYYYMMDD').format(LocalStorage.get('user')?.Contact?.DateTimeFormat?.split(' ')[0]) ?? ''

        return (
            <>
                <div className={"flex items-center"}>
                    <div>
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleClearFiltersClick}
                            translate={translate}
                        />
                    </div>

                    <div className="ml-auto flex">
                        <ButtonIcon
                            tooltip={translate('text.download_excel')}
                            isLoading={isLoading}
                            onClick={this.downloadExcel}
                        >
                            <DocumentArrowDownIcon className="w-5 h-5"/>
                        </ButtonIcon>

                        <TableSettingsPopOver
                            options={this.state.tableOptions}
                            setOptions={this.setOptions}
                            toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                            translate={translate}
                        />

                        <Button
                            onClick={() => this.handleToggleCreateUpdateModal()}
                            hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                            appearance={'primary'}
                        >
                            {translate('btn.create_new')}
                        </Button>
                    </div>
                </div>


                <TableCard addClass={'relative'}>
                    <TableFilters
                        hideLimit
                        filterFields={this.state.queryFilterFields}
                        handleInputChange={this.handleFilterInputChange}
                        translate={translate}
                        onRefreshTable={this.fetchData}
                        isLoading={resource.isLoading}
                    />

                    {!resource.isLoading && (
                        <div className="m-1">
                            <InfoParagraph type="note"
                                           message={translate('message.AverageFuelPrice') + genericMoneyFormatter(AverageFuelPrice) + ' - ' + AverageFuelPriceDate}
                            />
                        </div>
                    )}

                    <ResourceTable
                        data={data}
                        fields={this.getFields()}
                        verticalTableIsVisible={this.props.breakpoint.index <= 1}

                        translate={translate}
                        isLoading={resource.isLoading}

                        options={this.state.tableOptions}

                        limit={this.state.queryFilterFields.limit.value}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        onRowClick={checkPerm(this.getResource(), UPDATE_PERM) ? this.handleToggleCreateUpdateModal : null}

                        onEdit={(it) => this.handleToggleCreateUpdateModal(it)}
                        onDelete={this.archiveResource}

                        hasEditPerm={checkPerm(this.getResource(), UPDATE_PERM)}
                        hasDeletePerm={checkPerm(this.getResource(), DELETE_PERM)}
                    />

                    {/*Table footer*/}
                    {!(!data.length && !resource.isLoading) && (
                        <TableCardFooter>
                            <Pagination
                                count={count}
                                isLoading={resource.isLoading}
                                hideRowsPerPage={this.props.breakpoint.index <= 1}
                                handleQueryChange={
                                    (name, value, currentPage) => name === "offset"
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                            />
                        </TableCardFooter>
                    )}

                    <NoRecordsTable
                        show={(data.length === 0) && !resource.isLoading}
                        canCreate={checkPerm(this.getResource(), CREATE_PERM)}
                        title={'No matching records found'}
                        text={'Create a fuel surcharge'}
                        btnLabel="Create fuel surcharge"
                        onBtnClick={() => {
                            this.setState({prefilled: this.state.queryFilterFields.query.value}, () => {
                                this.handleToggleCreateUpdateModal()
                            })
                        }}
                        onClearFilterClick={this.handleClearFiltersClick}
                        clearFilterBtnLabel={translate('text.clear_all_filters')}
                        clearFilterText={translate('text.try_without_filters')}
                        filters={this.state.queryFilterFields}
                    />
                </TableCard>


                <TableOptionsDialog
                    show={this.state.isColumnsDialogVisible}
                    pagePath={this.pagePath}
                    columns={this.state.tableOptions.columns}
                    setTableColumnOptions={this.handleSetTableColumnOptions}
                    getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                    onClose={this.handleToggleColumnSettingsDialog}
                    translate={translate}
                />

                <Modal
                    show={this.state.isCreateUpdateModalOpen}
                    onClose={() => this.handleToggleCreateUpdateModal()}
                    widthClass={'w-auto'}
                >
                    <FuelSurchargeCreateUpdateModal
                        translate={translate}
                        onClose={() => this.handleToggleCreateUpdateModal()}
                        selectedItem={this.state.selectedItem}
                        prefilled={this.state.prefilled}
                        AverageFuelPrice={AverageFuelPrice}

                        onCreateData={this.props.createData}
                        onUpdateData={this.props.updateData}

                        resourceName={this.getResource()}
                        piggyResourceName={this.getResource()}
                        query={this.getQuery()}
                    />
                </Modal>

                <ModalConfirm
                    title={'Confirm delete'}
                    show={!!this.state.confirmDialog}
                    text={this.state.text}
                    onClose={() => this.setState({confirmDialog: false})}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.state.confirmModal}
                />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...state, // prinudno resenje za sad, posto globalni dijalozi zahtevaju i druge resurse
        resource: state.resource,
        ui: state.ui,
    }
}

const mapDispatchToProps = (dispatch) => {
    const user = LocalStorage.get('user')

    return {
        dispatch: dispatch, // prinudno resenje za sad, posto globalni dijalozi zahtevaju i druge dispatch-e
        getData: getDataSelect({dispatch, user}),
        deleteData: deleteDataSelect({dispatch, user}),
        createData: createDataSelect({dispatch, user}),
        updateData: updateDataSelect({dispatch, user}),

        downloadExcel: ({query, resource}) => {
            dispatch(download({
                user: user,
                query: query,
                resource: resource
            }))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FuelSurchargeMatrixTab)
