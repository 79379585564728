import React, {Component} from 'react'
import LocalStorage from '../../../util/localStorage'
import {Field, FieldsManager} from '../../../data/services/fields'
import {checkPerm, classNames, getProp, returnOffsetAndPagination} from '../../../common/util/util-helpers'
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import {
    createSecondResource,
    deleteSecondResource,
    getSecondResource,
    updateSecondResource
} from "../../../data/actions/secondResource";
import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DEFAULT_DATABASE_DATETIME_FORMAT,
    DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS,
    DELETE_PERM,
    UPDATE_PERM
} from "../../../util/util-constants";
import {fillFieldsFromData} from '../../../common/util/util-fields';
import Resources from "../../../data/services/resources";
import Env from "../../../util/env";
import moment from "moment-timezone";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import {ArrowPathIcon} from "@heroicons/react/24/outline";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import ResourceTable from "../../../common/components/resource-table";
import ModalSaveResourceWithDropZone from "../../../common/components/modal/modal-save-resource-with-drop-zone";
import FileList from "../../../common/components/dropzone/file-list";
import {getJWT} from "../../../common/util/util-auth";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import {DEFAULT_QUERY_LIMIT} from "../../../common/util/util-consts";

export default class CertificationsTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...DEFAULT_CRUD_STATE,
            queryFilterFields: this.getQueryFilterFields(),

            //Files
            files: [],
            existingFiles: [],
            previewShow: false,
            filePreview: {
                show: false,
                fileType: "",
                filePath: ""
            },

            // Modals
            createModalOpen: false,
            deleteModalOpen: false,
            editModalOpen: false,

            canSubmitModal: false
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    addNewCertification = (fields) => {
        this.props.dispatch(createSecondResource({
            user: LocalStorage.get('user'),
            params: Object.assign({}, fields,
                {
                    id: this.getCarrierID()
                }),
            query: this.getQuery(),
            resource: this.getResource(),
            piggyResource: this.getResource(),
            file: this.state.files,
            fileResource: this.getFileResource(),
            errorMessage: true, successMessage: this.props.translate('message.certification_added')
        }))
        this.setState({files: []})
        this.handleCloseCreateModal()
    }

    removeCertification = () => {
        this.setState({
            ...returnOffsetAndPagination(getProp(this.props.secondResource.data, 'list', []), this.getQuery(), this.state.paginationPage),
        }, () => {
            this.props.dispatch(deleteSecondResource({
                user: LocalStorage.get('user'),
                query: {
                    CarrierCertsID: this.state.selectedItem.CarrierCertsID,
                    id: this.getCarrierID()
                },
                resource: this.getResource(),
                secondPiggyResource: this.getResource(),
                secondPiggyQuery: this.getQuery(),
                errorMessage: true, successMessage: this.props.translate('message.certification_deleted')
            }))
            this.handleCloseConfirmDeleteModal()
        })
    }

    editCertification = (params) => {
        params.CarrierCertsID = this.state.selectedItem.CarrierCertsID
        params.id = this.getCarrierID()

        if (!!this.state.selectedItem.DocumentPath && !this.state.existingFiles.length && !this.state.files?.length) {
            params.DocumentPath = "1"; // delete document
        }

        this.props.dispatch(updateSecondResource({
            user: LocalStorage.get('user'),
            params: params,
            query: this.getQuery(),
            resource: this.getResource(),
            piggyResource: this.getResource(),
            fileParams: {
                id: this.state.selectedItem.CarrierCertsID
            },
            file: this.state.files,
            fileResource: this.getFileResource(),
            errorMessage: true, successMessage: this.props.translate('message.certification_updated'),
        }))
        this.handleCloseEditModal()
    }

    /** Fields/data definitions
     ================================================================= */
    getFields = (item = null) => {
        let fieldTemplates = {
            CarrierCertsTypeID: new Field('CarrierCertsTypeID', '', ['empty'], false, 'select', {addContainerClass: 'col-span-full'}),
            Notes: new Field('Notes', '', [], false, 'textarea', {addContainerClass: 'col-span-full'}),
            ExpiryDate: new Field('ExpiryDate', '', [], false, 'date', {addContainerClass: 'col-span-full'})
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true, labelType: 'float'})
        }
    }

    /** UI Events
     ================================================================= */
    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, this.fetchData)
    }

    handleFilterInputChange = (name, value) => {
        const queryFilterFields = FieldsManager.updateField(this.state.queryFilterFields, name, value)

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, this.fetchData)
    }

    handleOpenCreateModal = () => {
        this.setState({
            createModalOpen: true,
            previewShow: false
        })
    }

    handleCloseCreateModal = () => {
        this.setState({
            createModalOpen: false
        })
    }

    handleOpenEditModal = (item = null) => {
        this.setState({
            selectedItem: item,
            files: [],
            existingFiles: item?.DocumentPath ? [item?.DocumentPath] : [],
            editModalOpen: true,
            canSubmitModal: false
        })
    }

    handleCloseEditModal = () => {
        this.setState({
            selectedItem: null,
            editModalOpen: false
        })
    }

    handleOpenConfirmDeleteModal = (item = null) => {
        this.setState({
            selectedItem: item,
            deleteModalOpen: true
        })
    }

    handleCloseConfirmDeleteModal = () => {
        this.setState({
            selectedItem: null,
            deleteModalOpen: false
        })
    }

    handleAcceptFiles = (files) => {
        this.setState({
            files: files,
            canSubmitModal: true
        });
    }

    handleFileView = (file, hasFilePath) => {
        this.setState({
            filePreview: {
                show: true,
                fileType: file.path.split(".").pop(),
                filePath: hasFilePath
                    ? Env.getApiUrl("api/" + Resources.CarrierCertificationsDocument, Object.assign({}, {
                        CarrierCertsID: this.state.selectedItem?.CarrierCertsID,
                        token: getJWT().access_token,
                        date: moment(this.state.selectedItem.CreateUpdateDate, DEFAULT_DATABASE_DATETIME_FORMAT).unix(),
                    }))
                    : URL.createObjectURL(file)
            }
        })
    }

    closePreview = () => {
        this.setState({
            filePreview: {
                show: false,
                fileType: "",
                filePath: ""
            }
        })
    }

    /** Helpers
     ================================================================= */
    getCarrierID = () => {
        return this.props.id
    }

    getResource = () => {
        return this.props.resourceName
    }

    getFileResource = () => {
        return Resources.CarrierCertificationsDocument
    }

    getQuery = () => {
        return {
            id: this.getCarrierID(),
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props

        const data = getProp(this.props.secondResource, 'data.list', [])
        const count = getProp(this.props.secondResource, 'data.count', 0)
        const isLoading = getProp(this.props.secondResource, 'isLoading', false)

        return (
            <div>
                <div className={"mb-2 ml-4"}>
                    <PageHeader
                        buttonLabel={translate('btn.create_new')}
                        onButtonClick={this.handleOpenCreateModal}
                        hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                    />
                </div>

                <TableCard>
                    <div className={"flex justify-end p-5 border-tm-gray-300 border-b"}>
                        <button className="btn btn-header" onClick={this.fetchData}>
                            <ArrowPathIcon className={
                                classNames(
                                    "w-5 h-5",
                                    isLoading ? "animate-spin" : undefined,
                                )
                            }/>
                        </button>
                    </div>

                    <ResourceTable
                        addClass="rounded-card"
                        data={data}
                        count={count}

                        commonTable={true}

                        fields={this.getFields()}
                        translate={translate}
                        isLoading={isLoading}

                        onDelete={checkPerm(this.getResource(), DELETE_PERM) ? this.handleOpenConfirmDeleteModal : null}
                        onEdit={checkPerm(this.getResource(), UPDATE_PERM) ? this.handleOpenEditModal : null}
                    />

                    {/*Table footer*/}
                    <TableCardFooter show={!(!data.length && !isLoading)}>
                        <Pagination
                            count={count}
                            isLoading={isLoading}
                            handleQueryChange={
                                (name, value, currentPage) => name === "offset"
                                    ? this.handleUpdateOffset(value, currentPage)
                                    : this.handleFilterInputChange(name, value)
                            }
                            pageOffset={this.state.offset}
                            queryFields={this.state.queryFilterFields}
                            translate={translate}
                        />
                    </TableCardFooter>

                    <NoRecordsTable
                        show={!!this.getCarrierID() && (data.length === 0) && !isLoading}
                        canCreate={false}
                        title={translate('text.no_matching_records')}
                        className="pt-12 px-6 pb-6"
                    />

                    {/* Create */}
                    <ModalSaveResourceWithDropZone
                        show={this.state.createModalOpen}
                        title={translate('text.AddNewCertification')}
                        widthClass="max-w-md"
                        gridColsClass="grid-cols-3"
                        onClose={this.handleCloseCreateModal}
                        fields={this.getFields()}
                        onAcceptFiles={(files) => this.handleAcceptFiles(files)}
                        maxFilesAccepted={1}
                        fileUploadInfoText={"Max file size 25 MB."}
                        files={this.state.files}
                        accept={DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS}
                        filePreview={this.state.filePreview}
                        onClosePreview={this.closePreview}

                        onSubmit={(params) => {
                            if (params) {
                                this.addNewCertification(params)
                            }
                        }}

                        translate={translate}

                        htmlBefore={
                            <React.Fragment>
                                {!!this.state.files.length && (
                                    <div className="px-6 pt-6">
                                        <h2
                                            className="flex items-center text-sm font-semibold text-tm-gray-900 h-5 whitespace-nowrap"
                                        >
                                            Files
                                        </h2>

                                        <FileList
                                            files={this.state.files}
                                            onFileDelete={() => this.setState({
                                                files: []
                                            })}
                                            onFileView={(file) => this.handleFileView(file)}
                                        />
                                    </div>
                                )}
                            </React.Fragment>
                        }
                    />

                    {/* Edit */}
                    <ModalSaveResourceWithDropZone
                        show={this.state.editModalOpen}
                        title={translate('text.EditCertification')}
                        widthClass="max-w-md"
                        gridColsClass="grid-cols-3"
                        onClose={this.handleCloseEditModal}
                        fields={this.getFields(this.state.selectedItem)}
                        onAcceptFiles={(files) => this.handleAcceptFiles(files)}
                        maxFilesAccepted={1}
                        fileUploadInfoText={"Max file size 25 MB."}
                        files={this.state.files}
                        documentPaths={this.state.existingFiles}
                        accept={DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS}
                        filePreview={this.state.filePreview}
                        onClosePreview={this.closePreview}
                        canSubmit={this.state.canSubmitModal}

                        onSubmit={(params) => {
                            if (params) {
                                this.editCertification(params)
                            }
                        }}

                        translate={translate}
                        htmlBefore={
                            <React.Fragment>
                                {(!!this.state.files.length || !!this.state.existingFiles?.length) && (
                                    <div className="px-6 pt-6 space-y-4">
                                        {!!this.state.existingFiles?.length && (
                                            <div>
                                                <h2
                                                    className="flex items-center text-sm font-semibold text-tm-gray-900 h-5 whitespace-nowrap"
                                                >
                                                    Files
                                                </h2>

                                                <FileList
                                                    filePaths={this.state.existingFiles}
                                                    onFileDelete={() => {
                                                        this.setState({
                                                            existingFiles: [],
                                                            canSubmitModal: true
                                                        })
                                                    }}
                                                    onFileView={(file) => this.handleFileView(file, this.state?.selectedItem?.DocumentPath)}
                                                />
                                            </div>
                                        )}

                                        {!!this.state.files?.length && (
                                            <div>
                                                <h2
                                                    className="flex items-center text-sm font-semibold text-tm-gray-900 h-5 whitespace-nowrap"
                                                >
                                                    Files to upload
                                                </h2>

                                                <FileList
                                                    files={this.state.files}
                                                    onFileDelete={() => this.setState({
                                                        files: []
                                                    })}
                                                    onFileView={(file) => this.handleFileView(file, this.state?.selectedItem?.DocumentPath)}
                                                />
                                            </div>
                                        )}

                                        <span className="text-xs text-tm-gray-600">
                                                {translate("text.one_document_allowed")}
                                            </span>
                                    </div>
                                )}
                            </React.Fragment>
                        }
                    />

                    {/* Delete */}
                    <ModalConfirm
                        title={'Confirm'}
                        show={this.state.deleteModalOpen}
                        text={translate('message.are_you_sure_delete_certification')}
                        onClose={this.handleCloseConfirmDeleteModal}
                        buttonLabel={translate('btn.confirm')}
                        closeButtonLabel={'Cancel'}
                        translate={translate}
                        onConfirm={this.removeCertification}
                    />
                </TableCard>
            </div>
        )
    }
}
