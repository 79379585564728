import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {download} from '../../../data/actions/download'
import {
    checkPerm,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getLookup,
    getProp,
    mergeDeep,
    returnOffsetAndPagination,
    saveTableColumns,
} from '../../../common/util/util-helpers'
import {Field, FieldsManager} from '../../../data/services/fields'
import Tippy from '@tippyjs/react'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DELETE_PERM,
    feeTypes,
    LINE_OF_BUSINESS_PRIMARY_CARRIER,
    LINE_OF_BUSINESS_PRIMARY_DRIVER,
    MAJOR_ACCOUNT_TYPE_EXPENSE,
    MAJOR_ACCOUNT_TYPE_INCOME,
    UPDATE_PERM
} from '../../../util/util-constants'
import {currentDate} from '../../../common/util/util-dates'
import {cloneDeep} from '../../../common/util/util-vanilla'
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import ResourceTable from "../../../common/components/resource-table";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import Button from "../../../common/components/button";
import {DEFAULT_QUERY_LIMIT} from "../../../common/util/util-consts";

class AccessorialsTemplatesTab extends Component {
    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1) + "_accessorial_tab"

        this.state = {
            // Defaults and filters
            ...DEFAULT_CRUD_STATE,
            sortBy: 'Accessorial',

            tableOptions: getDefaultTableOptions(this.getFields(), {}, this.pagePath, this.props.translate),
            queryFilterFields: this.getQueryFilterFields(),

            // Dialogs
            confirmDialog: false,
            isAccessorialModalOpen: false,
            isColumnsModalOpen: false,
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps) {

        if (prevProps.breakpoint !== this.props.breakpoint && this.props.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
            this.handleFilterInputChange('limit', 10);
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign({format: 'EXCEL', name: 'commodities_' + currentDate() + '.xlsx'}, this.getQuery())
        }))
    }

    archiveResource = (item) => {
        this.setState({text: `${this.props.translate("message.are_you_sure_delete_accessorial")} ${item.Accessorial}?`}, () => {
            this.handleShowConfirmDialog(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(deleteResource({
                        user: LocalStorage.get('user'),
                        query: {
                            AccessorialID: item.AccessorialID,
                        },
                        piggyQuery: this.getQuery(),
                        errorMessage: true, successMessage: `${item.Accessorial} has been deleted`,
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    restoreFromArchive = (item) => {
        this.setState({text: this.props.translate('message.confirm_restore_generic') + ` ${item.Accessorial}?`}, () => {
            this.handleShowConfirmDialog(() => {
                this.setState({confirmDialog: false}, () => {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: {
                            AccessorialID: item.AccessorialID,
                            ArchivedDate: 1
                        },
                        errorMessage: true, successMessage: `${item.AccountName} account restored`,
                        query: this.getQuery(),
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    /** UI Events
     ================================================================= */
    handleShowConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    handleCreateAccessorialClick = () => {
        this.setState({
            selectedItem: null,
            isAccessorialModalOpen: true
        })
    }

    handleToggleAccessorialDialog = (selectedItem = this.state.selectedItem) => {
        this.setState({
            selectedItem: selectedItem,
            isAccessorialModalOpen: !this.state.isAccessorialModalOpen
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleInputChange = (fields, name, value) => {
        let fieldsUpdate = cloneDeep(fields)

        if ('IsPayDriver' === name) {
            fieldsUpdate.FeeTypeID.type = value ? 'select' : 'hidden'
            fieldsUpdate.FeeTypeID.validate = value ? ['empty'] : ['']
            fieldsUpdate.FeeTypeID.metadata.hideDialog = !value
            fieldsUpdate.PayDriverAmount.type = value ? 'money' : 'hidden'
            fieldsUpdate.PayDriverAmount.validate = value ? ['float'] : ['']
            fieldsUpdate.PayDriverAmount.metadata.hideDialog = !value
            fieldsUpdate.ExpenseAccountID.type = value ? 'select-search' : 'hidden'
            fieldsUpdate.ExpenseAccountID.validate = value ? ['empty'] : ['']
            fieldsUpdate.ExpenseAccountID.metadata.hideDialog = !value
        }

        if ('Deduction' === name && !this.state.selectedItem) {
            fieldsUpdate.IncomeAccountID.props.key = fieldsUpdate.IncomeAccountID.props.key + 1
            fieldsUpdate.IncomeAccountID.props.query = {MainAccountTypeID: Number(value) === 1 ? MAJOR_ACCOUNT_TYPE_EXPENSE : MAJOR_ACCOUNT_TYPE_INCOME}
        }

        fieldsUpdate = FieldsManager.updateField(fieldsUpdate, name, value)

        return fieldsUpdate
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            isColumnsDialogVisible: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate);
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }
    /** Helpers
     ================================================================= */
    getFields = (item = {}) => {
        const IsPayDriver = item?.IsPayDriver

        const fieldTemplates = {
            Accessorial: new Field('Accessorial', '', ['empty'], false, 'text', {
                addContainerClass: 'col-span-6'
            }),
            Deduction: new Field('Deduction', '', [''], false, 'button-group', {
                data: {0: this.props.translate('btn.charge'), 1: this.props.translate('btn.deduction')},
                addContainerClass: 'col-span-6',
                hideLabel: true,
                hideTable: true
            }),
            Amount: new Field('Amount', '', ['money'], false, 'money', {addContainerClass: 'col-span-full'}, {}),
            InvoiceSumTypeID: new Field('InvoiceSumTypeID', '', [''], false, 'select', {
                addContainerClass: 'col-span-full'
            }),
            IncomeAccountID: new Field('IncomeAccountID', '', [''], false, 'select-search', {
                addContainerClass: 'col-span-full'
            }, {
                key: 0, query: {
                    MainAccountTypeID: Number(item?.Deduction) === 1 ? MAJOR_ACCOUNT_TYPE_EXPENSE : MAJOR_ACCOUNT_TYPE_INCOME
                },
            }),
        }

        if ((getProp(LocalStorage.get('user'), 'Contact.LineOfBusiness', []).includes(LINE_OF_BUSINESS_PRIMARY_DRIVER) && !!this.state?.selectedItem) || getProp(LocalStorage.get('user'), 'Contact.LineOfBusiness', []).includes(LINE_OF_BUSINESS_PRIMARY_CARRIER)) {
            fieldTemplates.IsPayDriver = new Field('IsPayDriver', false, [], false, 'checkbox', {
                label: 'IsPayDriver',
                note: 'Add driver pay entry',
                addContainerClass: 'col-span-full',
                hideTable: true
            })
            fieldTemplates.FeeTypeID = new Field('FeeTypeID', '', [''], false, 'select', {
                render: (item) => <div>{item?.FeeTypeID ? this.props.translate(feeTypes[item.FeeTypeID]) : ''}</div>,
                addContainerClass: 'col-span-full',
                hideDialog: !IsPayDriver,
            }),

                fieldTemplates.PayDriverAmount = new Field('PayDriverAmount', '', IsPayDriver ? ['float'] : [''], false, 'money', {
                    addContainerClass: 'col-span-full',
                    hideDialog: !IsPayDriver
                }),
                fieldTemplates.ExpenseAccountID = new Field('ExpenseAccountID', '', IsPayDriver ? ['empty'] : [''], false, 'select-search', {
                    addContainerClass: 'col-span-full',
                    hideDialog: !IsPayDriver
                })
        }

        fieldTemplates.AccessorialNotes = new Field('AccessorialNotes', '', [], false, 'textarea', {
            addContainerClass: 'col-span-full'
        })


        return fillFieldsFromData(fieldTemplates, item)
    }

    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'limit', 'query', 'offset', 'paginationPage', 'showArchived'])
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            archived: new Field('archived', '', [''], false, 'checkbox'),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {
                menuPlacement: "top",
                hasPortal: true
            })
        }
    }

    getResource = () => {
        return Resources.Accessorial
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props

        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)
        const InvoiceSumTypeID = getLookup('InvoiceSumType', 'InvoiceSumTypeID', 'InvoiceSumType')

        const metadata = {
            IncomeAccountID: {
                api: 'api/' + Resources.AccountsQuick,
                searchMap: (item) => ({
                    value: item.AccountID,
                    label: item.AccountNumber + ' ' + item.AccountName
                })
            },
            ExpenseAccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: {
                    MainAccountTypeID: MAJOR_ACCOUNT_TYPE_EXPENSE
                },
                searchMap: (item) => ({
                    value: item.AccountID,
                    label: item.AccountNumber + ' ' + item.AccountName
                })
            },
            InvoiceSumTypeID: InvoiceSumTypeID,
            FeeTypeID: Object.values(feeTypes).reduce((memo, it, i) => {
                memo[i + 1] = this.props.translate(it)
                return memo
            }, {})
        }

        return (
            <>
                <div className="flex items-center">
                    <div>
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleClearFiltersClick}
                            translate={translate}
                        />
                    </div>

                    <div className="ml-auto flex">
                        <Tippy content={translate('text.download_excel')}>
                            <button
                                className="btn-icon"
                                onClick={this.downloadExcel}
                            >
                                <DocumentArrowDownIcon className="w-5 h-5"/>
                            </button>
                        </Tippy>

                        <TableSettingsPopOver
                            options={this.state.tableOptions}
                            setOptions={this.setOptions}
                            toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                            translate={translate}
                        />

                        <Button
                            onClick={this.handleCreateAccessorialClick}
                            hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                            appearance={'primary'}
                        >
                            {translate('btn.create_new')}
                        </Button>
                    </div>
                </div>

                <TableCard addClass={'relative'}>
                    <TableFilters
                        hideLimit
                        filterFields={this.state.queryFilterFields}
                        handleInputChange={this.handleFilterInputChange}
                        translate={translate}
                        onRefreshTable={this.fetchData}
                        isLoading={resource.isLoading}
                    />

                    <ResourceTable
                        data={data}
                        fields={this.getFields()}
                        verticalTableIsVisible={this.props.breakpoint.index <= 1}

                        translate={translate}
                        isLoading={resource.isLoading}

                        options={this.state.tableOptions}

                        limit={this.state.queryFilterFields.limit.value}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        onRowClick={checkPerm(this.getResource(), UPDATE_PERM) ? (it) => this.handleToggleAccessorialDialog(it) : null}
                        onEdit={checkPerm(this.getResource(), UPDATE_PERM) ? (it) => this.handleToggleAccessorialDialog(it) : null}
                        onDelete={checkPerm(this.getResource(), DELETE_PERM) ? this.archiveResource : null}
                        onRestore={checkPerm(this.getResource(), UPDATE_PERM) ? this.restoreFromArchive : null}

                    />

                    {/*Table footer*/}
                    {!(!data.length && !resource.isLoading) && (
                        <TableCardFooter>
                            <Pagination
                                count={count}
                                isLoading={resource.isLoading}
                                hideRowsPerPage={this.props.breakpoint.index <= 1}
                                handleQueryChange={
                                    (name, value, currentPage) => name === "offset"
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                            />
                        </TableCardFooter>
                    )}

                    <NoRecordsTable
                        show={(data.length === 0) && !resource.isLoading}
                        canCreate={checkPerm(this.getResource(), CREATE_PERM)}
                        title={'No matching records found'}
                        text={'Create a accessorial'}
                        btnLabel="Create accessorial"
                        onBtnClick={() => {
                            this.handleCreateAccessorialClick({
                                Accessorial: this.state.queryFilterFields.query.value
                            })
                        }}
                        onClearFilterClick={this.handleClearFiltersClick}
                        clearFilterBtnLabel={translate('text.clear_all_filters')}
                        clearFilterText={translate('text.try_without_filters')}
                        filters={this.state.queryFilterFields}
                    />
                </TableCard>

                <TableOptionsDialog
                    show={this.state.isColumnsDialogVisible}
                    pagePath={this.pagePath}
                    columns={this.state.tableOptions.columns}
                    setTableColumnOptions={this.handleSetTableColumnOptions}
                    getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                    onClose={this.handleToggleColumnSettingsDialog}
                    translate={translate}
                />

                <ModalSaveResource
                    title={this.state.selectedItem ? translate('dialog_heading.edit_accessorial_template') : translate('dialog_heading.create_accessorial_template')}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-12"
                    show={this.state.isAccessorialModalOpen}
                    onClose={this.handleToggleAccessorialDialog}
                    fields={this.getFields(this.state.selectedItem)}
                    onSubmit={(params) => {
                        if (params) {
                            if (this.state.selectedItem) {
                                this.props.dispatch(updateResource({
                                    user: LocalStorage.get('user'),
                                    params: Object.assign(params, {AccessorialID: this.state.selectedItem?.AccessorialID}),
                                    query: this.getQuery(),
                                    resource: this.getResource(),
                                    piggyResource: this.getResource(),
                                    errorMessage: true, successMessage: translate('text.accessorial_template_updated')
                                }))
                            } else {
                                this.props.dispatch(createResource({
                                    user: LocalStorage.get('user'),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: this.getResource(),
                                    piggyResource: this.getResource(),
                                    errorMessage: true, successMessage: translate('text.accessorial_template_created')
                                }))
                            }
                            this.handleToggleAccessorialDialog()
                        }
                    }}
                    translate={translate}
                    metadata={metadata}
                    handleInputChange={this.handleInputChange}
                />

                <ModalConfirm
                    title={'Confirm'}
                    show={!!this.state.confirmDialog}
                    text={this.state.text}
                    onClose={() => this.setState({confirmDialog: false})}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.state.confirmModal}
                />
            </>
        )
    }
}

export default connect(state => state)(AccessorialsTemplatesTab)
