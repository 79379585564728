import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getResource, } from '../../../data/actions/resource'
import Resources from '../../../data/services/resources'
import {DEFAULT_CRUD_STATE, DEFAULT_QUERY_LIMIT} from "../../../common/util/util-consts";
import {
    getDefaultTableOptions,
    getDefaultTableOptionsJSON, getProp,
    mergeDeep,
    saveTableColumns
} from "../../../common/util/util-helpers";
import LocalStorage from "../../../common/util/localStorage";
import {Field, FieldsManager} from "../../../data/services/fields";
import {cloneDeep} from "../../../common/util/util-vanilla";
import Layout from "../../../common/components/layout";
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import ResourceTable from "../../../common/components/resource-table";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import TrackTimeInfo from "./dialogs/info-dialog";


class TrackTimeView extends Component {
    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1)

        this.state = {
            // Defaults and filters
            ...DEFAULT_CRUD_STATE,
            sortBy: 'Name',
            fields: this.getFields(),
            tableOptions: getDefaultTableOptions(this.getFields(), {}, this.pagePath, this.props.translate),
            queryFilterFields: this.getQueryFilterFields(),
            tableColumns: {},
            prefilled: '',
            isColumnsDialogVisible: false,

            // Dialogs
            confirmDialog: false,
            confirmDialogHideAnimation: false,
            breakpoint: {}
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    /** UI Events
     ================================================================= */
    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        }, () => {
            if (this.state.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
                this.handleFilterInputChange('limit', 10);
            }
        });
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleViewResource = (item = null) => {
        this.setState({selectedItem: item, infoDialog: !this.state.infoDialog})
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    setTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            isColumnsDialogVisible: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate);
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }
    /** Helpers
     ================================================================= */
    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {
                menuPlacement: "top",
                hasPortal: true
            })
        }
    }

    getFields = () => {
        return {
            Name: new Field('Name', '', ['empty']),
        }
    }

    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, Object.keys(this.getQuery()).map(it => it))
    }

    getResource = () => {
        return Resources.TrackTime
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props
        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)

        return (
            <Layout
                onBreakpoinChange={this.handleBreakpointChange}
                {...this.props}
            >
                <Page>
                    <PageHeader
                        title={translate('page.heading.time-tracking')}
                        afterTitle={(
                            <PageHeaderInfo
                                dispatch={this.props.dispatch}
                            />
                        )}
                    />

                    <div className="flex">
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleClearFiltersClick}
                            translate={translate}
                        />

                        <div className="ml-auto flex">
                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>
                    </div>

                    <TableCard addClass={'relative'}>
                        <TableFilters
                            hideLimit
                            filterFields={this.state.queryFilterFields}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                            onRefreshTable={this.fetchData}
                        />

                        <ResourceTable
                            data={data}
                            fields={this.getFields()}
                            verticalTableIsVisible={this.state.breakpoint.index <= 1}

                            translate={translate}
                            isLoading={resource.isLoading}

                            options={this.state.tableOptions}

                            limit={this.state.queryFilterFields.limit.value}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onRowClick={this.handleViewResource}
                            onView={this.handleViewResource}
                        />

                        {/*Table footer*/}
                        <TableCardFooter
                            show={!(!data.length && !resource.isLoading)}
                        >
                                <Pagination
                                    count={count}
                                    isLoading={resource.isLoading}
                                    hideRowsPerPage={this.state.breakpoint.index <= 1}
                                    handleQueryChange={
                                        (name, value, currentPage) => name === "offset"
                                            ? this.handleUpdateOffset(value, currentPage)
                                            : this.handleFilterInputChange(name, value)
                                    }
                                    pageOffset={this.state.offset}
                                    queryFields={this.state.queryFilterFields}
                                    translate={translate}
                                />
                        </TableCardFooter>

                        <NoRecordsTable
                            show={(data.length === 0) && !resource.isLoading}
                            canCreate={false}
                            title={'No matching records found'}

                            className={"pb-12 pt-16 px-6"}
                            onClearFilterClick={this.handleClearFiltersClick}
                            clearFilterBtnLabel={translate('text.clear_all_filters')}
                            clearFilterText={translate('text.try_without_filters')}
                            filters={this.state.queryFilterFields}
                        />
                    </TableCard>

                    {this.state.infoDialog && (
                        <TrackTimeInfo
                            {...this.props}
                            close={this.handleViewResource}
                            item={this.state.selectedItem}
                        />
                    )}

                    <TableOptionsDialog
                        show={this.state.isColumnsDialogVisible}
                        pagePath={this.pagePath}
                        columns={this.state.tableOptions.columns}
                        setTableColumnOptions={this.setTableColumnOptions}
                        getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                        onClose={this.handleToggleColumnSettingsDialog}
                        translate={translate}
                    />
                </Page>
            </Layout>
        )
    }
}

export default connect(state => state)(TrackTimeView)
