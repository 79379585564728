import React, {useMemo, useState} from "react";
import ChevronRightIcon from "@heroicons/react/24/outline/ChevronRightIcon";
import {checkPerm, classNames, getProp} from '../../../../common/util/util-helpers'
import {cloneDeep, genericMoneyFormatter} from "../../../../common/util/util-vanilla";
import {Field, FieldsManager as Fields, FieldsManager} from "../../../../data/services/fields";
import {useDispatch, useSelector} from 'react-redux'
import LocalStorage from '../../../../util/localStorage'
import Resources from '../../../../data/services/resources'
import {EyeIcon} from '@heroicons/react/20/solid'
import {getInfoResource} from "../../../../data/actions/infoResource";
import Subtitle from "../../../../common/components/layout/layout-components/page/subtitle";
import {LoaderLarge} from "../../../../common/components/loader";
import TableCard from "../../../../common/components/resource-table/table-components/table-card";
import ResourceTable from "../../../../common/components/resource-table";
import ModalDefault from "../../../../common/components/modal/modal-default";
import TableFooter from "../../../../common/components/resource-table/table/table-footer";
import Pagination from "../../../../common/components/resource-table/table-components/pagination";
import FieldSearch from "../../../../common/components/fields/field-text/search";
import {fillFieldsFromData} from "../../../../common/util/util-fields";
import {READ_PERM} from "../../../../util/util-constants";
import Card from "../../../../common/components/card";
import AdvancesDialog from "../../../../common/components/modal/advances-modal";
import {DEFAULT_QUERY_LIMIT} from "../../../../common/util/util-consts";

const tableOptions = {
    behaviour: {
        hasVirtualRows: false
    },
    style: {
        condensed: true,
        horizontalLines: false,
        verticalLines: true,
        stripedRows: true
    }
}

export default function DriverPayrollOpenPayEntries({
                                                        data = {},
                                                        unpaidItemsFields,
                                                        escrowBalancesFields,
                                                        loanBalancesFields,
                                                        translate,
                                                        toggleExpandSection,
                                                        openPayEntryFields,
                                                        openPayEntryData,
                                                        isSectionExpanded,
                                                        isLoading,
                                                        isContactAdvancesDialogOpen,
                                                        setIsContactAdvancesDialogOpen,
                                                        contact
                                                    }) {
    const dispatch = useDispatch();

    const [activeModal, setActiveModal] = useState();
    const [unpaidItemsFilterValue, setUnpaidItemsFilterValue] = useState("");
    const [escrowBalancesFilterValue, setEscrowBalancesFilterValue] = useState("");
    const [loanBalancesFilterValue, setLoanBalancesFilterValue] = useState("");

    const advancesResource = useSelector((state) => state.info);

    const hasOpenEntries = !!data?.UnpaidItems?.length || !!data?.LoanBalances?.length || !!data?.EscrowBalances?.length;

    const [unpaidItemsQuery, setUnpaidItemsQuery] = useState(getQueryFields());
    const unpaidItemsQueryValues = Fields.getFieldKeyValues(unpaidItemsQuery);
    const handleUpdateUnpaidItemsOffset = (name, value) => {
        const unpaidItemsQueryUpdate = FieldsManager.updateField(unpaidItemsQuery, name, value);
        setUnpaidItemsQuery(unpaidItemsQueryUpdate);
    }

    const [escrowBalancesQuery, setEscrowBalancesQuery] = useState(getQueryFields());
    const escrowBalancesQueryValues = Fields.getFieldKeyValues(escrowBalancesQuery);
    const handleEscrowBalancesOffset = (name, value) => {
        const unpaidItemsQueryUpdate = FieldsManager.updateField(escrowBalancesQuery, name, value);
        setEscrowBalancesQuery(unpaidItemsQueryUpdate);
    }

    const [loanBalancesQuery, setLoanBalancesQuery] = useState(getQueryFields());
    const loanBalancesQueryValues = Fields.getFieldKeyValues(loanBalancesQuery);
    const handleLoanBalancesOffset = (name, value) => {
        const unpaidItemsQueryUpdate = FieldsManager.updateField(loanBalancesQuery, name, value);
        setLoanBalancesQuery(unpaidItemsQueryUpdate);
    }

    const tableData = useMemo(() => {
        const tableData = {};
        tableData.UnpaidItems = cloneDeep(data?.UnpaidItems ?? {});
        if (tableData.UnpaidItems) {
            let fieldsList = Object.keys(unpaidItemsFields);
            tableData.UnpaidItems = Object.values(tableData.UnpaidItems).reduce((memo, row) => {

                Object.keys(row).forEach(key => {
                    if (!fieldsList.includes(key)) {
                        delete row[key];
                    }
                })

                memo.push(row)
                return memo;
            }, []);
        }

        tableData.EscrowBalances = cloneDeep(data?.EscrowBalances ?? {});
        if (tableData.EscrowBalances) {
            let fieldsList = Object.keys(escrowBalancesFields);
            tableData.EscrowBalances = Object.values(tableData.EscrowBalances).reduce((memo, row) => {

                Object.keys(row).forEach(key => {
                    if (!fieldsList.includes(key)) {
                        delete row[key];
                    }
                })

                memo.push(row)
                return memo;
            }, []);
        }

        tableData.LoanBalances = cloneDeep(data.LoanBalances);
        if (tableData.LoanBalances) {
            let fieldsList = Object.keys(loanBalancesFields);
            tableData.LoanBalances = Object.values(tableData?.LoanBalances ?? {}).reduce((memo, row) => {

                Object.keys(row).forEach(key => {
                    if (!fieldsList.includes(key)) {
                        delete row[key];
                    }
                })

                memo.push(row)
                return memo;
            }, []);
        }

        return tableData;
    }, [data])


    let unpaidItemsTableData = !!unpaidItemsFilterValue
        ? tableData.UnpaidItems.filter(d => {
            return Object.values(cloneDeep(d))
                .find(it => {
                    return typeof it === "string" ? it.toUpperCase().includes(unpaidItemsFilterValue.toUpperCase()) : it.toString().includes(unpaidItemsFilterValue)
                });
        })
        : tableData.UnpaidItems

    let paginatedUnpaidItemsTableData = [];
    if (!!unpaidItemsTableData?.length) {
        paginatedUnpaidItemsTableData = cloneDeep(unpaidItemsTableData).splice(unpaidItemsQueryValues.offset, unpaidItemsQueryValues.limit);
    }

    let escrowBalancesTableData = !!escrowBalancesFilterValue
        ? tableData.EscrowBalances.filter(d => {
            return Object.values(cloneDeep(d))
                .find(it => {
                    return typeof it === "string" ? it.toUpperCase().includes(escrowBalancesFilterValue.toUpperCase()) : it.toString().includes(escrowBalancesFilterValue)
                });
        })
        : tableData.EscrowBalances

    let paginatedEscrowBalancesTableData = [];
    if (!!escrowBalancesTableData?.length) {
        paginatedEscrowBalancesTableData = cloneDeep(escrowBalancesTableData).splice(escrowBalancesQueryValues.offset, escrowBalancesQueryValues.limit) ?? [];
    }

    let loanBalancesTableData = !!loanBalancesFilterValue
        ? tableData.LoanBalances.filter(d => {
            return Object.values(cloneDeep(d))
                .find(it => {
                    return typeof it === "string" ? it.toUpperCase().includes(loanBalancesFilterValue.toUpperCase()) : it.toString().includes(loanBalancesFilterValue)
                });
        })
        : tableData.LoanBalances

    let paginatedLoanBalancesTableData = [];
    if (!!loanBalancesTableData?.length) {
        paginatedLoanBalancesTableData = cloneDeep(loanBalancesTableData).splice(loanBalancesQueryValues.offset, loanBalancesQueryValues.limit) ?? [];
    }

    const toggleTableModal = (rowData) => {
        setActiveModal(rowData?.Item);
    }

    const grandTotal = getProp(data, "UnpaidItemsTotal", 0)
        + getProp(data, "EscrowBalancesTotal", 0)
        - getProp(data, "LoanBalancesTotal", 0);

    return (
        <div className="space-y-4">
            <div className="flex items-center">
                <button
                    className="btn btn-icon text-tm-gray-500 hover:text-tm-gray-700 w-8 h-8 -ml-2 md mr-2"
                    onClick={() => toggleExpandSection("isOpenPayEntriesSectionExpanded")}
                >
                    <ChevronRightIcon
                        className={
                            classNames(
                                "w-5 h-5",
                                isSectionExpanded ? "rotate-90" : undefined
                            )
                        }
                    />
                </button>

                <Subtitle subtitle={translate("text.open_pay_entries")}/>
            </div>

            {isLoading && (
                <div className="relative h-48">
                    <LoaderLarge/>
                </div>
            )}

            {isSectionExpanded && !isLoading && (
                <React.Fragment>
                    {hasOpenEntries && (
                        <div className="max-w-xl">

                            <TableCard addClass="overflow-hidden">
                                <ResourceTable
                                    data={openPayEntryData}
                                    count={3}

                                    onRowClick={toggleTableModal}

                                    fields={openPayEntryFields}
                                    translate={translate}
                                    isLoading={isLoading}

                                    options={tableOptions}
                                />
                            </TableCard>

                            <div className="pt-4">
                                <p className="text-right text-xl">
                                    Net position:
                                    {" "}
                                    <span
                                        className={
                                            classNames(
                                                "font-bold",
                                                grandTotal > 0 ? "text-green-600" : "text-red-600"
                                            )
                                        }
                                    >
                                        {genericMoneyFormatter(grandTotal)}
                                    </span>

                                    {checkPerm(Resources.AccountingAccountAdvances, READ_PERM) && (
                                        <button className="btn btn-header ml-5"
                                                onClick={() => setIsContactAdvancesDialogOpen(true)}>
                                            <EyeIcon className="w-5 h-5"/>
                                        </button>
                                    )}
                                </p>
                            </div>
                        </div>
                    )}

                    <ModalDefault
                        show={activeModal === 'UnpaidItemsTotal'}
                        widthClass={'max-w-xl'}
                        title={translate("modal_heading.unpaid_items")}

                        closeButtonLabel={translate('Close')}
                        onClose={() => toggleTableModal(undefined)}
                    >
                        <div>
                            {!!data?.UnpaidItems?.length && (
                                <React.Fragment>
                                    {data?.UnpaidItems?.length > 10 && (
                                        <div className="px-6 py-2 max-w-xs mx-auto">
                                            <FieldSearch
                                                addClass={"form-control px-6"}
                                                onChange={(name, value) => setUnpaidItemsFilterValue(value)}
                                                value={unpaidItemsFilterValue}
                                            />
                                        </div>
                                    )}

                                    <ResourceTable
                                        data={paginatedUnpaidItemsTableData}

                                        fields={unpaidItemsFields}
                                        translate={translate}
                                        isLoading={isLoading}

                                        options={tableOptions}
                                    />
                                    {data?.UnpaidItems?.length > 10 && (
                                        <TableFooter
                                        >
                                            <Pagination
                                                count={unpaidItemsTableData.length ?? 0}
                                                queryFields={getQueryFields(unpaidItemsQueryValues)}
                                                handleQueryChange={handleUpdateUnpaidItemsOffset}
                                                translate={translate}
                                            />
                                        </TableFooter>
                                    )}
                                </React.Fragment>
                            )}

                            {!data?.UnpaidItems?.length && (
                                <div className="text-tm-gray-900 text-lg p-6 text-center">
                                    <h3 className="mt-2  font-medium text-primary">All settled</h3>
                                    <p className="mt-1 text-tm-gray-500">No open entries</p>
                                </div>
                            )}
                        </div>
                    </ModalDefault>

                    <ModalDefault
                        show={activeModal === 'EscrowBalancesTotal'}
                        widthClass={'max-w-xl'}
                        title={translate("modal_heading.escrow_balances_total")}

                        closeButtonLabel={translate('Close')}
                        onClose={() => toggleTableModal(undefined)}
                    >
                        <div>
                            {!!data?.EscrowBalances?.length && (
                                <React.Fragment>
                                    {data?.EscrowBalances?.length > 10 && (
                                        <div className="px-6 py-2 max-w-xs mx-auto">
                                            <FieldSearch
                                                addClass={"form-control px-6"}
                                                onChange={(name, value) => setEscrowBalancesFilterValue(value)}
                                                value={escrowBalancesFilterValue}
                                            />
                                        </div>
                                    )}

                                    <ResourceTable
                                        data={paginatedEscrowBalancesTableData}

                                        fields={escrowBalancesFields}
                                        translate={translate}
                                        isLoading={isLoading}

                                        options={tableOptions}
                                    />

                                    {data?.EscrowBalances?.length > 10 && (
                                        <TableFooter
                                        >
                                            <Pagination
                                                count={escrowBalancesTableData.length ?? 0}
                                                queryFields={getQueryFields(escrowBalancesQueryValues)}
                                                handleQueryChange={handleEscrowBalancesOffset}
                                                translate={translate}
                                            />
                                        </TableFooter>
                                    )}
                                </React.Fragment>
                            )}

                            {!data?.EscrowBalances?.length && (
                                <div className="text-tm-gray-900 text-lg p-6 text-center">
                                    <h3 className="mt-2  font-medium text-primary">All settled</h3>
                                    <p className="mt-1 text-tm-gray-500">No open entries</p>
                                </div>
                            )}
                        </div>
                    </ModalDefault>

                    <ModalDefault
                        show={activeModal === 'LoanBalancesTotal'}
                        widthClass={'max-w-md'}
                        title={translate("modal_heading.loan_balances_total")}

                        closeButtonLabel={translate('Close')}
                        onClose={() => toggleTableModal(undefined)}
                    >
                        <div>
                            {!!data?.LoanBalances?.length && (
                                <React.Fragment>
                                    {data?.LoanBalances?.length > 10 && (
                                        <div className="px-6 py-2 max-w-xs mx-auto">
                                            <FieldSearch
                                                addClass={"form-control px-6 rounded-0 w-full"}
                                                onChange={(name, value) => setLoanBalancesFilterValue(value)}
                                                value={loanBalancesFilterValue}
                                            />
                                        </div>
                                    )}

                                    <ResourceTable
                                        data={paginatedLoanBalancesTableData}

                                        fields={loanBalancesFields}
                                        translate={translate}
                                        isLoading={isLoading}

                                        options={tableOptions}
                                    />

                                    {data?.LoanBalances?.length > 10 && (
                                        <TableFooter
                                        >
                                            <Pagination
                                                count={loanBalancesTableData.length ?? 0}
                                                queryFields={getQueryFields(loanBalancesQueryValues)}
                                                handleQueryChange={handleLoanBalancesOffset}
                                                translate={translate}
                                            />
                                        </TableFooter>
                                    )}
                                </React.Fragment>
                            )}

                            {!data?.LoanBalances?.length && (
                                <div className="text-tm-gray-900 text-lg p-6 text-center">
                                    <h3 className="mt-2 font-medium text-primary">All settled</h3>
                                    <p className="mt-1 text-tm-gray-500">No open entries</p>
                                </div>
                            )}
                        </div>
                    </ModalDefault>

                    {!hasOpenEntries && (
                        <div className="max-w-xl mx-auto">
                            <Card bodyClass="p-6 text-center">
                                <div className="text-tm-gray-900 text-lg">
                                    <h3 className="mt-2  font-medium text-primary">All settled</h3>
                                    <p className="mt-1 text-tm-gray-500">No open entries</p>
                                </div>
                            </Card>
                        </div>
                    )}

                    <AdvancesDialog
                        resourceName={Resources.AccountingAccountAdvances}
                        show={isContactAdvancesDialogOpen}
                        onClose={() => setIsContactAdvancesDialogOpen(false)}
                        ContactID={contact?.ContactID}
                        ContactName={[contact?.FirstName, contact?.LastName].filter(it => !!it).join(" ")}
                        AdvanceValue={getProp(data, "LoanBalancesTotal", 0).toFixed(2)}
                        expenseModalResource={advancesResource}
                        expenseModalOnFetchData={(selectedItem) => {
                            dispatch(getInfoResource({
                                user: LocalStorage.get('user'),
                                query: {
                                    ExpenseID: selectedItem?.ExpenseID
                                },
                                resource: Resources.ExpenseExpense
                            }))
                        }}
                        translate={translate}
                    />
                </React.Fragment>
            )}
        </div>
    )
}

const getQueryFields = (item = {}) => {
    const fieldTemplates = {
        offset: new Field('offset', 0, [''], false, ''),
        limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true, labelType: 'float'}),
    }

    return fillFieldsFromData(fieldTemplates, item);
}
