import React, {Component, Fragment} from 'react'
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../data/services/resources";
import {Field, FieldsManager} from "../../../data/services/fields";
import {deleteResource, getResource, updateResource} from "../../../data/actions/resource";
import {
    DEFAULT_CRUD_STATE,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    DELETE_PERM
} from "../../../util/util-constants";
import {cloneDeep} from "../../../common/util/util-vanilla";
import {checkPerm, classNames, getProp, resourceIsLoaded} from "../../../common/util/util-helpers";
import {ArrowPathIcon, TruckIcon} from "@heroicons/react/24/outline";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";
import ResourceTableDialog from "../../../common/components/modal/resource-table-dialog";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ResourceTable from "../../../common/components/resource-table";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import Card from "../../../common/components/card";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import CardSubTitle from "../../../common/components/card/card-sub-title";
import {LoaderLarge} from "../../../common/components/loader";
import StatusProgressTable from "../../../common/components/resource-table/table-components/status-progress-table";
import {fieldsToHtml, fillFieldsFromData} from "../../../common/util/util-fields";
import {DEFAULT_QUERY_LIMIT} from "../../../common/util/util-consts";

export default class TripInfoTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: 'LoadID',
            sort: 'DESC',

            // Fields
            fieldsInfo: this.getInfoFields(),
            fieldsLoads: this.getLoadsTableFields(),
            queryFields: this.getQueryFields(),

            selectedRows: {},
            selectedRowsWeights: {},

            // Modals
            isLoadsModalOpen: false,
            isConfirmDialogOpen: false,
            isSuggestedLoadsModalOpen: false,

            canSubmit: false,
        }

        this.isInitialLoad = true
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount = () => {
        this.fetchTripData();
    }

    componentDidUpdate(prevProps) {
        if (resourceIsLoaded(this.props.resource, prevProps.resource) && this.isInitialLoad) {
            this.setState({
                fieldsInfo: this.getInfoFields(getProp(this.props.resource, 'data.info', {}))
            }, () => {
                this.isInitialLoad = false
            })
        }
    }

    /** Helpers
     ================================================================= */
    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            ...FieldsManager.getFieldKeyValues(this.state.queryFields),
            LoadTripID: this.getLoadTripID()
        }
    }

    getAddRemoveResource = () => {
        return Resources.TripAddRemoveLoads
    }

    getLoadTripID = () => {
        return this.props.loadTripID
    }

    /** Fields
     ============================================================ */
    getInfoFields = (item = null) => {
        let fieldTemplates = {
            DriverID: new Field('DriverID', '', ['empty'], false, 'select-search'),
            CoDriverID: new Field('CoDriverID', '', [], false, 'select-search'),
            TruckID: new Field('TruckID', '', ['empty'], false, 'select-search'),
        }
        return fillFieldsFromData(fieldTemplates, item)
    }

    getLoadsTableFields = () => {
        return {
            LoadNumber: new Field('LoadNumber', '', [''], false, 'text'),
            StatusID: new Field('StatusID', '', [''], false, 'text', {
                render: (item) => {
                    return (
                        <StatusProgressTable
                            addClass=""
                            LoadStatusID={item.LoadStatusID}
                            translate={this.props.translate}
                        />
                    )
                }
            }),
            Customer: new Field('Customer', '', [''], false, 'text'),
            Pickup: new Field('Pickup', '', [''], false, 'text'),
            Destination: new Field('Destination', '', [''], false, 'text'),
            TotalWeight: new Field('TotalWeight', '', [''], false, 'float'),
            TotalVolume: new Field('TotalVolume', '', [''], false, 'float')
        }
    }

    getQueryFields = () => {
        return {
            offset: new Field('offset', "0", [''], false, ''),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true, labelType: 'float'})
        }
    }

    getFieldsToHtml = () => {
        const selects = {
            DriverID: {
                api: 'api/' + Resources.DriversQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.DriverID,
                    label: item.FirstName + ' ' + item.LastName
                })
            },
            CoDriverID: {
                api: 'api/' + Resources.DriversQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.DriverID,
                    label: item.FirstName + ' ' + item.LastName
                })
            },
            TruckID: {
                api: 'api/' + Resources.TrucksQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.TruckID,
                    label: item.Truck
                })
            }
        }
        return fieldsToHtml(Object.values(Object.assign({}, this.state.fieldsInfo)), this.props.translate, this.handleInputChange, selects);
    }

    /** Data Events
     ================================================================= */
    fetchTripData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.Trip,
            query: this.getQuery()
        }))
    }

    handleAddLoads = () => {
        this.props.dispatch(updateResource({
            user: LocalStorage.get('user'),
            resource: this.getAddRemoveResource(),
            params: {
                LoadIDs: Object.values(this.state.selectedRows),
                LoadTripID: this.getLoadTripID()
            },
            query: this.getQuery(),
            errorMessage: true, successMessage: this.props.translate('text.load_added'),
            piggyResource: Resources.Trip,
        }))
        this.state.isLoadsModalOpen ? this.toggleLoadsModal() : this.toggleSuggestedLoadsModal()
    }

    handleDeleteLoad = (selectedItem) => {
        this.props.dispatch(deleteResource({
            user: LocalStorage.get('user'),
            resource: this.getAddRemoveResource(),
            query: {
                LoadID: selectedItem.LoadID,
                LoadTripID: this.getLoadTripID()
            },
            piggyResource: Resources.Trip,
            errorMessage: true, successMessage: this.props.translate("text.load_deleted"),
        }))
        this.toggleConfirmModal()
        this.setState({queryFields: this.getQueryFields()})
    }

    handleSubmit = () => {
        this.setState({fieldsInfo: FieldsManager.validateFields(this.state.fieldsInfo)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fieldsInfo)) {
                this.setState({canSubmit: false}, () => {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: Object.assign({},
                            FieldsManager.getFieldKeyValues(this.state.fieldsInfo), {
                                LoadTripID: this.getLoadTripID()
                            }),
                        query: {
                            LoadTripID: this.getLoadTripID()
                        },
                        resource: Resources.Trip,
                        errorMessage: true, successMessage: this.props.translate('text.trip_info_updated'),
                        piggyResource: Resources.Trip
                    }))
                })
            }
        })
    }

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        this.setState({
            fieldsInfo: FieldsManager.updateField(this.state.fieldsInfo, name, value),
            canSubmit: true
        })
    }

    handleCancel = () => {
        this.setState({
            fieldsInfo: this.getInfoFields()
        })
    }

    toggleLoadsModal = () => {
        this.setState({
            selectedRows: {},
            selectedRowsWeights: {},
            isLoadsModalOpen: !this.state.isLoadsModalOpen
        })
    }

    toggleSuggestedLoadsModal = () => {
        this.setState({
            selectedRows: {},
            selectedRowsWeights: {},
            isSuggestedLoadsModalOpen: !this.state.isSuggestedLoadsModalOpen
        })
    }

    toggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            isConfirmDialogOpen: !this.state.isConfirmDialogOpen
        })
    }

    handleUpdateOffset = (name, value) => {
        this.setState({queryFields: FieldsManager.updateField(this.state.queryFields, name, value)})
    }

    handleSelectRowClick = (item) => {
        const itemID = item.LoadID;
        const itemWeight = item.TotalWeight

        let selectedRows = cloneDeep(this.state.selectedRows)
        let selectedRowsWeights = cloneDeep(this.state.selectedRowsWeights)

        if (selectedRows[itemID]) {
            delete selectedRows[itemID]
            delete selectedRowsWeights[itemID]
        } else {
            Object.assign(selectedRows, {[itemID]: itemID})
            Object.assign(selectedRowsWeights, {[itemID]: itemWeight})
        }

        this.setState({selectedRows, selectedRowsWeights})
    }

    handleSelectAllClick = (selectAll) => {
        const data = this.state.isLoadsModalOpen ?
            cloneDeep(getProp(this.props.dialogResource.data, 'list', []))
            :
            cloneDeep(getProp(this.props.dialogResource.data, 'suggestedLoads', []))

        let selectedRows = cloneDeep(this.state.selectedRows)
        let selectedRowsWeights = cloneDeep(this.state.selectedRowsWeights)

        if (selectAll) {
            Object.assign(selectedRows, data.reduce((memo, it) => {
                memo[it.LoadID] = it.LoadID
                return memo
            }, {}))
            Object.assign(selectedRowsWeights, data.reduce((memo, it) => {
                memo[it.LoadID] = it.TotalWeight
                return memo
            }, {}))
        } else {
            let selectedRowsKeys = Object.keys(selectedRows)
            data.forEach(it => {
                if (selectedRowsKeys.includes(it.LoadID.toString())) {
                    delete selectedRows[it.LoadID];
                    delete selectedRowsWeights[it.LoadID]
                }
            })
        }
        this.setState({selectedRows, selectedRowsWeights})
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props

        const offset = this.state.queryFields?.offset?.value ?? 0;
        const limit = this.state.queryFields?.limit?.value ?? 5;

        const tripLoads = getProp(this.props.resource, 'data.loads.list', [])
        let tripLoadsSlice = tripLoads.length ? tripLoads.slice(Number(offset), Number(offset) + Number(limit)) : [];

        const suggestedLoads = getProp(this.props.resource, 'data.suggestedLoads', [])
        const suggestedLoadsCount = suggestedLoads.length

        const isLoading = getProp(this.props.resource, "isLoading", false)
        let hasSelectedRows = Object.keys(this.state.selectedRows).length

        const fieldsHtml = this.getFieldsToHtml()

        const weightTotal = Number(Object.values(tripLoadsSlice).reduce((memo, it) => {
            memo += it.TotalWeight ?? 0
            return memo
        }, 0)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})

        const weightTotalSelected = Object.values(this.state.selectedRowsWeights).reduce((memo, item) => memo + item, 0).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})

        let weightTotalSelectedHTML = hasSelectedRows ? (
            <div className="px-0 py-2 border-y border-tm-gray-200  flex items-center">
                <div className="mr-4 flex ml-4 space-x-4">
                    <TruckIcon
                        className={classNames(hasSelectedRows ? 'text-primary' : 'text-tm-gray-400', 'w-5 h-5')}
                    />
                </div>
                <div className="text-sm flex justify-between w-full">
                    <div>
                        <span
                            className="font-bold text-tm-gray-700">{Object.keys(this.state.selectedRows).length}
                        </span>
                             {Object.keys(this.state.selectedRows).length === 1 ? ' load selected' : ' loads selected'}
                    </div>

                    <div className="mr-4">
                        Total weight for selected loads:
                        <span className="ml-2 font-bold text-tm-gray-700">
                            {weightTotalSelected}
                        </span>
                    </div>
                </div>
            </div>
        ) : ''

        return (
            <Fragment>
                <div className="grid grid-cols-12 gap-4">
                    <div className="relative col-span-6">
                        {isLoading && (
                            <LoaderLarge stripesBg/>
                        )}
                        <Card addClass={'mb-4'}>
                            <CardSubTitle
                                text={translate('text.Info')}
                            />
                            <div className="grid gap-4">
                                {fieldsHtml}
                            </div>
                        </Card>
                    </div>
                </div>

                <Card>
                    <PageHeader
                        title={translate('text.Loads')}
                        titleClass="mr-5 text-2xl"
                        buttonLabel={translate('btn.add_load')}
                        secondaryButtonLabel={translate('btn.add_suggested_loads')}
                        onSecondaryButtonClick={this.toggleSuggestedLoadsModal}
                        onButtonClick={this.toggleLoadsModal}
                    >
                        <button className="btn btn-header" onClick={this.fetchTripData}>
                            <ArrowPathIcon className="w-5 h-5" />
                        </button>

                    </PageHeader>
                    <TableCard>
                        <ResourceTable
                            addClass="rounded-card"
                            data={tripLoadsSlice}

                            commonTable={true}

                            fields={this.state.fieldsLoads}
                            translate={translate}
                            isLoading={isLoading}

                            onDelete={checkPerm(this.getAddRemoveResource(), DELETE_PERM) ? this.toggleConfirmModal : null}

                            tfoot={{
                                TotalWeight: <div className="text-right">{weightTotal}</div>
                            }}
                        />

                        <NoRecordsTable
                            show={(tripLoads.length === 0) && !isLoading}
                            canCreate={false}
                            title={translate("text.no_matching_records")}
                            className={"pb-12 pt-16 px-6"}
                        />

                        <TableCardFooter
                            show={!!tripLoads.length && !isLoading}
                        >
                            <Pagination
                                count={tripLoads.length ?? 0}
                                handleQueryChange={
                                    (name, value) => this.handleUpdateOffset(name, value)
                                }
                                translate={this.props.translate}
                                hideRowsPerPage
                                queryFields={this.state.queryFields}
                            />
                        </TableCardFooter>
                    </TableCard>
                </Card>

                <ResourceTableDialog
                    show={this.state.isLoadsModalOpen}
                    buttonDisabled={!Object.keys(this.state.selectedRows).length}
                    buttonLabel={translate('btn.add_selected')}
                    onButtonClick={this.handleAddLoads}
                    options={{
                        behaviour: {
                            rowSelect: true,
                        },
                    }}
                    dialogResource={this.props.dialogResource}
                    resource={Resources.Loads}
                    title={translate("text.select_loads")}
                    dispatch={this.props.dispatch}
                    fields={this.getLoadsTableFields()}
                    fieldsFilter={{
                        query: new Field('query', '', [''], false, 'search', {addContainerClass: 'col-span-3'})
                    }}

                    defaultAction={this.handleSelectRowClick}

                    onSelectRow={this.handleSelectRowClick}
                    onSelectAllClick={this.handleSelectAllClick}
                    selectedRows={this.state.selectedRows}
                    tableKey={'LoadID'}

                    onClose={this.toggleLoadsModal}
                    translate={translate}

                    htmlAfter={weightTotalSelectedHTML}
                />

                {/*Suggested Loads Modal*/}
                <ResourceTableDialog
                    show={this.state.isSuggestedLoadsModalOpen}
                    buttonDisabled={!Object.keys(this.state.selectedRows).length}
                    buttonLabel={translate('btn.add_selected')}
                    onButtonClick={this.handleAddLoads}
                    options={{
                        behaviour: {
                            rowSelect: true,
                        },
                    }}
                    dialogResource={this.props.dialogResource}
                    resource={Resources.Trip}
                    dialogData={suggestedLoads}
                    dialogCount={suggestedLoadsCount}
                    title={translate("text.select_loads")}
                    dispatch={this.props.dispatch}
                    fields={this.getLoadsTableFields()}
                    fieldsFilter={{
                        query: new Field('query', '', [''], false, 'search', {addContainerClass: 'col-span-3'})
                    }}

                    defaultAction={this.handleSelectRowClick}

                    onSelectRow={this.handleSelectRowClick}
                    onSelectAllClick={this.handleSelectAllClick}
                    selectedRows={this.state.selectedRows}
                    tableKey={'LoadID'}

                    onClose={this.toggleSuggestedLoadsModal}
                    translate={translate}

                    htmlAfter={weightTotalSelectedHTML}
                />


                <ModalConfirm
                    title={translate("Confirm")}
                    show={!!this.state.isConfirmDialogOpen}
                    text={translate("message.are_you_sure_remove_load")}
                    onClose={this.toggleConfirmModal}
                    buttonLabel={translate("btn.confirm")}
                    closeButtonLabel={translate('Cancel')}
                    translate={translate}
                    onConfirm={() => this.handleDeleteLoad(this.state.selectedItem)}
                />

                <PageFooter
                    translate={this.props.translate}
                    canSubmit={this.state.canSubmit}
                    actionSubmit={this.handleSubmit}
                    actionCancel={this.handleCancel}
                />
            </Fragment>
        )
    }
}