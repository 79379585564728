import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    returnOffsetAndPagination,
    saveTableColumns,
} from'../../../common/util/util-helpers'
import {Field, FieldsManager} from '../../../data/services/fields'
import {CREATE_PERM, DEFAULT_CRUD_STATE, DELETE_PERM, UPDATE_PERM} from '../../../util/util-constants'
import {checkPerm, getProp, mergeDeep} from '../../../common/util/util-helpers'
import {cloneDeep} from '../../../common/util/util-vanilla'
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import ResourceTable from "../../../common/components/resource-table";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import Button from "../../../common/components/button";
import {DEFAULT_QUERY_LIMIT} from "../../../common/util/util-consts";
class EventTemplatesTab extends Component {
    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1) + "_event_tab"

        this.state = {
            // Defaults and filters
            ...DEFAULT_CRUD_STATE,
            sortBy: 'EventKey',

            tableOptions: getDefaultTableOptions(this.getFields(), {}, this.pagePath, this.props.translate),
            queryFilterFields: this.getQueryFilterFields(),

            // Dialogs
            confirmDialog: false,
            isEventModalOpen: false,
            isColumnsModalOpen: false,
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps) {

        if (prevProps.breakpoint !== this.props.breakpoint && this.props.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
            this.handleFilterInputChange('limit', 10);
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    deleteResource = (item) => {
        this.setState({text: `${this.props.translate("message.are_you_sure_delete_event")} ${item.EventKey}?`}, () => {
            this.handleShowConfirmDialog(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(deleteResource({
                        user: LocalStorage.get('user'),
                        query: {
                            LoadEventTemplateID: item.LoadEventTemplateID,
                        },
                        piggyQuery: this.getQuery(),
                        errorMessage: true, successMessage: `${item.EventKey} has been deleted`,
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    /** UI Events
     ================================================================= */
    handleShowConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    handleCreateEventClick = () => {
        this.setState({
            selectedItem: null,
            isEventModalOpen: true
        })
    }

    handleToggleEventDialog = (selectedItem = this.state.selectedItem) => {
        this.setState({
            selectedItem: selectedItem,
            isEventModalOpen: !this.state.isEventModalOpen
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            isColumnsDialogVisible: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate);
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }
    /** Helpers
     ================================================================= */
    getFields = (item = {}) => {

        const fieldTemplates = {
            EventKey: new Field('EventKey', '', ['empty'], false, 'text', {
                addContainerClass: 'col-span-full',
                label: 'Title'
            }),
            EventValue: new Field('EventValue', '', ['empty'], false, 'textarea', {
                addContainerClass: 'col-span-full',
                label: 'Description'
            }),
            IsCustomerVisible: new Field('IsCustomerVisible', '', [''], false, 'checkbox', {addContainerClass: 'col-span-full'})
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'limit', 'query', 'offset', 'paginationPage'])
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {
                menuPlacement: "top",
                hasPortal: true
            })
        }
    }

    getResource = () => {
        return Resources.LoadEventTemplates
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props

        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)

        return (
            <>
                <div className="flex">
                    <ActiveFilters
                        filterFields={this.state.queryFilterFields}
                        onLabelClick={this.handleFilterInputChange}
                        onClearFiltersClick={this.handleClearFiltersClick}
                        translate={translate}
                    />

                    <div className="ml-auto flex">
                        <TableSettingsPopOver
                            options={this.state.tableOptions}
                            setOptions={this.setOptions}
                            toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                            translate={translate}
                        />

                        <Button
                            onClick={this.handleCreateEventClick}
                            hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                            appearance={'primary'}
                        >
                            {translate('btn.create_new')}
                        </Button>
                    </div>
                </div>

                <TableCard addClass={'relative'}>
                    <TableFilters
                        hideLimit
                        filterFields={this.state.queryFilterFields}
                        handleInputChange={this.handleFilterInputChange}
                        translate={translate}
                        onRefreshTable={this.fetchData}
                        isLoading={resource.isLoading}
                    />

                    <ResourceTable
                        data={data}
                        fields={this.getFields()}
                        verticalTableIsVisible={this.props.breakpoint.index <= 1}

                        translate={translate}
                        isLoading={resource.isLoading}

                        options={this.state.tableOptions}

                        limit={this.state.queryFilterFields.limit.value}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        onRowClick={checkPerm(this.getResource(), UPDATE_PERM) ? (it) => this.handleToggleEventDialog(it) : null}
                        onEdit={checkPerm(this.getResource(), UPDATE_PERM) ? (it) => this.handleToggleEventDialog(it) : null}
                        onDelete={checkPerm(this.getResource(), DELETE_PERM) ? this.deleteResource : null}
                    />

                    {/*Table footer*/}
                    {!(!data.length && !resource.isLoading) && (
                        <TableCardFooter>
                            <Pagination
                                count={count}
                                isLoading={resource.isLoading}
                                hideRowsPerPage={this.props.breakpoint.index <= 1}
                                handleQueryChange={
                                    (name, value, currentPage) => name === "offset"
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                            />
                        </TableCardFooter>
                    )}

                    <NoRecordsTable
                        show={(data.length === 0) && !resource.isLoading}
                        canCreate={checkPerm(this.getResource(), CREATE_PERM)}
                        title={'No matching records found'}
                        text={'Create a event'}
                        btnLabel="Create event"
                        onBtnClick={() => {
                            this.handleCreateEventClick()
                        }}
                        onClearFilterClick={this.handleClearFiltersClick}
                        clearFilterBtnLabel={translate('text.clear_all_filters')}
                        clearFilterText={translate('text.try_without_filters')}
                        filters={this.state.queryFilterFields}
                    />
                </TableCard>

                <TableOptionsDialog
                    show={this.state.isColumnsDialogVisible}
                    pagePath={this.pagePath}
                    columns={this.state.tableOptions.columns}
                    setTableColumnOptions={this.handleSetTableColumnOptions}
                    getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                    onClose={this.handleToggleColumnSettingsDialog}
                    translate={translate}
                />

                <ModalSaveResource
                    title={this.state.selectedItem ? translate('dialog_heading.edit_event_template') : translate('dialog_heading.create_event_template')}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-12"
                    show={this.state.isEventModalOpen}
                    onClose={this.handleToggleEventDialog}
                    fields={this.getFields(this.state.selectedItem)}
                    onSubmit={(params) => {
                        if (params) {
                            params.EventType = 1
                            if (this.state.selectedItem) {
                                this.props.dispatch(updateResource({
                                    user: LocalStorage.get('user'),
                                    params: Object.assign(params, {LoadEventTemplateID: this.state.selectedItem?.LoadEventTemplateID}),
                                    query: this.getQuery(),
                                    resource: this.getResource(),
                                    piggyResource: this.getResource(),
                                    errorMessage: true, successMessage: translate('text.event_template_updated')
                                }))
                            } else {
                                this.props.dispatch(createResource({
                                    user: LocalStorage.get('user'),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: this.getResource(),
                                    piggyResource: this.getResource(),
                                    errorMessage: true, successMessage: translate('text.event_template_created')
                                }))
                            }
                            this.handleToggleEventDialog()
                        }
                    }}
                    translate={translate}
                />

                <ModalConfirm
                    title={'Confirm delete'}
                    show={!!this.state.confirmDialog}
                    text={this.state.text}
                    onClose={() => this.setState({confirmDialog: false})}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.state.confirmModal}
                />
            </>
        )
    }
}

export default connect(state => state)(EventTemplatesTab)
