import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {getHeaderFilterValues, groupListBySCAC} from '../../../common/util/util-helpers'
import {Field, FieldsManager} from '../../../data/services/fields'
import {download} from '../../../data/actions/download'
import {ArrowDownTrayIcon, ArrowRightOnRectangleIcon, PencilIcon} from '@heroicons/react/24/outline'
import Tippy from '@tippyjs/react'
import RectangleGroupIcon from '@heroicons/react/20/solid/RectangleGroupIcon'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import {DEFAULT_CRUD_STATE, RESOURCE_TABLE_SIMPLE_DEFAULT_OPTIONS, UPDATE_PERM} from '../../../util/util-constants'
import {checkPerm, getDefaultFieldOrder, getProp, mergeDeep, orderFields} from '../../../common/util/util-helpers'
import {currentDate} from '../../../common/util/util-dates'
import Layout from "../../../common/components/layout";
import GoBackButton from "../../../common/components/layout/layout-components/go-back-button";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import ResourceTable from "../../../common/components/resource-table";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableColumnsDialog from "../../../common/components/modal/table-columns-dialog";
import TableCellsIcon from "@heroicons/react/20/solid/TableCellsIcon";
import {DEFAULT_QUERY_LIMIT} from "../../../common/util/util-consts";

class ExternalTrucksView extends Component {

    constructor(props) {
        super(props)

        this.pagePath = this.props.location.pathname.substring(1)

        this.state = {
            // Default state
            ...DEFAULT_CRUD_STATE,
            sortBy: 'TruckNumber',

            // Fields
            fields: this.getFields(),
            fieldsOrder: getDefaultFieldOrder(this.getFields(), this.pagePath),

            // Filter, and header fields
            queryFilterFields: this.getQueryFilterFields(),

            // Dialog(s)
            selectedItem: null,
            confirmDialog: false,
            confirmDialogHideAnimation: false,

            // Batch actions
            selectedItems: {},

        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign({format: 'EXCEL', name: 'trucks_' + currentDate() + '.xlsx'}, this.getQuery())
        }))
    }

    downloadExcelTemplate = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign({
                format: 'EXCEL',
                name: 'trucks_template' + currentDate() + '.xlsx'
            }, this.getQuery(), {excelTemplate: 1})
        }))
    }
    /** UI Events
     ================================================================= */
    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterClear = () => {
        this.setState({
            queryFilterFields: this.getQueryFilterFields(),
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleActionUpdate = (item = null) => {
        this.handleLinkClick(`/trucks/info/${item[this.getPrimaryKey()]}`)
    }

    toggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }
    /** Helpers
     ================================================================= */
    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...getHeaderFilterValues(this.state.headerFilterFields),
            TrackingMethodID: 2,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields)
        }
    }

    saveFilters = () => {
        let filters = []
        Object.keys(this.getQuery()).map(it => filters.push(it))
        LocalStorage.persistState(this.pagePath, this.state, filters)
    }

    setTableColumns = (fieldsNewOrder) => {
        this.setState({
            fieldsOrder: [...fieldsNewOrder]
        })
    }

    handleLinkClick = (e) => {
        this.props.history.push(e)
    }

    handleImportTruckToSystem = (item = null) => {
        this.setState({text: `Are you sure you want to import this truck to system?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(createResource({
                        user: LocalStorage.get('user'),
                        params: Object.assign({}, {
                            TrackingMethodID: this.state.queryFilterFields.TrackingMethodID.value.value,
                            Type: 1,
                            data: [item]
                        }),
                        query: this.getQuery(),
                        errorMessage: true, successMessage: `Truck imported`,
                        resource: Resources.TruckImport,
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    handleDeatachTruckFromSystem = (item = null) => {
        this.setState({text: `Are you sure you want to deatach this truck from system?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: Object.assign({}, {
                            id: item?.vehicle?.TruckID,
                            TrackingMethodID: this.state.queryFilterFields.TrackingMethodID.value.value,
                        }),
                        query: this.getQuery(),
                        errorMessage: true, successMessage: `Truck deatached`,
                        resource: Resources.TrucksDeatach,
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    handleAttachExternalTruckToExisting = (item = null) => {
        this.setState({
            AttachExternalTruckToExistingDialog: !this.state.AttachExternalTruckToExistingDialog,
            selectedItem: item
        })
    }

    /** Fields
     ================================================================= */
    getQueryFilterFields = () => {
        return {
            TrackingMethodID: new Field('TrackingMethodID', 2, [''], false, 'select', {
                isRequiredFilter: true
            }, {
                values: {
                    2: 'Keep Motive'
                }
            }),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select'),
        }
    }

    getFields = () => {
        return {
            number: new Field('number', '', [], false, 'custom', {
                omitSort: true,
                render: (item) => {
                    return (
                        <div
                            className={item?.vehicle?.TruckID && 'text-primary cursor-pointer font-semibold'}
                            onClick={() => item?.vehicle?.TruckID && this.handleActionUpdate(item?.vehicle)}>{item?.vehicle?.number}</div>
                    )
                }
            }),
            model: new Field('model', '', [], false, 'custom', {
                omitSort: true,
                render: (item) => {
                    return (
                        <>{item?.vehicle?.model} {item?.vehicle?.make} {item?.vehicle?.year}</>
                    )
                }
            }),
            vin: new Field('vin', '', [], false, 'custom', {
                omitSort: true,
                render: (item) => {
                    return (
                        <>{item?.vehicle?.vin}</>
                    )
                }
            }),
            plate: new Field('plate', '', [], false, 'custom', {
                omitSort: true,
                render: (item) => {
                    return (
                        <>{item?.vehicle?.license_plate_state} {item?.vehicle?.license_plate_number}</>
                    )
                }
            }),
            status: new Field('status', '', [], false, 'custom', {
                omitSort: true,
                render: (item) => {
                    return (
                        <>{item?.vehicle?.status}</>
                    )
                }
            }),
        }
    }

    getResource = () => {
        return Resources.TrucksImport
    }

    getPrimaryKey = () => {
        return 'TruckID'
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props
        const data = getProp(resource.data, 'list', [])

        return (
            <Layout
                //isAccessible={!(this.props.resource?.data === null)}
                {...this.props}
            >
                <Page>
                    <PageHeader
                        title={
                            <div className={'flex justify-start items-center'}>
                                <GoBackButton
                                    translate={this.props.translate}
                                    history={this.props.history}
                                    path={'/trucks'}
                                />
                                <div className="ml-2">{translate('page.heading.external-trucks')}</div>
                            </div>
                        }
                        titleClass="mr-5 text-2xl"
                    >
                        <div className="flex">
                            <Tippy content={translate('text.download_template')}>
                                <button
                                    className="btn-icon"
                                    onClick={this.downloadExcelTemplate}
                                >
                                    <RectangleGroupIcon className="w-5 h-5"/>
                                </button>
                            </Tippy>

                            <Tippy content={translate('text.download_excel')}>
                                <button
                                    className="btn-icon"
                                    onClick={this.downloadExcel}
                                >
                                    <DocumentArrowDownIcon className="w-5 h-5"/>
                                </button>
                            </Tippy>

                            <Tippy content={translate('text.table_columns')}>
                                <button
                                    className="btn-icon"
                                    onClick={() => this.toggleColumnSettingsDialog()}
                                >
                                    <TableCellsIcon className="w-5 h-5"/>
                                </button>
                            </Tippy>
                        </div>
                    </PageHeader>

                    <div className="flex">
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleFilterClear}
                            translate={translate}
                        />
                    </div>
                    <TableCard>
                        <div className="relative card-height-s">
                            <TableFilters
                                hideLimit={true}
                                filterFields={this.state.queryFilterFields}
                                handleInputChange={this.handleFilterInputChange}
                                translate={translate}
                            />

                            <ResourceTable
                                data={data}
                                count={resource?.data?.count}
                                options={RESOURCE_TABLE_SIMPLE_DEFAULT_OPTIONS}
                                fields={orderFields(this.state.fields, this.state.fieldsOrder)}
                                translate={this.props.translate}
                                isLoading={resource.isLoading}

                                limit={this.state.queryFilterFields.limit.value}
                                offset={this.state.offset}
                                page={this.state.paginationPage}
                                paginationButtonLimit={5}
                                onOffsetChange={this.handleUpdateOffset}

                                sort={this.state.sort}
                                sortBy={this.state.sortBy}
                                onSortChange={this.handleUpdateSort}

                                defaultAction={checkPerm(this.getResource(), UPDATE_PERM) ? this.handleActionUpdate : null}

                                actions={[
                                    {
                                        tooltipText: () => translate('text.ImportTruckToSystem'),
                                        visible: (item) => item?.vehicle?.exist,
                                        hasPerm: () => checkPerm(Resources.TruckImport, UPDATE_PERM),
                                        action: this.handleImportTruckToSystem,
                                        icon: () => ArrowDownTrayIcon
                                    },
                                    {
                                        tooltipText: () => translate('text.DeatachTruckFromSystem'),
                                        visible: (item) => item?.vehicle?.exist,
                                        hasPerm: () => checkPerm(Resources.TrucksDeatach, UPDATE_PERM),
                                        action: this.handleDeatachTruckFromSystem,
                                        icon: () => ArrowRightOnRectangleIcon
                                    },
                                    {
                                        tooltipText: () => translate('text.AttachExternalTruckToExisting'),
                                        hasPerm: () => checkPerm(this.getResource(), UPDATE_PERM),
                                        action: this.handleAttachExternalTruckToExisting,
                                        icon: () => PencilIcon
                                    },
                                ]}
                            />

                            <NoRecordsTable
                                show={(data.length === 0) && !resource.isLoading}
                                title={'No matching records found'}
                                filters={{}}
                            />
                        </div>
                    </TableCard>

                    <ModalConfirm
                        title={'Confirm'}
                        show={!!this.state.confirmDialog}
                        text={this.state.text}
                        onClose={() => this.setState({confirmDialog: false})}
                        buttonLabel={translate('btn.confirm')}
                        closeButtonLabel={'Cancel'}
                        translate={translate}
                        onConfirm={this.state.confirmModal}
                    />

                    {this.state.AttachExternalTruckToExistingDialog && (
                        <ModalSaveResource
                            {...this.props}
                            value={this.state.selectedItem?.vehicle}
                            translate={translate}
                            primaryKey={'TruckID'}
                            fields={{
                                TruckID: new Field('TruckID', '', ['empty'], false, 'select-search')
                            }}
                            onClose={this.handleAttachExternalTruckToExisting}
                            onSubmit={(params) => {
                                if (params) {
                                    this.props.dispatch(updateResource({
                                        user: LocalStorage.get('user'),
                                        params: Object.assign({}, {
                                            id: params.params.TruckID,
                                            ExternalID: this.state.selectedItem?.vehicle?.id,
                                            TrackingMethodID: this.state.queryFilterFields.TrackingMethodID.value.value,
                                        }),
                                        query: this.getQuery(),
                                        errorMessage: true, successMessage: `Truck attached`,
                                        resource: Resources.TrucksAttach,
                                        piggyResource: this.getResource()
                                    }))
                                    this.handleAttachExternalTruckToExisting()
                                }
                            }}
                            headerText={'Attach this truck to existing'}
                            resourceName={Resources.TrucksAttach}
                            selects={{
                                TruckID: {
                                    api: 'api/' + Resources.TrucksQuick,
                                    query: {},
                                    customizeList: (list) => {
                                        return groupListBySCAC(list, 'Truck')
                                    }
                                }
                            }}
                        />
                    )}

                    <TableColumnsDialog
                        show={this.state.isColumnsDialogVisible}
                        pagePath={this.pagePath}
                        tableColumns={this.state.fieldsOrder}
                        setTableColumns={this.setTableColumns}
                        onToggleColumnSettingsDialog={this.toggleColumnSettingsDialog}
                        translate={translate}
                    />
                </Page>
            </Layout>
        )
    }
}

export default connect(state => state)(ExternalTrucksView)
