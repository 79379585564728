import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {download} from '../../../data/actions/download'
import {
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    returnOffsetAndPagination,
    saveTableColumns,
} from '../../../common/util/util-helpers'
import {Field, FieldsManager} from '../../../data/services/fields'
import Tippy from '@tippyjs/react'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DELETE_PERM,
    LOAD_PRICING_FLAT,
    LOAD_PRICING_PER_MILE,
    LOAD_PRICING_PER_PIECE,
    LOAD_PRICING_PER_VOLUME,
    LOAD_PRICING_PER_WEIGHT,
    MATRIX_PRICING_ZIP,
    MATRIX_PRICING_ZONE,
    UPDATE_PERM
} from '../../../util/util-constants'
import {checkPerm, getProp, mergeDeep} from '../../../common/util/util-helpers'
import {currentDate} from '../../../common/util/util-dates'
import {cloneDeep} from '../../../common/util/util-vanilla'
import PricingMatrixCreateUpdateDialog from './pricing-matrix-create-update-dialog'
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import Modal from "../../../common/components/modal";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import ResourceTable from "../../../common/components/resource-table";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import Button from "../../../common/components/button";
import {DEFAULT_QUERY_LIMIT} from "../../../common/util/util-consts";

class LoadPricingMatrixTab extends Component {
    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1) + '_load_tab'

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: 'MatrixName',

            tableOptions: getDefaultTableOptions(this.getFields(), {}, this.pagePath, this.props.translate),
            queryFilterFields: this.getQueryFilterFields(),

            createUpdateDialog: false,
            confirmModalOpen: false,
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps) {

        if (prevProps.breakpoint !== this.props.breakpoint && this.props.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
            this.handleFilterInputChange('limit', 10);
        }
    }

    /** Data Events
     ================================================================= */

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    archiveResource = () => {
        this.setState({
            ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
        }, () => {
            this.props.dispatch(deleteResource({
                user: LocalStorage.get('user'),
                query: {LoadPricingMatrixID: this.state.selectedItem.LoadPricingMatrixID},
                piggyQuery: this.getQuery(),
                errorMessage: true, successMessage: this.props.translate('message.pricing_matrix_archived'),
                resource: this.getResource(),
                piggyResource: this.getResource()
            }))
            this.handleToggleConfirmModal()
        })
    }

    restoreFromArchive = () => {
        this.props.dispatch(updateResource({
            user: LocalStorage.get('user'),
            params: {
                LoadPricingMatrixID: this.state.selectedItem.LoadPricingMatrixID,
                ArchivedDate: 1
            },
            query: this.getQuery(),
            errorMessage: true, successMessage: `Pricing Matrix restored`,
            resource: this.getResource(),
            piggyResource: this.getResource()
        }))
        this.handleToggleConfirmModal()
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign({format: 'EXCEL', name: 'assignments_' + currentDate() + '.xlsx'}, this.getQuery())
        }))
    }

    /** UI Events
     ================================================================= */
    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleToggleCreateUpdateModal = (item = null) => {
        this.setState({
            isCreateUpdateModalOpen: !this.state.isCreateUpdateModalOpen,
            selectedItem: item,
        })
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            isColumnsDialogVisible: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate);
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }
    /** Helpers
     ================================================================= */
    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, Object.keys(this.getQuery()).map(it => it))
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            archived: new Field('archived', '', [''], false, 'checkbox'),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {
                menuPlacement: "top",
                hasPortal: true
            })
        }
    }

    getFields = (item = null) => {
        const LoadPayMethodID = {
            [LOAD_PRICING_FLAT]: 'Flat',
            [LOAD_PRICING_PER_MILE]: 'Per mile',
            [LOAD_PRICING_PER_WEIGHT]: 'Per weight',
            [LOAD_PRICING_PER_VOLUME]: 'Per volume',
            [LOAD_PRICING_PER_PIECE]: 'Per piece'
        }

        const PricingMatrixTypeID = {
            [MATRIX_PRICING_ZIP]: 'Zip to Zip',
            [MATRIX_PRICING_ZONE]: 'Zone to Zone'
        }

        const fieldTemplates = {
            MatrixName: new Field('MatrixName', '', ['empty'], false, 'text', {addContainerClass: 'col-span-3'}),
            LoadPayMethodID: new Field('LoadPayMethodID', '', ['empty'], false, 'custom',
                {
                    addContainerClass: 'col-span-3',
                    render: (item) => <div>{LoadPayMethodID[item.LoadPayMethodID]}</div>
                }
            ),
            PricingMatrixTypeID: new Field('PricingMatrixTypeID', '', ['empty'], false, 'custom',
                {
                    addContainerClass: 'col-span-3',
                    render: (item) => <div>{PricingMatrixTypeID[item.PricingMatrixTypeID]}</div>
                },
                {values: PricingMatrixTypeID}
            ),
            Notes: new Field('Notes', '', [], false, 'textarea', {addContainerClass: 'col-span-3'})
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getResource = () => {
        return Resources.LoadPricingMatrix
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props

        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)

        return (
            <>
                <div className={"flex items-center"}>
                    <div>
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleClearFiltersClick}
                            translate={translate}
                        />
                    </div>

                    <div className="ml-auto flex">
                        <Tippy content={translate('text.download_excel')}>
                            <button
                                className="btn-icon"
                                onClick={this.downloadExcel}
                            >
                                <DocumentArrowDownIcon className="w-5 h-5"/>
                            </button>
                        </Tippy>

                        <TableSettingsPopOver
                            options={this.state.tableOptions}
                            setOptions={this.setOptions}
                            toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                            translate={translate}
                        />

                        <Button
                            onClick={() => this.handleToggleCreateUpdateModal()}
                            hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                            appearance={'primary'}
                        >
                            {translate('btn.create_new')}
                        </Button>
                    </div>
                </div>


                <TableCard>
                    <TableFilters
                        hideLimit
                        filterFields={this.state.queryFilterFields}
                        handleInputChange={this.handleFilterInputChange}
                        translate={translate}
                        onRefreshTable={this.fetchData}
                        isLoading={resource.isLoading}
                    />

                    <ResourceTable
                        data={data}
                        fields={this.getFields()}
                        verticalTableIsVisible={this.props.breakpoint.index <= 1}

                        translate={translate}
                        isLoading={resource.isLoading}

                        options={this.state.tableOptions}

                        limit={this.state.queryFilterFields.limit.value}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        onRowClick={checkPerm(this.getResource(), UPDATE_PERM) ? this.handleToggleCreateUpdateModal : null}

                        onEdit={this.handleToggleCreateUpdateModal}
                        onDelete={this.handleToggleConfirmModal}
                        onRestore={this.handleToggleConfirmModal}

                        hasEditPerm={checkPerm(this.getResource(), UPDATE_PERM)}
                        hasDeletePerm={checkPerm(this.getResource(), DELETE_PERM)}
                        hasRestorePerm={checkPerm(this.getResource(), UPDATE_PERM)}
                    />

                    {/*Table footer*/}
                    {!(!data.length && !resource.isLoading) && (
                        <TableCardFooter>
                            <Pagination
                                count={count}
                                isLoading={resource.isLoading}
                                hideRowsPerPage={this.props.breakpoint.index <= 1}
                                handleQueryChange={
                                    (name, value, currentPage) => name === "offset"
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                            />
                        </TableCardFooter>
                    )}

                    <NoRecordsTable
                        show={(data.length === 0) && !resource.isLoading}
                        hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                        title={'No matching records found'}
                        text={'Create a new Pricing Matrix'}
                        btnLabel="Create Pricing Matrix"
                        onBtnClick={() => {
                            this.setState({prefilled: this.state.queryFilterFields.query.value}, () => {
                                this.handleToggleCreateUpdateModal()
                            })
                        }}
                        onClearFilterClick={this.handleClearFiltersClick}
                        clearFilterBtnLabel={translate('text.clear_all_filters')}
                        clearFilterText={translate('text.try_without_filters')}
                        filters={this.state.queryFilterFields}
                    />
                </TableCard>


                <Modal
                    show={this.state.isCreateUpdateModalOpen}
                    onClose={() => this.handleToggleCreateUpdateModal()}
                    widthClass={'max-w-[1600px]'}
                >
                    <PricingMatrixCreateUpdateDialog
                        selectedItem={this.state.selectedItem}

                        translate={translate}
                        dispatch={this.props.dispatch}

                        onClose={() => this.handleToggleCreateUpdateModal()}

                        primaryKey={'LoadPricingMatrixID'}
                        resourceName={this.getResource()}
                        piggyResourceName={this.getResource()}
                        query={this.getQuery()}
                    />
                </Modal>

                <ModalSaveResource
                    title={'Edit Pricing Matrix'}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    errorMessage={this.state.errorMessage}
                    show={!!this.state.editModalOpen}
                    onClose={this.handleToggleUpdateModal}
                    fields={this.getFields(this.state.selectedItem)}
                    onSubmit={(params) => {
                        if (params) {
                            params['LoadPricingMatrixID'] = this.state.selectedItem['LoadPricingMatrixID']
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get('user'),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResource(),
                                piggyResource: this.getResource(),
                                errorMessage: true, successMessage: 'Pricing Matrix updated.',
                            }))
                            this.handleToggleUpdateModal(null)
                        }
                    }}
                    translate={this.props.translate}
                />

                <ModalSaveResource
                    show={!!this.state.createModalOpen}
                    title={'Create Pricing Matrix'}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    errorMessage={this.state.errorMessage}
                    onClose={this.handleToggleCreateModal}
                    fields={this.getFields()}
                    onSubmit={(params) => {
                        if (params) {
                            this.props.dispatch(createResource({
                                user: LocalStorage.get('user'),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResource(),
                                piggyResource: this.getResource(),
                                errorMessage: true, successMessage: 'Pricing Matrix created.',
                            }))
                            this.handleToggleCreateModal()
                        }
                    }}
                    translate={this.props.translate}
                />

                <ModalConfirm
                    title={'Confirm'}
                    show={!!this.state.confirmModalOpen}
                    text={this.state.selectedItem?.ArchivedDate ? translate('message.confirm_restore_generic') : translate('message.confirm_archive_generic') + ' ' + this.state.selectedItem?.MatrixName + '?'}
                    onClose={this.handleToggleConfirmModal}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={() => {
                        if (this.state.selectedItem?.ArchivedDate) {
                            this.restoreFromArchive()
                        } else {
                            this.archiveResource()
                        }
                    }}
                />

                <TableOptionsDialog
                    show={this.state.isColumnsDialogVisible}
                    pagePath={this.pagePath}
                    columns={this.state.tableOptions.columns}
                    setTableColumnOptions={this.handleSetTableColumnOptions}
                    getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                    onClose={this.handleToggleColumnSettingsDialog}
                    translate={translate}
                />
            </>
        )
    }
}

export default connect(state => state)(LoadPricingMatrixTab)
