import React from 'react';
import Badge from "../index";
import {ConstantTranslate} from "../../../util/util-helpers";
import {DOCUMENT_REVIEW_STATUSES} from "../../../util/util-consts";


export default function DocumentReviewStatusBadge({translate, DocumentReviewStatusID = 0}) {
    let badgeType = 'warning';
    if (1 === DocumentReviewStatusID) {
        badgeType = 'success';
    } else if (2 === DocumentReviewStatusID) {
        badgeType = 'danger'
    }
    return <Badge type={badgeType}>{ConstantTranslate(DOCUMENT_REVIEW_STATUSES, translate)[DocumentReviewStatusID]}</Badge>
}